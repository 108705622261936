import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store';
import { getThemes } from './OrdersSlice';
import { apiSlice } from '../../apis/apiSlice';

export type MainOrderListColumn = {
    header: string,
    type: string,
    path: string | string[],
    sortstring?: string,
    sortable: boolean,
    selected: boolean,
    sortUp?: boolean,
    formatString: string,
    displayOptions?: any;
};

export interface IMainOrderListSlice {
    searchString: string;
    studyStateFilter: string[]
    searchDateRange?: DateRangeT;
    sortString?: string;
    selectedDateMenuEntry: string | undefined;
    allowComplete: boolean;
    imagePolarity: number;
    versionString: string;
    releaseDateString: string;
    theme: string;
    showThemeSelection: boolean;
    orderlistIndex: number;
}

export type DateRangeT = {
    startDate: Date,
    endDate: Date
}

const initialState: IMainOrderListSlice = {
    searchString: "",
    studyStateFilter: ["OPENED", "COMPLETED"],
    searchDateRange: undefined,
    sortString: "ModifiedDateDescending",
    selectedDateMenuEntry: undefined,
    allowComplete: true,
    imagePolarity: 1,
    versionString: "",
    releaseDateString: "",
    theme: 'ORinspect',
    showThemeSelection: true,
    orderlistIndex: 0,
}

const MainOrderListSlice = createSlice({
    name: 'MainOrderListColumns',
    initialState,
    reducers: {
        setSearchString(state, action) {
            const searchString: string = action.payload.searchString;
            //console.log("MainOrderListSlice gets new searchString: " + searchString);
            state.searchString = searchString;
        },
        setStudyStateFilter(state, action) {
            const studyStateFilter: string[] = action.payload;
            state.studyStateFilter = studyStateFilter;
        },
        setSearchDateRange(state, action: PayloadAction<DateRangeT>) {
            const searchDateRange: DateRangeT = action.payload;
            /* if (searchDateRange && searchDateRange.startDate && searchDateRange.endDate) {
                console.log("MainOrderListSlice gets new searchDateRange: " + searchDateRange.startDate + " - "+searchDateRange.endDate);
            } */
            state.searchDateRange = searchDateRange;
        },
        setSortString(state, action) {
            const sortString: string = action.payload;
            //console.log("MainOrderListSlice gets new sortString: " + sortString);
            state.sortString = sortString;
        },
        setSelectedDateMenuEntry(state, action) {
            const selectedDateMenuEntry: string = action.payload;
            //console.log("MainOrderListSlice gets new selectedDateMenuEntry: " + selectedDateMenuEntry);
            state.selectedDateMenuEntry = selectedDateMenuEntry;
        },
        setAllowComplete(state, action) {
            const allowComplete: boolean = action.payload;
            state.allowComplete = allowComplete;
            /* if(state.mainOrderListColumns) {
                state.mainOrderListColumns = state.mainOrderListColumns?.filter((column: MainOrderListColumn) => column.type !== 'openStatus' || state.allowComplete);
            } */
        },
        setImagePolarity(state, action) {
            const imagePolarity: number = action.payload;
            state.imagePolarity = imagePolarity;
        },
        setVersionString(state, action) {
            const versionString: string = action.payload;
            state.versionString = versionString;
        },
        setReleaseDateString(state, action) {
            const releaseDateString: string = action.payload;
            state.releaseDateString = releaseDateString;
        },
        setTheme(state, action) {
            const theme: string = action.payload;
            state.theme = theme;
        },
        setShowThemeSelection(state, action) {
            const showThemeSelection: boolean = action.payload;
            state.showThemeSelection = showThemeSelection;
        },
        setOrderlistIndex(state, action) {
            const orderlistIndex: number = action.payload;
            state.orderlistIndex = orderlistIndex;
        },
    }
});

export const { setSearchDateRange, setSearchString, setStudyStateFilter, setSortString, setSelectedDateMenuEntry,
    setAllowComplete, setImagePolarity, setVersionString, setTheme, setShowThemeSelection, setOrderlistIndex } = MainOrderListSlice.actions;
export default MainOrderListSlice.reducer;

export const selectFormatString = (state: RootState): string => {
    let ret: string = '';
    const cols = getOrderlistCols(state);
    if (cols) {
        for (let j = 0; j < getOrderlistCols(state)[state.MainOrderListColumns.orderlistIndex]?.cols?.length; j++) {
            if (getOrderlistCols(state)[state.MainOrderListColumns.orderlistIndex]?.cols[j]?.type !== 'openStatus' || state.MainOrderListColumns.allowComplete) {
                ret += getOrderlistCols(state)[state.MainOrderListColumns.orderlistIndex]?.cols[j]?.formatString;
            }
            if (j < getOrderlistCols(state)[state.MainOrderListColumns.orderlistIndex]?.cols?.length - 1) {
                ret += ' ';
            }
        }
    }
    return ret;
}

export const selectSearchString = (state: RootState): string => state.MainOrderListColumns.searchString;
export const selectStudyStateFilter = (state: RootState): string[] => state.MainOrderListColumns.studyStateFilter;
export const selectSortString = (state: RootState): string => state.MainOrderListColumns.sortString;
export const selectSearchDateRange = (state: RootState): DateRangeT => state.MainOrderListColumns.searchDateRange;
export const selectDateMenuEntry = (state: RootState): string | undefined => state.MainOrderListColumns.selectedDateMenuEntry;

export const selectAllowComplete = (state: RootState) => {
    return state.MainOrderListColumns.allowComplete;
}

export const selectImagePolarity = (state: RootState) => {
    return state.MainOrderListColumns.imagePolarity;
}

export const selectVersionString = (state: RootState) => {
    return state.MainOrderListColumns.versionString;
}

export const selectReleaseDateString = (state: RootState) => {
    return state.MainOrderListColumns.releaseDateString;
}

export const selectTheme = (state: RootState) => {
    return state.MainOrderListColumns.theme;
}

export const selectShowThemeSelection = (state: RootState) => {
    return state.MainOrderListColumns.showThemeSelection;
}

export const selectOrderlistIndex = (state: RootState) => {
    return state.MainOrderListColumns.orderlistIndex;
}

export const getOrderlistCols = (state: RootState): any => {
    let orderlistCols = [];
    let currentThemesDir = './';
    if (getThemes(state)?.length > 0) {
        currentThemesDir = `Themes/${state.MainOrderListColumns.theme}`;
    }
    const selectLayoutResult = apiSlice.endpoints.config.select({ file: 'Orderlist.json', path: currentThemesDir });
    orderlistCols = selectLayoutResult(state)?.data;
    return orderlistCols;
};
