import { useSelector } from "react-redux";
import { selectReleaseDateString } from "../OrderList/MainOrderListSlice";

interface ReleaseDateStringProps {
    releaseDate?: string
}

export function ReleaseDateString (props: ReleaseDateStringProps) {

    const releaseDate : string = useSelector(selectReleaseDateString);

    return (
        <span className="versionInfo releaseDate">
            {props.releaseDate || releaseDate || ""}
        </span>
    )
}
