import InfoPanel from "../Utils/ConfigurableInfoPanel/InfoPanel"
import "./ProductInfoDialog.scss"

interface ProductInfoDialogProps { }

export function ProductInfoDialog (props: ProductInfoDialogProps) {
    return (
        <div className="productInfoDialogContainer">
            <InfoPanel
                className="productInfoDialogWrapper"
                configFile={"/ProductInfoDialog.json"}
                workitemId={undefined}
                artifactId={undefined}
                orderId={''}
                sources={undefined}
                editMode={false}
                translationNameSpaces={["ProductInfoDialog"]}
            />
        </div>
    )
}
