import React from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import "./PreviewPanel.scss";
import {
    selectArtifactForSelectedWorkitem, selectedStudy, selectInstanceForWorkitem,
    selectRawInstanceForWorkitem,
    selectRawSeriesDataForWorkitem, selectSeriesDataForWorkitem,
    workitemHasImage
} from "../OrderList/OrdersSlice";
import { workitem } from "../../apis/apiSlice";
import { getActivatePlanningMode, getCurrentProtocolStepNumber, selectedWorkitem } from "../ImageDisplay/ImageDisplaySlice";
import "./ImageDesc.scss";
import InfoPanel from "../Utils/ConfigurableInfoPanel/InfoPanel";
import { selectAllowComplete } from "../OrderList/MainOrderListSlice";
import { useORTranslation } from "../Localization/ORLocalization";
import { Constants } from "../../Constants";
import { selectProcedureSelectionVisibilityState } from "../Procedures/ProcedureSlice";

interface ImageDescProps {
    orderId: string;
    workitem: string;
    artifactId: string | undefined;
    artifactId_Raw: string | undefined;
    data: workitem | undefined;
    className: string;
    isPlanning?: boolean;
    onImageSelect: (workitem: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined, protocolCorrelationId?: string | undefined) => void;
    rawProtocolStepNumber?: number;
    resumeWorkitem?: (evt: any) => void;
    currentProtocolStep?: any;
    protocolCorrelationId?: string;
    sources?: any;
    isRejectedSeries?: boolean;
    showRecover?: boolean;
    recoverImage?: (evt: any) => void;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();


const ImageDesc = (props: ImageDescProps) => {
    const dispatch = useAppDispatch();

    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const allowComplete: boolean = useAppSelector((state) => selectAllowComplete(state));

    const { t } = useORTranslation(['InfoPanel']);

    const currentStudyAsString: string | undefined = useAppSelector((state) => selectedStudy(state, props.orderId ?? ''));
    const currentStudy = currentStudyAsString ? JSON.parse(currentStudyAsString) : undefined;
    const currentSeriesAsString: string | undefined = useAppSelector((state) => selectSeriesDataForWorkitem(state, props.workitem));
    const currentSeries = currentSeriesAsString ? JSON.parse(currentSeriesAsString) : undefined;
    const currentRawSeriesAsString: string | undefined = useAppSelector((state) => selectRawSeriesDataForWorkitem(state, props.workitem));
    const currentRawSeries = currentRawSeriesAsString ? JSON.parse(currentRawSeriesAsString) : undefined;
    const currentInstanceAsString: string | undefined = useAppSelector((state) => selectInstanceForWorkitem(state, props.workitem));
    const currentInstance = currentInstanceAsString ? JSON.parse(currentInstanceAsString) : undefined;
    const currentRawInstanceAsString: string | undefined = useAppSelector((state) => selectRawInstanceForWorkitem(state, props.workitem));
    const currentRawInstance = currentRawInstanceAsString ? JSON.parse(currentRawInstanceAsString) : undefined;
    const currentArtifactAsString: string | undefined = useAppSelector((state) => selectArtifactForSelectedWorkitem(state));
    const currentArtifact = currentArtifactAsString ? JSON.parse(currentArtifactAsString) : undefined;
    const currentProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.ACQUISITION_STEP_TYPE, 'scheduled', 'rejected'));
    const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));
    const activatePlanningMode = useAppSelector((state) => getActivatePlanningMode(state));
    const hasImage = useAppSelector((state) => workitemHasImage(state));



    const onWorkitemClick = (evt: any) => {
        if (evt.detail === 1) {
            props.onImageSelect(props.workitem, props.artifactId, props.artifactId_Raw, props.data, props.protocolCorrelationId);
            if (!activatePlanningMode && props.currentProtocolStep?.type === Constants.ACQUISITION_STEP_TYPE &&
                props.currentProtocolStep?.performed === undefined && props.currentProtocolStep?.rejected === undefined) {
                dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 2 });
            }
        } else if (evt.detail === 2) {
            if (procedureSelectionVisibilityState === 1) {
                dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 2 });
            }
        }
    };

    return (
        <div className="img-description" onMouseDown={(evt: any) => onWorkitemClick(evt)}>
            <div className={props.workitem === currentWorkitemId ? "content-images-desc_selected" : "content-images-desc"}>
                <InfoPanel className="infoPanelWrapper" configFile="/ImageDescInfo.json"

                    sources={ props.sources && props.isRejectedSeries ? props.sources :
                        {currentStudy, currentWorkitem: props.data, currentSeries, currentInstance,
                        currentArtifact, currentRawSeries, currentRawInstance,
                        protocolStep: props.rawProtocolStepNumber !== undefined ? props.data?.protocol?.steps[props.rawProtocolStepNumber] : undefined
                    }}
                    workitemId={props.workitem} artifactId={props.artifactId} orderId={props.orderId} editMode={props.workitem === currentWorkitemId && currentStudy?.state !== 'COMPLETED'}
                    editEnabled={currentStudy && ((currentStudy.state !== 'COMPLETED' && props.data?.state !== 'COMPLETED') || !allowComplete)}
                    configFileSection={props.isRejectedSeries ? 'rejected' : (props.isPlanning ? 'planning' : 'image')}
                    updateOnComponentShutdown={true}

                    confirmationTextlines={{
                        cancelButtonClick: hasImage ? undefined : [t(`confirmationCancelWorkitem`), t('confirmationQuestion')],
                        closeButtonClick: [t(`confirmationCloseWorkitem`), t('confirmationQuestion')],
                        resumeButtonClick: [t(`confirmationResumeWorkitem`), t('confirmationQuestion')],
                        downloadButtonClick: undefined,
                        editButtonClick: undefined,
                        recoverButtonClick: props.showRecover ? [t(`recoverClosedWorkitem`), t('confirmationQuestion')] : undefined,
                    }}
                    rawProtocolStepNumber={props.isRejectedSeries ? props.rawProtocolStepNumber : currentProtocolSettingsStepNumber}
                    resumeWorkitem={props.resumeWorkitem}
                    recoverImage={props.recoverImage}
                    isRejectedSeries={props.isRejectedSeries}
                />
            </div>
        </div>
    );
};

export default ImageDesc;
