import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { apiSlice, workitem, useCompleteStudyMutation, useCompleteWorkitemMutation, useCancelWorkitemMutation } from '../../apis/apiSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import "./FinalizeStudyDialog.scss";
import { setAcquisitionActive } from "../Akquisition/AcquisitionSlice";
import { selectedStudyTitle, studyWorkitemsList, selectFinalizeInstanceAndArtifactIdsForOrder, getOpenWorkitemIds, getCompletableWorkitemIds } from "../OrderList/OrdersSlice";
import { selectFinalizeStudyDialogVisible } from "../ImageDisplay/ImageDisplaySlice";
import FinalizePreviewImage from "./FinalizePreviewImage";
import { useORTranslation } from "../Localization/ORLocalization";

type FinalizeStudyDialogProps = {
	orderId: string;
	showWaitPACS: boolean;
	isCalledFromOrderList: boolean;
	refresh?: number;
	resetRefresh?: () => void;
	onActionDone?: () => void;
};
export type InstanceAndArtefactIds = {
	workitemId?: string, workitemTitle?: string, instanceId?: string | undefined,
	artifactId?: string | undefined, studyTitle?: string | undefined, orderId?: string | undefined,
	previewImg?: string | undefined, previewJobId?: string|undefined, artifactState?: string|undefined,
}

type AnnoDataMapEntry = {
	instanceId: string, seriesId: string, workitemId: string, artifactState: string,
	finalizeJobActive?: boolean
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const FinalizeStudyDialog = (props: FinalizeStudyDialogProps) => {
	const location = useLocation();
    const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { t } = useORTranslation(['FinalizeStudyDialog', 'common']);
	const isFinalizeStudyDialogVisible: number = useAppSelector((state) => selectFinalizeStudyDialogVisible(state));
	const studyWorkitemsListArray: string[] = JSON.parse(useAppSelector((state) => studyWorkitemsList(state, props.orderId ?? '')));
	const currentStudyTitle: string = useAppSelector((state) => selectedStudyTitle(state, props.orderId ?? ''));
	const artifactIdsForOrder = useAppSelector((state) => selectFinalizeInstanceAndArtifactIdsForOrder(state, props.orderId));
	const artifactIdsForOrderArray = JSON.parse(artifactIdsForOrder);
	const openWorkitemsList = useAppSelector((state) => getOpenWorkitemIds(state, props.orderId ?? ''));
	const completableWorkitemsString = useAppSelector((state) => getCompletableWorkitemIds(state, props.orderId));
	const completableWorkitems = JSON.parse(completableWorkitemsString);
	const [cancelWorkitem] = useCancelWorkitemMutation();
	const [completeWorkitem] = useCompleteWorkitemMutation();
	const [cancelIncomplete, setCancelIncomplete] = useState<boolean>(false);
	const [completeStudy] = useCompleteStudyMutation();

	const getAnnoDataMap = (ids: string[]) => {
		let annoDataMap: any = {};
		for (let i = 0; i < ids.length; i++) {
			let artifactId = artifactIdsForOrderArray[i].artifactId;
			if (artifactId) {
				//console.log(artifactId);
				annoDataMap[artifactId] = { instanceId: artifactIdsForOrderArray[i].instanceId, seriesId: artifactIdsForOrderArray[i].seriesId, workitemId: artifactIdsForOrderArray[i].workitemId, artifactState: artifactIdsForOrderArray[i].artifactState };
			} else {
				console.log("Undefined Artifact ID.");
			}
		}
		return annoDataMap;
	}

	const [annotationDataMap, setAnnotationDataMap] = useState<AnnoDataMapEntry[]>([]);

	const [waitForPACSChecked, setWaitForPACSChecked] = useState<boolean>(false);

	const [showPreview, setShowPreview] = useState<boolean>(false);

	const [doFinalize, setDoFinalize] = useState<boolean>(false);

	const [exportImages, setExportImages] = useState<InstanceAndArtefactIds[]>([]);

	const handleWaitForPACSChange = () => {
		setWaitForPACSChecked(!waitForPACSChecked);
	};

	const handleShowPreviewChange = () => {
		setShowPreview(!showPreview);
	};

	const handleSelectAll = (evt: React.MouseEvent<Element> | React.TouchEvent | undefined) => {
		setExportImages(studyWorkitemsListArray.map<InstanceAndArtefactIds>((item: string) => { return { workitemId: item, workitemTitle: '', instanceId: '', instanceId_raw: '', artifactId: '', artifactId_raw: '' } }) ?? []);
	};

	const handleUnselectAll = (evt: React.MouseEvent<Element> | React.TouchEvent | undefined) => {
		setExportImages([]);
	};


	useEffect(() => {
		if (props.refresh && props.refresh > 0) {
			doFinalizeFunc();
		}
		return () => {
			if (props.resetRefresh) {
				props.resetRefresh();
			}
			if (props.onActionDone) {
				props.onActionDone();
			}
		};
	}, [props.refresh]);


	useEffect(() => {
		if (isFinalizeStudyDialogVisible === 0) {
			setExportImages([]);
			setAnnotationDataMap([]);
			setShowPreview(false);
			setDoFinalize(false);
		} else {
			setAnnotationDataMap(getAnnoDataMap(artifactIdsForOrderArray));
			setShowPreview(true);
		}
	}, [isFinalizeStudyDialogVisible]);


    useEffect(() => {
		console.log("artifactIdsForOrder changed...");

        if (isFinalizeStudyDialogVisible === 0 && props.orderId) {
            const exportImagesTmp = structuredClone(exportImages);
            let itemChanged: boolean = false;
            for (let item of exportImages) {
                if (item.instanceId === undefined || item.artifactId === undefined || item.instanceId === '' || item.artifactId === '') {
                    let iaa: InstanceAndArtefactIds | undefined = undefined;
                    for (let i = 0; i < artifactIdsForOrderArray.length; i++) {
                        if (artifactIdsForOrderArray[i].workitemId === item.workitemId) {
                            iaa = artifactIdsForOrderArray[i];
                            break;
                        }
                    }
                    if (iaa && iaa.instanceId && iaa.artifactId && iaa.instanceId !== '' && iaa.artifactId !== '') {
                        const index: number = exportImages.indexOf(item);
                        if (index >= 0) {
                            itemChanged = true;
                            exportImagesTmp[index].instanceId = iaa.instanceId;
                            exportImagesTmp[index].artifactId = iaa.artifactId;
                            exportImagesTmp[index].workitemTitle = iaa.workitemTitle;
                            exportImagesTmp[index].artifactState = iaa.artifactState;
                        }
                    }
                }
            }
            if (itemChanged) {
                setExportImages(exportImagesTmp);
            }
        }
    }, [artifactIdsForOrder, JSON.stringify(exportImages)]);


	const doFinalizeFunc = () => {
		//dispatch({ type: "ImageDisplay/setExportActive", payload: true });

		setDoFinalize(true);

		//dispatch({ type: "ImageDisplay/setExportActive", payload: false });
		// if(!waitForPACSOK)
		//  dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 0 });
	};


	const onImageSelect = (workitem: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined) => {
		if (artifactId) {
			if (exportImages.find((item: InstanceAndArtefactIds) => item.workitemId === workitem)) {
				const index: number = exportImages.findIndex((item: InstanceAndArtefactIds) => item.workitemId === workitem);
				if (index >= 0) {
					const newSelectedImages: InstanceAndArtefactIds[] = structuredClone(exportImages);
					newSelectedImages.splice(index, 1);
					setExportImages(newSelectedImages);
				}
			} else {
				const newSelectedImages: InstanceAndArtefactIds[] = structuredClone(exportImages);
				newSelectedImages.push({ workitemId: workitem, workitemTitle: workitemData?.details?.title ?? '', instanceId: '', artifactId: artifactId });
				setExportImages(newSelectedImages);
			}
		}
	}


	useEffect(() => {

		if (doFinalize) {
			const interval = setInterval(() => {
				console.log("Check for finalize jobs...");
				let finalizeActive = false;
				for (let i = 0; i < artifactIdsForOrderArray.length; i++) {
					let item = artifactIdsForOrderArray[i];
					if (item.artifactId) {
						if (annotationDataMap[item.artifactId].finalizeJobActive) {
							console.log("Job for "+item.artifactId + "is active!");
							finalizeActive = true;
							break;
						}
					}
				}
				if (!finalizeActive) {
					console.log("Checked for active finalize jobs, found none, closing down...");
					setDoFinalize(false);

					if (cancelIncomplete)
						for (const workitem of openWorkitemsList)
							cancelWorkitem({ workitemId: workitem }).unwrap();

					for (const workitem of completableWorkitems)
						completeWorkitem({ workitemId: workitem }).unwrap();

					let canCompleteStudy: boolean = true;

					if (openWorkitemsList && openWorkitemsList.length > 0)
						if (!cancelIncomplete)
							canCompleteStudy = false;

					if (canCompleteStudy) {
						completeStudy({ orderId: props.orderId }).unwrap();

						// TODO resolve copy of the following code from Navbar.tsx onClick() !
						dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
						dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: "" });
						dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
						dispatch({ type: "Procedure/setSelectedComponentSubtree", payload: ['human', 'Adult'] });
						dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });

						const r = document.querySelector(':root');
						// @ts-ignore
						r?.style.setProperty('--img_desc_left_margin', `${0}px`);

						navigate(`/${location?.search ?? ''}`);
					} else {
						// continue working
						dispatch(apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
							for (let workitem of data?.workitems) {
								if (/*workitem.state === "SCHEDULED" ||*/ workitem.state === "IN_PROGRESS")
									workitem.state = "COMPLETED";
							}
							return data;
						})); // dispatch
					} // if
					dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 0 });
				}
			}, 1000);

			return () => clearInterval(interval);
		} else {
			return;
		}
	}, [doFinalize]);

	const handleCancelIncompleteChange = () => {
		setCancelIncomplete(!cancelIncomplete);
    };


	const getFinalizePreviewImage = (workitemId:any, artifactId: any): JSX.Element => {
		return (<FinalizePreviewImage key={workitemId} displayImage={true} artifactId={artifactId} orderId={props.orderId} showPreview={showPreview} doFinalize={doFinalize} annotationDataMap={annotationDataMap} />);
	} // getFinalizePreviewImage

	return (
		<div className="finalizeStudyDialogWrapper" onContextMenu={(e) => e.preventDefault()}>

			{/* <div className="imagesToExport">
				<label>{`${t('studyToFinalize')}: ` + currentStudyTitle}</label>
			</div> */}

			{openWorkitemsList && openWorkitemsList.length > 0 ?
				<div className="imagesToExport">
            		<label className="haveIncomplete">{t('haveIncomplete')}</label>
            		<div onClick={handleCancelIncompleteChange}>
                		<input type="checkbox" checked={cancelIncomplete} onChange={handleCancelIncompleteChange} />
                		<label>{t('cancelIncomplete')}</label>
            		</div>
            		<div onClick={handleCancelIncompleteChange}>
                		<input type="checkbox" checked={!cancelIncomplete} onChange={handleCancelIncompleteChange} />
                		<label>{t('keepIncomplete')}</label>
                	</div>
            	</div> : null
			}

			<div className="preview-images-div">
				<label className="preview">{t('preview')}</label>
				<div className="preview-images">
					{artifactIdsForOrderArray.map((item: any) => getFinalizePreviewImage(item.workitemId, item.artifactId ? item.artifactId : ""))}
				</div>
			</div>

			{false && completableWorkitems.length > 0 ?
				<div className="format" onClick={handleShowPreviewChange}>
					&nbsp;
					<input type="checkbox" checked={showPreview} onChange={handleShowPreviewChange} />
					&nbsp;
					<label>{t('showPreview')}</label>
				</div>:null
			}

			{props.showWaitPACS ?
				<div className="format" onClick={handleWaitForPACSChange}>
					&nbsp;
					<input type="checkbox" checked={waitForPACSChecked} onChange={handleWaitForPACSChange} />
					&nbsp;
					<label>{t('waitPACS')}</label>
				</div> : null
			}
		</div>
	);
};

export default FinalizeStudyDialog;
