import { Callback, TFunction } from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';
import * as dateFnsLocales from 'date-fns/locale';
import i18next from 'i18next';
import { format as formatDateFns } from "date-fns";


import i18n from 'i18next';
import { RootState } from '../../store';
import { getIntlOptions, selectDataFormat, selectTimeFormat } from '../ImageDisplay/ImageDisplaySlice';

interface Locales {
    [key: string]: Locale;
}

function init(state: RootState) {
    const languageDetectorOptions = {
        // order and from where user language should be detected
        order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

        // keys or params to lookup language from
        lookupQuerystring: 'lang',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupSessionStorage: 'i18nextLng',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,

        // cache user language on
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)


    }
    const backdendOptions = {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
    }
    if (!i18n.isInitialized) {
        i18n
            // detect user language
            // learn more: https://github.com/i18next/i18next-browser-languageDetector
            .use(LanguageDetector)
            // pass the i18n instance to react-i18next.
            .use(initReactI18next)
            .use(Backend)
            // init i18next
            // for all options read: https://www.i18next.com/overview/configuration-options
            .use(i18nextPlugin)
            .init({
                debug: false,
                fallbackLng: 'en',
                ns: ['common', 'ActionButton', 'OrderList', 'Search', 'PreviewPanel', 'InfoPanel', 'ReloadDialog','ExportDialog', 'FinalizeStudyDialog', 'NavbarImage',
                    'Tools', 'Acquisition', 'DocumentViewer', 'ViewportOverlay', 'Subject', 'xrayhelper', 'Procedures', 'ProductInfoDialog'],
                backend: backdendOptions,
                detection: languageDetectorOptions,
                interpolation: {
                    escapeValue: false, // not needed for react as it escapes by default
                    // value == date from {{date, date}} == new Date()
                    // format == date/time from {{date, date}} == "date"
                    // lng == active language
                    format: function (value, format, lng) {
                        if(value === 'Invalid Date') {
                            return '';
                        }
                        if (format === "date") {
                            if (selectDataFormat(state)) {
                                return formatDateFns(value, selectDataFormat(state));
                            } else {
                                try {
                                    return new Intl.DateTimeFormat(lng, getIntlOptions(state)?.date).format(value);
                                } catch (error) {
                                }
                            }
                        }
                        if (format === "time") {
                            if (selectTimeFormat(state)) {
                                return formatDateFns(value, selectTimeFormat(state));
                            } else {
                                try {
                                    return new Intl.DateTimeFormat(lng, getIntlOptions(state)?.time ?? { "timeStyle": "short" }).format(value);
                                } catch (error) {

                                }
                            }
                        }
                        return value;
                    },
                },
            });
    }
}

const useORTranslation = (namespaces: string[]): {
    t: TFunction<"translation", undefined, "translation">,
    language: string, locales: Locale, changeLanguage: (lng?: string | undefined, callback?: Callback | undefined) => Promise<TFunction<"translation", undefined, "translation">>, i18n: typeof i18n
} => {
    const { t, i18n } = useTranslation(namespaces);
    const getDateFnsLocale = (): Locale => {
        const locales: Locales = {
            en: dateFnsLocales.enUS,
            de: dateFnsLocales.de,
        };
        return locales[i18n.language];
    };
    const locales = getDateFnsLocale();
    const changeLanguage = i18n.changeLanguage;
    return { t, language: i18n.language, locales, changeLanguage, i18n };
};

const t = (key: string, args?: any): any => {
    return i18next.t(key, args);
}

export {
    init, useORTranslation, t
};
