import React, { useEffect, useRef, useState } from "react";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
//import { useORTranslation } from "../Localization/ORLocalization";

import "./GenderIcon.scss";
import { useUpdateStudyDetailsMutation } from "../../apis/apiSlice";
import { parseISO } from "date-fns";
import { selectPregnancyAgeLimits } from "../ImageDisplay/ImageDisplaySlice";
import { getAge } from "./SubjectPanel";

type GenderIconProps = {
    subject: any;
    study?: any;
    styleString?: string;
    displayPregnancy?: boolean;
    t?: (text: string) => string;
};

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;



const GenderIcon = (props: GenderIconProps) => {

    const dispatch = useAppDispatch();

	//const { t } = useORTranslation(['Subject', 'common']);

    const rootDivElement = useRef<HTMLDivElement>(null);

    const [UpdateStudyDetails] = useUpdateStudyDetailsMutation();

    const pregnancyAgeLimits: number[] = useAppSelector((state) => selectPregnancyAgeLimits(state));


    const [open, setOpen] = useState(false);

    const formatStringToCamelCase = (str: string) => {
        const splitted = str.split("-");
        if (splitted.length === 1) return splitted[0];
        return (
            splitted[0] +
            splitted
                .slice(1)
                .map(word => word[0].toUpperCase() + word.slice(1))
                .join("")
        );
    };

    const getStyleObjectFromString = (str: string) => {
        const style = {};
        str.split(";").forEach(el => {
            const [property, value] = el.split(":");
            if (!property) return;

            const formattedProperty: string = formatStringToCamelCase(property.trim());
            //@ts-ignore
            style[formattedProperty] = value.trim();
        });

        return style;
    };

    const handleOpen = (evt: any) => {
        if (!((props.subject?.details?.sex === 'male' || !props.displayPregnancy) || !isInAgeLimits(props.subject?.details?.birthDate))) {
            setOpen(!open);
        }
    };

    const setPregnancyStatus = (evt: any, pregnancyStatus: string) => {
        const newStudy = structuredClone(props.study);
        if (newStudy?.details?.subject?.details) {
            newStudy.details.subject.details.pregnancyStatus = pregnancyStatus;
            UpdateStudyDetails({ orderId: newStudy?.id, body: newStudy });
        }

        setOpen(false);
    };

    useEffect(() => {
        const onBodyClick = (event: Event) => {
            if (rootDivElement.current?.contains(event.target as Node)) {
                return;
            }
            setOpen(false);
        };
        document.body.addEventListener("click", onBodyClick, { capture: true });
        return () => {
            document.body.removeEventListener("click", onBodyClick, { capture: true });
        };
    }, []);

    const GenderSelection3 = () =>
        <div className={"pregnancySelection"}  >
            <div className={props.subject?.details?.pregnancyStatus === 'definitely_pregnant' ? "pregnancySelectionImageWrapper-selected" : "pregnancySelectionImageWrapper"} onClick={(evt: any) => setPregnancyStatus(evt, "definitely_pregnant")}>
                {Pregnant()}
                <label>{props.t ? props.t("Pregnant") : "Pregnant"}</label>

            </div>
            <div className={props.subject?.details?.pregnancyStatus === 'possibly_pregnant' ? "pregnancySelectionImageWrapper-selected" : "pregnancySelectionImageWrapper"} onClick={(evt: any) => setPregnancyStatus(evt, "possibly_pregnant")}>
                {PossiblyPregnant()}
                <label>{props.t ? props.t("Possibly pregnant") : "Possibly pregnant"}</label>

            </div>
            <div className={props.subject?.details?.pregnancyStatus === 'not_pregnant' ? "pregnancySelectionImageWrapper-selected" : "pregnancySelectionImageWrapper"} onClick={(evt: any) => setPregnancyStatus(evt, "not_pregnant")}>
                {NotPregnant()}
                <label>{props.t ? props.t("Not pregnant") : "Not pregnant"}</label>
            </div>
            <div className={props.subject?.details?.pregnancyStatus === 'unknown' ? "pregnancySelectionImageWrapper-selected" : "pregnancySelectionImageWrapper"} onClick={(evt: any) => setPregnancyStatus(evt, "unknown")}>
                {Unknown()}
                <label>{props.t ? props.t("Unknown") : "Unknown"}</label>
            </div>
        </div>;

    const NotPregnant = () =>
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
            <g id="Group 871">
                <g id="Ebene 43">
                    <g id="Group">
                        <path id="Vector" d="M16.6633 27.8291C18.7705 28.814 20.0496 30.4919 20.4506 32.7364C20.784 34.5995 19.6073 36.0312 18.2998 36.7677C17.9577 36.9595 17.6439 37.1992 17.365 37.3844C17.3868 37.6677 17.9956 44.245 18 44.5M9.5 41C9.48693 39.4114 9 37 10.7841 34.4775C10.9388 34.2748 11.1676 34.1288 11.3202 33.9262C11.641 33.5 11.926 32.4945 10.7754 32.2047C9.41129 31.8604 8.18446 31.2917 7.07529 30.444C5.88551 29.5331 5.709 28.7378 6.42157 27.3933C7.30846 25.7219 8.50478 24.275 9.79481 22.815C9.49191 22.6646 9.274 22.5557 9.02341 22.4293C9.87761 21.2177 10.1631 19.9102 9.85582 18.3369C9.61394 17.0905 9.66624 15.7569 10.4224 14.517C11.2831 13.1093 12.5274 12.9546 13.9395 13.009C15.1358 13.0548 16.1077 13.4863 16.781 14.53C17.0338 14.9223 17.1209 15.3951 16.9597 15.7198C16.6285 16.3845 16.7243 17.06 16.7984 17.6941C17.049 19.8776 16.6546 20.455 14.469 20.7492C14.0462 21.6579 14.3012 22.4009 15.029 23.0351C15.65 23.5755 16.2885 24.0985 16.8769 24.6737C18.1669 25.9311 18.1909 26.4475 17.0991 27.8291" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path id="Vector_2" d="M10.4527 26.739C11.4094 27.7458 11.7602 28.9922 11.8691 30.3345C11.0193 30.4348 10.322 29.9968 9.7053 29.5522C8.51115 28.6915 8.70073 27.846 10.2348 26.739" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                </g>
                <rect id="Rectangle 429" x="0.5" y="0.5" width="45" height="45" rx="22.5" stroke="#EEAC5F" strokeWidth="1.8" />
                <g id="Group 861">
                    <g id="Group 748">
                        <path id="Vector_3" d="M23 16C23 18.1217 23.8429 20.1566 25.3431 21.6569C26.8434 23.1571 28.8783 24 31 24C33.1217 24 35.1566 23.1571 36.6569 21.6569C38.1571 20.1566 39 18.1217 39 16C39 13.8783 38.1571 11.8434 36.6569 10.3431C35.1566 8.84285 33.1217 8 31 8C28.8783 8 26.8434 8.84285 25.3431 10.3431C23.8429 11.8434 23 13.8783 23 16Z" fill="#EEAC5F" stroke="#EEAC5F" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <g id="Group 860">
                        <g id="Vector_4">
                            <path d="M28.1992 18.5091L33.7083 13L28.1992 18.5091Z" fill="#F48F17" />
                            <path d="M28.1992 18.5091L33.7083 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <g id="Vector_5">
                            <path d="M28.1992 13L33.7083 18.5091L28.1992 13Z" fill="#F48F17" />
                            <path d="M28.1992 13L33.7083 18.5091" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    const Pregnant = () =>
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
            <g id="Group 870">
                <g id="Ebene 31">
                    <g id="Group">
                        <path id="Vector" d="M16.6633 27.8291C18.7705 28.814 20.0496 30.4919 20.4506 32.7364C20.784 34.5995 19.6073 36.0312 18.2998 36.7677C17.9577 36.9595 17.6439 37.1992 17.365 37.3844C17.3868 37.6677 17.9956 44.245 18 44.5M9.5 41C9.48693 39.4114 9 37 10.7841 34.4775C10.9388 34.2748 11.1676 34.1288 11.3202 33.9262C11.641 33.5 11.926 32.4945 10.7754 32.2047C9.41129 31.8604 8.18446 31.2917 7.07529 30.444C5.88551 29.5331 5.709 28.7378 6.42157 27.3933C7.30846 25.7219 8.50478 24.275 9.79481 22.815C9.49191 22.6646 9.274 22.5557 9.02341 22.4293C9.87761 21.2177 10.1631 19.9102 9.85582 18.3369C9.61394 17.0905 9.66624 15.7569 10.4224 14.517C11.2831 13.1093 12.5274 12.9546 13.9395 13.009C15.1358 13.0548 16.1077 13.4863 16.781 14.53C17.0338 14.9223 17.1209 15.3951 16.9597 15.7198C16.6285 16.3845 16.7243 17.06 16.7984 17.6941C17.049 19.8776 16.6546 20.455 14.469 20.7492C14.0462 21.6579 14.3012 22.4009 15.029 23.0351C15.65 23.5755 16.2885 24.0985 16.8769 24.6737C18.1669 25.9311 18.1909 26.4475 17.0991 27.8291" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path id="Vector_2" d="M10.4527 26.739C11.4094 27.7458 11.7602 28.9922 11.8691 30.3345C11.0193 30.4348 10.322 29.9968 9.7053 29.5522C8.51115 28.6915 8.70073 27.846 10.2348 26.739" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                </g>
                <rect id="Rectangle 394" x="0.5" y="0.5" width="45" height="45" rx="22.5" stroke="#F48F17" strokeWidth="1.8" />
                <g id="Group 769">
                    <path id="Vector_3" d="M32.5718 8.59027C32.473 8.41145 32.3281 8.26238 32.1522 8.15857C31.9763 8.05476 31.7757 8 31.5714 8C31.3671 8 31.1666 8.05476 30.9906 8.15857C30.8147 8.26238 30.6698 8.41145 30.571 8.59027L23.1424 22.3047C23.0463 22.4787 22.9973 22.6748 23.0001 22.8735C23.003 23.0722 23.0576 23.2668 23.1586 23.438C23.2596 23.6091 23.4035 23.751 23.5761 23.8496C23.7487 23.9481 23.944 24 24.1428 24H39.0001C39.1988 24 39.3942 23.9481 39.5667 23.8496C39.7393 23.751 39.8832 23.6091 39.9843 23.438C40.0853 23.2668 40.1399 23.0722 40.1427 22.8735C40.1456 22.6748 40.0966 22.4787 40.0005 22.3047L32.5718 8.59027Z" fill="#F48F17" stroke="#F48F17" strokeLinecap="round" strokeLinejoin="round" />
                    <g id="Vector_4">
                        <path d="M31.5723 18.2855V13.1426V18.2855Z" fill="#F48F17" />
                        <path d="M31.5723 18.2855V13.1426" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <g id="Vector_5">
                        <path d="M31.5709 21.1437C31.4951 21.1437 31.4224 21.1136 31.3688 21.06C31.3153 21.0064 31.2852 20.9338 31.2852 20.858C31.2852 20.7822 31.3153 20.7095 31.3688 20.656C31.4224 20.6024 31.4951 20.5723 31.5709 20.5723" fill="#F48F17" />
                        <path d="M31.5709 21.1437C31.4951 21.1437 31.4224 21.1136 31.3688 21.06C31.3153 21.0064 31.2852 20.9338 31.2852 20.858C31.2852 20.7822 31.3153 20.7095 31.3688 20.656C31.4224 20.6024 31.4951 20.5723 31.5709 20.5723" stroke="white" />
                    </g>
                    <g id="Vector_6">
                        <path d="M31.5723 21.1437C31.648 21.1437 31.7207 21.1136 31.7743 21.06C31.8279 21.0064 31.858 20.9338 31.858 20.858C31.858 20.7822 31.8279 20.7095 31.7743 20.656C31.7207 20.6024 31.648 20.5723 31.5723 20.5723" fill="#F48F17" />
                        <path d="M31.5723 21.1437C31.648 21.1437 31.7207 21.1136 31.7743 21.06C31.8279 21.0064 31.858 20.9338 31.858 20.858C31.858 20.7822 31.8279 20.7095 31.7743 20.656C31.7207 20.6024 31.648 20.5723 31.5723 20.5723" stroke="white" />
                    </g>
                </g>
            </g>
        </svg>

    const PossiblyPregnant = () =>
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
            <path d="M32.5718 8.59027C32.473 8.41145 32.3281 8.26238 32.1522 8.15857C31.9763 8.05476 31.7757 8 31.5714 8C31.3671 8 31.1666 8.05476 30.9906 8.15857C30.8147 8.26238 30.6698 8.41145 30.571 8.59027L23.1424 22.3047C23.0463 22.4787 22.9973 22.6748 23.0001 22.8735C23.003 23.0722 23.0576 23.2668 23.1586 23.438C23.2596 23.6091 23.4035 23.751 23.5761 23.8496C23.7487 23.9481 23.944 24 24.1428 24H39.0001C39.1988 24 39.3942 23.9481 39.5667 23.8496C39.7393 23.751 39.8832 23.6091 39.9843 23.438C40.0853 23.2668 40.1399 23.0722 40.1427 22.8735C40.1456 22.6748 40.0966 22.4787 40.0005 22.3047L32.5718 8.59027Z" fill="#F48F17" stroke="#F48F17" strokeLinejoin="round" />
            <path d="M16.6633 27.8291C18.7705 28.814 20.0496 30.4919 20.4506 32.7364C20.784 34.5995 19.6073 36.0312 18.2998 36.7677C17.9577 36.9595 17.6439 37.1992 17.365 37.3844C17.3868 37.6677 17.9956 44.245 18 44.5M9.5 41C9.48693 39.4114 9 37 10.7841 34.4775C10.9388 34.2748 11.1676 34.1288 11.3202 33.9262C11.641 33.5 11.926 32.4945 10.7754 32.2047C9.41129 31.8604 8.18446 31.2917 7.07529 30.444C5.88551 29.5331 5.709 28.7378 6.42157 27.3933C7.30846 25.7219 8.50478 24.275 9.79481 22.815C9.49191 22.6646 9.274 22.5557 9.02341 22.4293C9.87761 21.2177 10.1631 19.9102 9.85582 18.3369C9.61394 17.0905 9.66624 15.7569 10.4224 14.517C11.2831 13.1093 12.5274 12.9546 13.9395 13.009C15.1358 13.0548 16.1077 13.4863 16.781 14.53C17.0338 14.9223 17.1209 15.3951 16.9597 15.7198C16.6285 16.3845 16.7243 17.06 16.7984 17.6941C17.049 19.8776 16.6546 20.455 14.469 20.7492C14.0462 21.6579 14.3012 22.4009 15.029 23.0351C15.65 23.5755 16.2885 24.0985 16.8769 24.6737C18.1669 25.9311 18.1909 26.4475 17.0991 27.8291" stroke="white" strokeLinejoin="round" />
            <path d="M10.4527 26.739C11.4094 27.7458 11.7602 28.9922 11.8691 30.3345C11.0193 30.4348 10.322 29.9968 9.7053 29.5522C8.51115 28.6915 8.70073 27.846 10.2348 26.739" stroke="white" strokeLinejoin="round" />
            <rect x="0.5" y="0.5" width="45" height="45" rx="22.5" stroke="#F48F17" strokeWidth="1.8" />
            <path d="M29.4004 15.1329C29.4005 14.7418 29.508 14.3583 29.7114 14.0242C29.9147 13.6901 30.206 13.4184 30.5533 13.2386C30.9006 13.0589 31.2907 12.9781 31.6809 13.0051C32.071 13.0321 32.4463 13.1658 32.7656 13.3916C33.0849 13.6174 33.336 13.9267 33.4914 14.2856C33.6468 14.6444 33.7006 15.0391 33.6468 15.4265C33.5931 15.8139 33.4339 16.1791 33.1866 16.4821C32.9394 16.7851 32.6136 17.0143 32.2448 17.1447C32.0368 17.2182 31.8567 17.3545 31.7293 17.5347C31.6019 17.7149 31.5336 17.9302 31.5337 18.1509V18.3329" stroke="white" strokeLinejoin="round" />
            <path d="M31.5323 21.0001C31.4616 21.0001 31.3937 20.972 31.3437 20.922C31.2937 20.872 31.2656 20.8042 31.2656 20.7335C31.2656 20.6627 31.2937 20.5949 31.3437 20.5449C31.3937 20.4949 31.4616 20.4668 31.5323 20.4668" stroke="white" />
            <path d="M31.5352 21.0001C31.6059 21.0001 31.6737 20.972 31.7237 20.922C31.7737 20.872 31.8018 20.8042 31.8018 20.7335C31.8018 20.6627 31.7737 20.5949 31.7237 20.5449C31.6737 20.4949 31.6059 20.4668 31.5352 20.4668" stroke="white" />
        </svg>

    const Unknown = () =>
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
            <g id="Group 866">
                <g id="Ebene 45">
                    <g id="Group">
                        <path id="Vector" d="M16.6633 27.8291C18.7705 28.814 20.0496 30.4919 20.4506 32.7364C20.784 34.5995 19.6073 36.0312 18.2998 36.7677C17.9577 36.9595 17.6439 37.1992 17.365 37.3844C17.3868 37.6677 17.9956 44.245 18 44.5M9.5 41C9.48693 39.4114 9 37 10.7841 34.4775C10.9388 34.2748 11.1676 34.1288 11.3202 33.9262C11.641 33.5 11.926 32.4945 10.7754 32.2047C9.41129 31.8604 8.18446 31.2917 7.07529 30.444C5.88551 29.5331 5.709 28.7378 6.42157 27.3933C7.30846 25.7219 8.50478 24.275 9.79481 22.815C9.49191 22.6646 9.274 22.5557 9.02341 22.4293C9.87761 21.2177 10.1631 19.9102 9.85582 18.3369C9.61394 17.0905 9.66624 15.7569 10.4224 14.517C11.2831 13.1093 12.5274 12.9546 13.9395 13.009C15.1358 13.0548 16.1077 13.4863 16.781 14.53C17.0338 14.9223 17.1209 15.3951 16.9597 15.7198C16.6285 16.3845 16.7243 17.06 16.7984 17.6941C17.049 19.8776 16.6546 20.455 14.469 20.7492C14.0462 21.6579 14.3012 22.4009 15.029 23.0351C15.65 23.5755 16.2885 24.0985 16.8769 24.6737C18.1669 25.9311 18.1909 26.4475 17.0991 27.8291" stroke="white" strokeLinejoin="round" />
                        <path id="Vector_2" d="M10.4527 26.739C11.4094 27.7458 11.7602 28.9922 11.8691 30.3345C11.0193 30.4348 10.322 29.9968 9.7053 29.5522C8.51115 28.6915 8.70073 27.846 10.2348 26.739" stroke="white" strokeLinejoin="round" />
                    </g>
                </g>
                <rect id="Rectangle 431" x="0.5" y="0.5" width="45" height="45" rx="22.5" stroke="#EEAC5F" strokeWidth="1.8" />
                <g id="Group 848">
                    <g id="Group 755">
                        <g id="Group 748">
                            <path id="Vector_3" d="M23 16C23 18.1217 23.8429 20.1566 25.3431 21.6569C26.8434 23.1571 28.8783 24 31 24C33.1217 24 35.1566 23.1571 36.6569 21.6569C38.1571 20.1566 39 18.1217 39 16C39 13.8783 38.1571 11.8434 36.6569 10.3431C35.1566 8.84285 33.1217 8 31 8C28.8783 8 26.8434 8.84285 25.3431 10.3431C23.8429 11.8434 23 13.8783 23 16Z" fill="#EEAC5F" stroke="#EEAC5F" strokeLinejoin="round" />
                        </g>
                    </g>
                    <g id="Group 757">
                        <g id="Group 750">
                            <path id="Vector_4" d="M30.9971 16.5333C30.9264 16.5333 30.8586 16.5052 30.8086 16.4552C30.7586 16.4052 30.7305 16.3374 30.7305 16.2667C30.7305 16.1959 30.7586 16.1281 30.8086 16.0781C30.8586 16.0281 30.9264 16 30.9971 16" stroke="white" />
                            <path id="Vector_5" d="M31.001 16.5333C31.0717 16.5333 31.1395 16.5052 31.1895 16.4552C31.2395 16.4052 31.2676 16.3374 31.2676 16.2667C31.2676 16.1959 31.2395 16.1281 31.1895 16.0781C31.1395 16.0281 31.0717 16 31.001 16" stroke="white" />
                        </g>
                        <g id="Group 751">
                            <path id="Vector_6" d="M35.1329 16.5333C35.0622 16.5333 34.9943 16.5052 34.9443 16.4552C34.8943 16.4052 34.8662 16.3374 34.8662 16.2667C34.8662 16.1959 34.8943 16.1281 34.9443 16.0781C34.9943 16.0281 35.0622 16 35.1329 16" stroke="white" />
                            <path id="Vector_7" d="M35.1367 16.5333C35.2074 16.5333 35.2753 16.5052 35.3253 16.4552C35.3753 16.4052 35.4034 16.3374 35.4034 16.2667C35.4034 16.1959 35.3753 16.1281 35.3253 16.0781C35.2753 16.0281 35.2074 16 35.1367 16" stroke="white" />
                        </g>
                        <g id="Group 752">
                            <path id="Vector_8" d="M26.8643 16.5333C26.7936 16.5333 26.7258 16.5052 26.6758 16.4552C26.6258 16.4052 26.5977 16.3374 26.5977 16.2667C26.5977 16.1959 26.6258 16.1281 26.6758 16.0781C26.7258 16.0281 26.7936 16 26.8643 16" stroke="white" />
                            <path id="Vector_9" d="M26.8682 16.5333C26.9389 16.5333 27.0067 16.5052 27.0567 16.4552C27.1067 16.4052 27.1348 16.3374 27.1348 16.2667C27.1348 16.1959 27.1067 16.1281 27.0567 16.0781C27.0067 16.0281 26.9389 16 26.8682 16" stroke="white" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    const female = () =>
        <React.Fragment>
            <svg className="genderImg" viewBox="0 0 11 16" fill="none">
                <path d="M5.66211 10.334V15.0012" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.80078 13.1328H7.53459" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1 5.66726C1 6.9051 1.49173 8.09223 2.36701 8.96751C3.24229 9.84279 4.42943 10.3345 5.66726 10.3345C6.9051 10.3345 8.09223 9.84279 8.96751 8.96751C9.84279 8.09223 10.3345 6.9051 10.3345 5.66726C10.3345 4.42943 9.84279 3.24229 8.96751 2.36701C8.09223 1.49173 6.9051 1 5.66726 1C4.42943 1 3.24229 1.49173 2.36701 2.36701C1.49173 3.24229 1 4.42943 1 5.66726Z" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </React.Fragment>;

    const male = () =>
        <React.Fragment>
            <svg className="genderImg" viewBox="0 0 16 16" fill="none">
                <path d="M8.9707 7.03455L14.9974 1" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.36847 7.03111C3.02185 6.37834 3.8543 5.93381 4.76053 5.75373C5.66677 5.57365 6.6061 5.66611 7.45975 6.01941C8.31339 6.37272 9.04301 6.97101 9.55635 7.73862C10.0697 8.50623 10.3437 9.40869 10.3437 10.3319C10.3437 11.2551 10.0697 12.1575 9.55635 12.9251C9.04301 13.6928 8.31339 14.291 7.45975 14.6443C6.6061 14.9976 5.66677 15.0901 4.76053 14.91C3.8543 14.7299 3.02185 14.2854 2.36847 13.6326C1.93462 13.1992 1.59046 12.6846 1.35566 12.1183C1.12085 11.5519 1 10.9449 1 10.3319C1 9.71886 1.12085 9.11185 1.35566 8.5455C1.59046 7.97915 1.93462 7.46456 2.36847 7.03111Z" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.7969 1H15.0015V5.20103" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </React.Fragment>;

    const other = () =>
        <React.Fragment>
            <svg className="genderImg" viewBox="0 0 13 18" fill="none">
            <path d="M1 5.63282C1.00014 4.87503 1.2086 4.13186 1.60257 3.48454C1.99655 2.83722 2.56089 2.31065 3.23391 1.96239C3.90694 1.61413 4.66274 1.45758 5.41873 1.50985C6.17471 1.56211 6.90178 1.82119 7.52047 2.25876C8.13917 2.69633 8.62567 3.29555 8.92681 3.99094C9.22795 4.68632 9.33214 5.45111 9.228 6.20171C9.12385 6.95231 8.81538 7.65983 8.33629 8.24696C7.8572 8.83409 7.22593 9.27822 6.51148 9.53082C6.10835 9.67335 5.75934 9.9374 5.51256 10.2866C5.26579 10.6358 5.13339 11.0529 5.13361 11.4805V11.8332" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.13389 17.0002C4.99685 17.0002 4.86543 16.9458 4.76853 16.8489C4.67163 16.752 4.61719 16.6205 4.61719 16.4835C4.61719 16.3465 4.67163 16.215 4.76853 16.1181C4.86543 16.0212 4.99685 15.9668 5.13389 15.9668" stroke="black" strokeWidth="1.4"/>
<path d="M5.13477 17.0002C5.2718 17.0002 5.40323 16.9458 5.50013 16.8489C5.59703 16.752 5.65147 16.6205 5.65147 16.4835C5.65147 16.3465 5.59703 16.215 5.50013 16.1181C5.40323 16.0212 5.2718 15.9668 5.13477 15.9668" stroke="black" strokeWidth="1.4"/>
            </svg>
        </React.Fragment>;

    const getIcon = () => {
        switch (props.subject?.details?.sex) {
            case 'female':
                return female();
            case 'male':
                return male();
            default:
                return other();
        } // switch
    }

    const selectPregnancyStatus = () => {
        switch (props.subject?.details?.pregnancyStatus) {
            case 'definitely_pregnant':
                return Pregnant();
            case 'possibly_pregnant':
                return PossiblyPregnant();
            case 'not_pregnant':
                return NotPregnant();
            default:
                return Unknown();
        } // switch
    }

    const isInAgeLimits = ((birthDate: string) => {
        if (birthDate === undefined || birthDate.trim() === '') {
            return true;
        }
        return getAge(parseISO(birthDate)) > pregnancyAgeLimits[0] && getAge(parseISO(birthDate)) < pregnancyAgeLimits[1];
    })

    return (
        <div className={props.displayPregnancy ? "GenderIconWrapperPregnancy" : "GenderIconWrapper"} ref={rootDivElement}>
            <div className={props.displayPregnancy ? (props.subject?.details?.sex !== 'male' && isInAgeLimits(props.subject?.details?.birthDate) ? "sexImgWrapperPregnancy" : "sexImgWrapperPregnancy1") : "sexImgWrapper"}
                style={props.styleString ? getStyleObjectFromString(props.styleString) : {}}
                onClick={handleOpen}>
                {(props.subject?.details?.sex === 'male' || !props.displayPregnancy) || !isInAgeLimits(props.subject?.details?.birthDate) ?
                    getIcon()
                    :
                    selectPregnancyStatus()
                }
            </div>
            {open ?
                GenderSelection3()
                :
                null}
        </div>
    );
};

export default React.memo(GenderIcon);
