import { parseISO } from "date-fns"

import "./DateOfBirthWithAge.scss"
import { useORTranslation } from "../Localization/ORLocalization"

interface DateOfBirthWithAgeProps {
    dateOfBirth?: string
    age?: string
}

function DateOfBirthWithAge(props: DateOfBirthWithAgeProps) {

    const { t } = useORTranslation(["Subject"]);

    return (
        <div className="dobWrapper">
            { props.dateOfBirth ?
            <>
                <span className="dob date">
                    { t("date", ({ date: parseISO(props.dateOfBirth)})) }
                </span>
                { (props.age !== undefined) &&
                <>
                    <span className="dob separator">, </span>
                    <span className="dob age number">{ props?.age }</span>
                    <span className="dob separator"> </span>
                    <span className="dob age unit">{ String(props.age) === "1" ? t("year") : t("years") }</span>
                </>
                }
            </>
            :
                <span className="dob fallback">{ t("date of birth missing") }</span>
            }
        </div>
    )
}

export default DateOfBirthWithAge
