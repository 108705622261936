import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Draggable from 'react-draggable';
import "./TextAnnotationInputDialog.scss";
import { t } from "../Localization/ORLocalization";

export const submit = (data: any, description: string, inputValueChanged: ((data: any, value: string) => void) | undefined, value: string, doneChangingTextCallback: (value: string | null) => void) => {

  confirmAlert({
    customUI: ({ onClose }) => {

      const handleKeyDown = (event: any) => {

        if (event.key === 'Enter') {

          //@ts-ignore
          doneChangingTextCallback(document.getElementById("TextAnnotationInputDialogInput")?.value === '' ? null : document.getElementById("TextAnnotationInputDialogInput")?.value, document.getElementById("TextAnnotationInputDialogInput")?.value === '');
          //@ts-ignore
          if (inputValueChanged && document.getElementById("TextAnnotationInputDialogInput")?.value !== '') {
            //@ts-ignore
            inputValueChanged(data, document.getElementById("TextAnnotationInputDialogInput")?.value);
          }
          onClose();
        }
      }

      const handleOkClick = () => {
        //@ts-ignore
        doneChangingTextCallback(document.getElementById("TextAnnotationInputDialogInput")?.value === '' ? null : document.getElementById("TextAnnotationInputDialogInput")?.value, document.getElementById("TextAnnotationInputDialogInput")?.value === '');
        //@ts-ignore
        if (inputValueChanged && document.getElementById("TextAnnotationInputDialogInput")?.value !== '') {
          //@ts-ignore
          inputValueChanged(data, document.getElementById("TextAnnotationInputDialogInput")?.value);
        }
        onClose();
      }

      const handleCancelClick = () => {
        //@ts-ignore
        if (document.getElementById("TextAnnotationInputDialogInput")?.value === undefined || document.getElementById("TextAnnotationInputDialogInput")?.value === null || document.getElementById("TextAnnotationInputDialogInput")?.value === '') {
          //@ts-ignore
          doneChangingTextCallback(document.getElementById("TextAnnotationInputDialogInput")?.value, true);
        }
        onClose();
      }

      return (
        <div className="TextAnnotationInputDialog">
          <Draggable>
            <div className="custom-ui">
              <div className='header'>
                <div>{description}</div>
              </div>
              <div className="content">
                <div className="inputPromptWrapper" onContextMenu={(e) => e.preventDefault()}>
                  <input type="text" defaultValue={value} id="TextAnnotationInputDialogInput" onKeyDown={handleKeyDown} autoFocus />
                </div>
              </div>
              <div className='footer'>
                <button
                  onClick={(evt) => handleOkClick()} onTouchStart={(evt) => handleOkClick()}
                >
                  {t('OK', { ns: 'common' })}
                </button>
                <button onClick={() => handleCancelClick()} onTouchStart={(evt) => handleCancelClick()}>{t('cancel', { ns: 'common' })}
                </button>
              </div>
            </div>
          </Draggable>
        </div>
      );
    }
  });
};
