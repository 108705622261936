import * as React from 'react';
//import Draggable from 'react-draggable';
import { Rnd } from "react-rnd";
import Dialog from 'rc-dialog';
import "./Dialog.scss";
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getImagePathForCurrentTheme } from '../OrderList/OrdersSlice';

type CommonDialogProps = {
    onClose: () => void;
    onOk?: () => void;
    title: string;
    visible: boolean;
    okText: string;
    cancelText: string;
    default?: { x: number, y: number, width: number, height: number | undefined };
    className?: string;
    childProps: any;
    children: any;
    maskClosable?: boolean;
    closeDisabled?: boolean;
    skipFooter?: boolean;
    keepOnOK?: boolean;
    zIndex?: number;
    okDisabled?: boolean;
    hideCloseButton?: boolean;
    iconSource?: String;
    enableResizing?: boolean;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const CommonDialog = (props: CommonDialogProps) => {

    const [disabled, setDisabled] = React.useState(true);
    const [refresh, doRefresh] = React.useState(0);

    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

    const onClose = () => {
        props.onClose();
    }

    const handelOk = async () => {
        if (props.children) {
            try {
                if (props.onOk) {
                    props.onOk();
                    props.onClose();
                } else {
                    // trigger a useEffect in the child to run to OK-action
                    doRefresh(prev => prev + 1);
                }
            } catch (err: any) {
                console.log(err?.message);
            }
        }
    }

    return (
        <Dialog
            visible={props.visible}
            animation="slide-fade"
            maskAnimation="fade"
            mask={true}
            onClose={onClose}
            style={{ width: props.default?.width }}
            closable={false}
            maskClosable={props.maskClosable !== undefined ? props.maskClosable : true}
            destroyOnClose={true}
            getContainer={() => { return document.getElementById('root')! }}
            className='venaDialog'
            zIndex={props?.zIndex ?? 999}
            title={(
                <div className="header-content">
                    <div
                        style={{
                            //width: '100%',
                            cursor: 'pointer',
                        }}
                        onMouseOver={() => {
                            if (disabled) {
                                setDisabled(false)
                            }
                        }}
                        onMouseOut={() => {
                            setDisabled(true)
                        }}
                        // fix eslintjsx-a11y/mouse-events-have-key-events
                        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                        onFocus={() => { }}
                        onBlur={() => { }}
                    // end
                    >{props.title}</div>
                    {props.skipFooter || props.hideCloseButton ?
                        <button className="close-dialog-button" onClick={(evt) => onClose()} onTouchStart={(evt) => onClose()}>
                            <img className="close-image"
                                src={`${imagePathForCurrentTheme}Schliessen_Kreuz.svg`}
                                onError={(event: any) => { event.target.src = "/images/Schliessen_Kreuz.svg"; event.onerror = null }}
                                alt="close" />
                        </button>
                        : null
                    }
                </div>
            )}
            footer={
                props.skipFooter ?
                    null :
                    <div className="footer">
                        <div className="buttons">
                            {props.okText && props.okText.length > 0 ?
                                <button onClick={() => handelOk()} disabled={props.okDisabled !== undefined ? props.okDisabled : false}>{props.okText}
                                    {props.iconSource !== undefined &&  props.iconSource !== '' ?
                                        <img className="OkButtonImg" src={`${imagePathForCurrentTheme}${props.iconSource}`}
                                            onError={(event: any) => { event.target.src = `/images/${props.iconSource}`; event.onerror = null }}
                                            alt="Plus.svg" /> : null}
                                </button>
                                : null}
                            {props.cancelText && props.cancelText.length > 0 && !props.hideCloseButton ?
                                <button onClick={(evt) => onClose()} disabled={props.closeDisabled !== undefined ? props.closeDisabled : false}>{props.cancelText}</button>
                                : null}
                        </div>
                    </div>
            }
            // @ts-ignore
            //modalRender={modal => <Draggable defaultPosition={{ x: props.default?.x, y: props.default?.y }} disabled={disabled}>{modal}</Draggable>}
            // @ts-ignore
            modalRender={modal => <Rnd default={props.default} className={props.className} dragHandleClassName="rc-dialog-header" enableResizing={props.enableResizing ?? true} disabled={disabled}>{modal}</Rnd>}
        >
            <div
                style={{ height: props.default?.height }}
            >
                {(props?.keepOnOK) ?
                    (props?.children ? React.cloneElement(props?.children, { ref: undefined, refresh: refresh, resetRefresh: () => doRefresh(0) }) : null) :
                    (props?.children ? React.cloneElement(props?.children, { ref: undefined, refresh: refresh, resetRefresh: () => doRefresh(0), onActionDone: () => props.onClose() }) : null)}
            </div>

        </Dialog>
    );
};

export default CommonDialog;
