import React, { useEffect, useState } from "react";
import * as cornerstoneTools from "cornerstone-tools";
//import streamSaver from 'streamsaver';
import streamSaver from '../Utils/StreamSaver';
import { webGL } from "cornerstone-core";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Direction, Range } from 'react-range';
import { saveAs } from 'file-saver';
import { Variable, evaluateStringTemplate, parseStringTemplate } from 'string-template-parser';
import { format } from "date-fns";
import { AppDispatch, RootState } from "../../store";
import "./ExportDialog.scss";
import {
    addImageMode, getSelectedWorkitemTitle, loadImage, selectArtifactIdForSelectedWorkitem, selectedStudyTitle, studyWorkitemsList, selectInstanceAndArtifactIdsForOrder,
    selectInstanceIdForSelectedWorkitem,
    selectInstanceRawIdForSelectedWorkitem,
    selectRawArtifactIdForSelectedWorkitem
} from "../OrderList/OrdersSlice";
import { selectedWorkitem, selectMatrixIndex, selectExportDialogVisible, selectCornerstoneInitialized } from "../ImageDisplay/ImageDisplaySlice";
import cornerstone, { EnabledElement } from "cornerstone-core";
import { Constants } from "../../Constants";
import { waitforImageRendered } from "../ImageDisplay/LoadAndDisplayImage";
import { apiSlice, workitem } from "../../apis/apiSlice";
import { initCornerstone } from "../ImageDisplay/CornerstoneElement";
import Workitem from "../OrderList/Workitem";
import { addAllToolsToElement } from "../ImageDisplay/Tools/Tools";
import { render, initRenderer, getRenderCanvas, isWebGLAvailable } from '../FromCornerstoneCore/renderer.js';
//import { getImageName } from "../Utils/getItemExportName";
import { serviceEndpoints } from "../..";
import { useORTranslation } from "../Localization/ORLocalization";
import { ZIP } from "../Utils/zip-stream";
import { restore } from "../ImageDisplay/Tools/Restorable";
const getToolState = cornerstoneTools.getToolState;

type ExportDialogProps = {
    orderId: string;
    isCalledFromOrderList: boolean;
    refresh?: number;
    resetRefresh?: () => void;
    onActionDone?: () => void;
};
export type InstanceAndartefactIds = {
    workitemId?: string, workitemTitle?: string, instanceId?: string | undefined, instanceId_raw?: string | undefined,
    artifactId?: string | undefined, artifactId_raw?: string | undefined, studyTitle?: string | undefined, orderId?: string | undefined
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const ExportDialog = (props: ExportDialogProps) => {
    const dispatch = useAppDispatch();
    const { t } = useORTranslation(['ExportDialog', 'common']);

    const isCornerstoneInitialized: boolean = useAppSelector(state => selectCornerstoneInitialized(state));
    const isExportDialogVisible: number = useAppSelector((state) => selectExportDialogVisible(state));
    const currentInstanceId: string | undefined = useAppSelector((state) => selectInstanceIdForSelectedWorkitem(state));
    const currentRawInstanceId: string | undefined = useAppSelector((state) => selectInstanceRawIdForSelectedWorkitem(state));
    const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const currentWorkitemTitle: string = useAppSelector((state) => getSelectedWorkitemTitle(state));
    const currentArtifactId: string | undefined = useAppSelector((state) => selectArtifactIdForSelectedWorkitem(state));
    const currentRawArtifactId: string | undefined = useAppSelector((state) => selectRawArtifactIdForSelectedWorkitem(state));
    const studyWorkitemsListArray: string[] = JSON.parse(useAppSelector((state) => studyWorkitemsList(state, props.orderId ?? '')));
    const currentStudyTitle: string = useAppSelector((state) => selectedStudyTitle(state, props.orderId ?? ''));

    const artifactIdsForOrder = useAppSelector((state) => selectInstanceAndArtifactIdsForOrder(state, props.orderId));

    const imageLoadStatus = useAppSelector((state) => state.Orders.imageLoadStatus);

    const artifactIdsForOrderArray = JSON.parse(artifactIdsForOrder);

    const [dicomProcessedChecked, setDicomProcessedChecked] = useState<boolean>(false);
    const [jpgProcessedChecked, setJpgProcessedChecked] = useState<boolean>(false);
    const [tifRawChecked, setTifRawChecked] = useState<boolean>(false);
    const [dicomRawChecked, setDicomRawChecked] = useState<boolean>(false);


    const [zipChecked, setZipChecked] = useState<boolean>(false);
    const [annotations, setAnnotations] = useState<boolean>(false);
    const [scale, setScale] = useState<number[]>([1.0]);
    const [selectedImages, setSelectedImages] = useState<InstanceAndartefactIds[]>([]);
    const [isFileNameDialogExtended, setIsFileNameDialogExtended] = useState<boolean>(false);
    const [fileNamePatternProcessed, setFileNamePatternProcessed] = useState<string>('${date}__${workItemTitle}__processed__${instanceId}');
    const [fileNamePatternRaw, setFileNamePatternRaw] = useState<string>('${date}__${workItemTitle}__raw__${instanceId}');
    const [fileNamePatternZip, setFileNamePatternZip] = useState<string>('${date}__${studyTitle}__${orderId}');


    const handleDicomProcessedChange = () => {
        setDicomProcessedChecked(!dicomProcessedChecked);
    };

    const handleDicomRawChange = () => {
        setDicomRawChecked(!dicomRawChecked);
    };

    const handleJpgProcessedChange = () => {
        setJpgProcessedChecked(!jpgProcessedChecked);
    };

    const handleTifRawChange = () => {
        setTifRawChecked(!tifRawChecked);
    };

    const handleZipChange = () => {
        setZipChecked(!zipChecked);
    };

    const handleAnnotationsChange = (evt: any) => {
        setAnnotations(!annotations);
    };

    const handleSelectAll = (evt: React.MouseEvent<Element> | React.TouchEvent | undefined) => {
        setSelectedImages(studyWorkitemsListArray.map<InstanceAndartefactIds>((item: string) => { return { workitemId: item, workitemTitle: '', instanceId: '', instanceId_raw: '', artifactId: '', artifactId_raw: '' } }) ?? []);
    };

    const handleUnselectAll = (evt: React.MouseEvent<Element> | React.TouchEvent | undefined) => {
        setSelectedImages([]);
    };

    const onReducedClicked = (evt: React.MouseEvent<HTMLButtonElement>) => {
        setIsFileNameDialogExtended(!isFileNameDialogExtended)
    };

    const getValueForVariable = (name: string, item: InstanceAndartefactIds) => {
        let ret: string = '';
        if (name === 'date') {
            ret = format(new Date(), "yyyyMMddHHmmss");
        }
        if (name === 'workItemTitle') {
            ret = item?.workitemTitle?.replace(' ', '_') ?? '';
        }
        if (name === 'workitemId') {
            ret = item?.workitemId?.replace(' ', '_') ?? '';
        }
        if (name === 'instanceId') {
            ret = item?.instanceId ?? '';
        }
        if (name === 'artifactId') {
            ret = item?.artifactId?.replace(' ', '_') ?? '';
        }
        if (name === 'instanceId_raw') {
            ret = item?.instanceId_raw?.replace(' ', '_') ?? '' ?? '';
        }
        if (name === 'artifactId_raw') {
            ret = item?.artifactId?.replace(' ', '_') ?? '';
        }
        if (name === 'studyTitle') {
            ret = item?.studyTitle?.replace(' ', '_') ?? '' ?? '';
        }
        if (name === 'orderId') {
            ret = item?.orderId?.replace(' ', '_') ?? '';
        }
        return ret;
    }

    const getImageNameFormParser = (item: InstanceAndartefactIds, imageType: string, mimeType: string): string => {
        let evaluatedVariables = {};

        let fileNamePattern: string = '';
        if (imageType === 'processed') {
            fileNamePattern = fileNamePatternProcessed;
        } else if (imageType === 'raw') {
            fileNamePattern = fileNamePatternRaw;
        }
        else if (imageType === 'zip') {
            fileNamePattern = fileNamePatternZip
        }

        const parseResult = parseStringTemplate(fileNamePattern);

        parseResult?.variables?.forEach((variable: Variable) => {
            const val: string = getValueForVariable(variable?.name, item);
            evaluatedVariables = { ...evaluatedVariables, [variable?.name]: val };
        })

        let resultString = evaluateStringTemplate(
            fileNamePattern,
            evaluatedVariables,
            {}
        );
        resultString += `.${mimeType}`;
        return resultString;
    };



    const handleFileNamePatternProcessed = async (evt: any) => {
        setFileNamePatternProcessed(evt?.target?.value);
    }

    const handleFileNamePatternRaw = async (evt: any) => {
        setFileNamePatternRaw(evt?.target?.value);
    }

    const handleFileNamePatternZip = async (evt: any) => {
        setFileNamePatternZip(evt?.target?.value);
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            //dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
        }
    }

    useEffect(() => {
        if (props.refresh && props.refresh > 0) {
            doExport();
        }
        return () => {
            if (props.resetRefresh) {
                props.resetRefresh();
            }
            if (props.onActionDone) {
                props.onActionDone();
            }
        };
    }, [props.refresh]);

    useEffect(() => {
        if (props.orderId) {

            if (!isCornerstoneInitialized) {
                initCornerstone();
                dispatch({ type: 'ImageDisplay/setCornerstoneInitialized', payload: true });
            }

            if (isExportDialogVisible < 2 && currentArtifactId) {
                setSelectedImages([{ workitemId: currentWorkitemId, workitemTitle: currentWorkitemTitle, instanceId: currentInstanceId, instanceId_raw: currentRawInstanceId, artifactId: currentArtifactId, artifactId_raw: currentRawArtifactId }]);
            } else if (isExportDialogVisible >= 2) {
                setSelectedImages(studyWorkitemsListArray.map<InstanceAndartefactIds>((item: string) => { return { workitemId: item, workitemTitle: currentWorkitemTitle, instanceId: '', instanceId_raw: '', artifactId: '', artifactId_raw: '' } }) ?? []);
            }
        }

        return () => {
            setDicomProcessedChecked(false);
            setDicomRawChecked(false);
            setJpgProcessedChecked(false);
            setTifRawChecked(false);
            setZipChecked(false);
        };
    }, [isExportDialogVisible, props.orderId]);

    useEffect(() => {
        if (props.orderId) {
            const selectedImagesTmp = structuredClone(selectedImages);
            let itemChanged: boolean = false;
            for (let item of selectedImages) {
                if (item.instanceId === undefined || item.artifactId === undefined || item.instanceId === '' || item.artifactId === '') {
                    let img: InstanceAndartefactIds | undefined = undefined;

                    for (let i = 0; i < artifactIdsForOrderArray.length; i++) {
                        if (artifactIdsForOrderArray[i].workitemId === item.workitemId) {
                            img = artifactIdsForOrderArray[i];
                            break;
                        }
                    }
                    if (img && img?.instanceId && img.artifactId && img?.instanceId !== '' && img.artifactId !== '') {
                        const index: number = selectedImages.indexOf(item);
                        if (index >= 0) {
                            itemChanged = true;
                            selectedImagesTmp[index].instanceId = img.instanceId;
                            selectedImagesTmp[index].instanceId_raw = img.instanceId_raw;
                            selectedImagesTmp[index].artifactId = img.artifactId;
                            selectedImagesTmp[index].artifactId_raw = img.artifactId_raw;
                            selectedImagesTmp[index].workitemTitle = img.workitemTitle;
                        }
                    }
                }
            }
            if (itemChanged) {
                setSelectedImages(selectedImagesTmp);
            }
        }
    }, [artifactIdsForOrder, JSON.stringify(selectedImages)]);

    useEffect(() => {
        if (selectedImages.some((image: InstanceAndartefactIds) => image.artifactId_raw !== undefined && image.artifactId_raw !== '')) {
            setDicomRawChecked(true);
            setDicomProcessedChecked(false);
        } else {
            setDicomProcessedChecked(true);
            setDicomRawChecked(false);
        }
    }, [selectedImages.some((image: InstanceAndartefactIds) => image.artifactId_raw !== undefined && image.artifactId_raw !== '')]);

    const doExport = async () => {

        dispatch({ type: "ImageDisplay/setExportActive", payload: true });

        const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
        const enabledElement: EnabledElement | undefined = cornerstoneElements.find((element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`);

        //const zip = new JSZip();

        const fileStream = streamSaver.createWriteStream(getImageNameFormParser({ studyTitle: currentStudyTitle, orderId: props.orderId }, 'zip', 'zip'));
        const files: any[][] = [];

        if (dicomProcessedChecked) {
            for (let item of selectedImages) {

                if (item && item.instanceId && item.instanceId !== '') {
                    const url: string = `${serviceEndpoints.BACKEND_URL}/export/instance/${item.instanceId}?mapping=default`;
                    if (zipChecked) {
                        files.push([getImageNameFormParser(item, 'processed', 'dcm'), url])
                    } else {
                        const data: Blob = await fetch(url).then(r => r.blob());
                        saveAs(data, getImageNameFormParser(item, 'processed', 'dcm'));
                    }
                }
            }
        }

        if (dicomRawChecked) {
            for (let item of selectedImages) {
                if (item && item.instanceId_raw && item.instanceId_raw !== '') {
                    const url: string = `${serviceEndpoints.BACKEND_URL}/export/instance/${item.instanceId_raw}?mapping=default`;
                    if (zipChecked) {
                        files.push([getImageNameFormParser(item, 'raw', 'dcm'), url])
                    } else {
                        const data: Blob = await fetch(url).then(r => r.blob());
                        saveAs(data, getImageNameFormParser(item, 'raw', 'dcm'));
                    }
                }
            }
        }

        if (jpgProcessedChecked) {
            const orgHandleRadius: number = cornerstoneTools.store?.state?.handleRadius;
            const orgToolWidth: number = cornerstoneTools.toolStyle.getToolWidth();
            const orgFontSize: number = cornerstoneTools.textStyle.getFontSize();
            cornerstoneTools.textStyle.setFontSize(orgFontSize * scale[0]);
            cornerstoneTools.toolStyle.setToolWidth(orgToolWidth * scale[0]);
            cornerstoneTools.store.state.handleRadius = orgHandleRadius * scale[0];

            const printElement = document.createElement('div');

            if (printElement) {

                // @ts-ignore
                webGL.renderer.initRenderer = initRenderer;
                // @ts-ignore
                webGL.renderer.render = render;
                // @ts-ignore
                webGL.renderer.getRenderCanvas = getRenderCanvas;
                // @ts-ignore
                webGL.renderer.isWebGLAvailable = isWebGLAvailable;

                const options = {
                    renderer: "webgl",
                    desynchronized: true,
                    preserveDrawingBuffer: true,
                };

                // @ts-ignore
                cornerstone.enable(printElement, options);
                const enabledElementForExport: EnabledElement = cornerstone.getEnabledElement(printElement);
                const context = enabledElementForExport?.canvas?.getContext('2d', { willReadFrequently: true });

                try {
                    if (enabledElementForExport) {
                        let toolsToRender: any[] = [];
                        // loading tools is neccessary  for drawing annotation on image
                        if (annotations) {
                            const getTextCallback = (args: any) => { }
                            const changeTextCallback = (args: any) => { }
                            const configuration_TextMarker = {
                                markers: ['F5', 'F4', 'F3', 'F2', 'F1'],
                                current: 'F5',
                                ascending: true,
                                loop: true,
                                changeTextCallback,
                            }

                            const configuration_ArrowAnnotate = {
                                getTextCallback,
                                changeTextCallback,
                                drawHandles: true,
                                drawHandlesOnHover: false,
                                hideHandlesIfMoving: false,
                                arrowFirst: true,
                                renderDashed: false,
                                allowEmptyLabel: false,
                            }

                            addAllToolsToElement(enabledElementForExport, 0, configuration_TextMarker, configuration_ArrowAnnotate, false);

                            toolsToRender = cornerstoneTools.store?.state?.tools?.filter(
                                (tool: any) =>
                                    tool.element === enabledElementForExport?.element
                            );
                        }

                        for (let artifactId of selectedImages) {
                            if (artifactId && artifactId.artifactId && artifactId.artifactId !== '') {
                                await dispatch(
                                    loadImage({
                                        imageName: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${artifactId.artifactId}/raw`,
                                        imageId: currentWorkitemId,
                                        artifactId: artifactId.artifactId,
                                        artifactId_Raw: artifactId.artifactId_raw,
                                        createPreviewImage: addImageMode.none,
                                        matrixIndex: -1,
                                        display_raw: false,
                                        enabledElementForExport: enabledElementForExport,
                                    })
                                );
                                await waitforImageRendered(enabledElementForExport.element);

                                if (annotations) {
                                    const r = document.querySelector(':root');
                                    const elem = document.getElementById('container');

                                    if (r && elem) {
                                        const toolColor = getComputedStyle(elem)?.getPropertyValue('--toolColor');
                                        const toolActiveColor = getComputedStyle(elem)?.getPropertyValue('--toolActiveColor');

                                        if (toolColor) {
                                            cornerstoneTools.toolColors.setToolColor(toolColor);
                                        }
                                        if (toolActiveColor) {
                                            cornerstoneTools.toolColors.setActiveColor(toolActiveColor);
                                        }
                                    }

                                    let annotationDispatch: any = undefined;
                                    //@ts-ignore
                                    annotationDispatch = dispatch(apiSlice.endpoints.getAnnotation.initiate(enabledElementForExport?.image?.targetId));
                                    const annotations = (await annotationDispatch)?.data;
                                    //@ts-ignore
                                    restore(enabledElementForExport, annotations, enabledElementForExport?.image?.targetId, -1, true, dispatch);

                                    annotationDispatch?.unsubscribe();
                                }

                                const context = enabledElementForExport?.canvas?.getContext('2d');
                                context?.setTransform(1, 0, 0, 1, 0, 0);

                                const evt = {
                                    currentTarget: enabledElementForExport.element, detail:
                                        { viewport: enabledElementForExport.viewport, image: enabledElementForExport.image, element: enabledElementForExport.element, enabledElement: enabledElementForExport, canvasContext: context }
                                };

                                toolsToRender?.forEach((tool: any) => {
                                    if (tool.renderToolData) {
                                        context?.save();
                                        tool.renderToolData(evt);
                                        cornerstone.updateImage(enabledElementForExport?.element);
                                        context?.restore();
                                    }
                                });


                                if (zipChecked) {
                                    //zip.file(getImageName(artifactId, 'processed', 'jpg'), blob);
                                    files.push([getImageNameFormParser(artifactId, 'processed', 'jpg'), enabledElementForExport?.canvas?.toDataURL()])
                                } else {
                                    const blob: Blob | null = await new Promise(res => {
                                        enabledElementForExport?.canvas?.toBlob(res);
                                    });
                                    if (blob) {
                                        saveAs(blob, getImageNameFormParser(artifactId, 'processed', 'jpg'));
                                    }
                                }
                                //throw new Error("export exception");
                            }
                        }


                        if (enabledElement?.element) {
                            cornerstone.updateImage(enabledElement.element);
                        }
                        cornerstone.disable(enabledElementForExport.element);

                    }
                } catch (err) {
                    console.log("export failed");
                    console.log(err);
                }
                finally {
                    cornerstoneTools.textStyle?.setFontSize(orgFontSize);
                    cornerstoneTools.toolStyle?.setToolWidth(orgToolWidth);
                    cornerstoneTools.store.state.handleRadius = orgHandleRadius;

                    if (enabledElement?.element) {
                        enabledElementForExport?.element?.remove();
                    }
                }
            }
        }

        if (tifRawChecked) {
            for (let artifactId of selectedImages) {
                if (artifactId.artifactId_raw) {
                    const url = `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${artifactId.artifactId_raw}/raw`;

                    if (zipChecked) {
                        files.push([getImageNameFormParser(artifactId, 'raw', 'tif'), url])
                    } else {
                        const image = await fetch(url);
                        const imageBlob = await image.blob();
                        saveAs(imageBlob, getImageNameFormParser(artifactId, 'raw', 'tif'));
                    }
                }
            }
        }

        if (zipChecked) {
            const iterator = files.values();
            //@ts-ignore
            new ZIP({
                pull(ctrl: any) {
                    const it = iterator.next()
                    if (it.done) {
                        ctrl.close()
                    } else {
                        const [name, url] = it.value
                        if (typeof url === 'string') {
                            return fetch(url).then(res => {
                                ctrl.enqueue({
                                    name,
                                    stream: () => res.body
                                })
                            }).catch(error => console.log(error));
                        } else {
                            ctrl.enqueue({
                                name,
                                stream: () => url?.stream()
                            })
                        }
                    }
                }
            }).pipeTo(fileStream).then(() => dispatch({ type: "ImageDisplay/setExportActive", payload: false }))
        } else {
            dispatch({ type: "ImageDisplay/setExportActive", payload: false });
        }

        dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 0 });
    };


    const onImageSelect = (workitem: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined) => {
        if (artifactId) {
            if (selectedImages.find((item: InstanceAndartefactIds) => item.workitemId === workitem)) {
                const index: number = selectedImages.findIndex((item: InstanceAndartefactIds) => item.workitemId === workitem);
                if (index >= 0) {
                    const newSelectedImages: InstanceAndartefactIds[] = structuredClone(selectedImages);
                    newSelectedImages.splice(index, 1);
                    setSelectedImages(newSelectedImages);
                }
            } else {
                const newSelectedImages: InstanceAndartefactIds[] = structuredClone(selectedImages);
                newSelectedImages.push({ workitemId: workitem, workitemTitle: workitemData?.details?.title ?? '', instanceId: '', instanceId_raw: '', artifactId: artifactId, artifactId_raw: artifactId_raw });
                setSelectedImages(newSelectedImages);
            }
        }
    }

    return (
        <div className="printDialogWrapper" onContextMenu={(e) => e.preventDefault()}>
            <div className="selectionButtons">
                <button onClick={(evt) => handleSelectAll(evt)}>{t('selectAll')}</button>
                <button onClick={(evt) => handleUnselectAll(evt)}>{t('unselectAll')}</button>
            </div>

            <div className="imagesToExport">
                <label>{`${t('imagesToExport')}:`}</label>
            </div>
            <div className="preview-images-div">
                <div className="preview-images">
                    {studyWorkitemsListArray.map((item: string, i) =>
                        <div key={i.toString()} onClick={(evt) => { }}>
                            <Workitem onImageSelect={onImageSelect} orderId={props.orderId}
                                workitemId={item} getRawSeries={!props.isCalledFromOrderList} includeImageDesc={false} showPlannings={false} showDocuments={false}
                                className={selectedImages?.find((selectedItem: InstanceAndartefactIds) => item === selectedItem.workitemId) ? "previewImage-list-img-selected" : "previewImage-list-img"} showRejected={false} />
                        </div>
                    )
                    }
                </div>
            </div>

            <div className="hruleWithButton">
                <hr className="hrule" />
                <label>{`${t('FileNameConfiguration')}`}</label>
                <hr className="hrule" />
                <button className="reduced_button" onClick={(evt) => onReducedClicked(evt)}>
                    <img
                        src={isFileNameDialogExtended ? "/images/Pfeil_nach_oben2.svg" : "/images/Pfeil_nach_unten2.svg"}
                        alt={"Pfeil nach oben.svg"}
                        className="reduced_button_img"
                    />
                </button>
                <hr className="hrule2" />
            </div>

            <div className={isFileNameDialogExtended ? "fileNameConfig" : "fileNameConfig_invisible"}>
                <div className="fileNameConfig_entry">
                    <label>{`${t('processed')}:`}</label>
                    <input type="search" className="fileNameConfigInput" name="fileNameConfig" onChange={handleFileNamePatternProcessed} value={fileNamePatternProcessed}
                        onKeyDown={handleKeyDown} placeholder={t("Search") as string} />
                </div>
                <div className="fileNameConfig_entry">
                    <label>{`${t('raw')}:`}</label>
                    <input type="search" className="fileNameConfigInput" name="fileNameConfig" onChange={handleFileNamePatternRaw} value={fileNamePatternRaw}
                        onKeyDown={handleKeyDown} placeholder={t("Search") as string} />
                </div>
                <div className="fileNameConfig_entry">
                    <label>{`${t('ZipFile')}:`}</label>
                    <input type="search" className="fileNameConfigInput" name="fileNameConfig" onChange={handleFileNamePatternZip} value={fileNamePatternZip}
                        onKeyDown={handleKeyDown} placeholder={t("Search") as string} />
                </div>
            </div>

            <div className="exportConfig">
                <div className="exportConfigRow">
                    <div className="exportType">
                        <label>{t('imageType')}</label>
                    </div>
                    <div className="exportFormat">
                        <label>{t('imageFormat')}</label>
                    </div>
                </div>
                <hr className="hrule1" />
                <div className="exportConfigRow">
                    <div className="exportType">
                        <label>{t('processed')}</label>
                    </div>
                    <div className="exportFormat">
                        <div className="format" onClick={handleDicomProcessedChange}>
                            <input type="checkbox" checked={dicomProcessedChecked} onChange={handleDicomProcessedChange} />
                            <label className="dicondeLabel">Diconde</label>
                            <label className="dicomLabel">Dicom</label>
                        </div>
                        <div className="format" onClick={handleJpgProcessedChange}>
                            <input type="checkbox" checked={jpgProcessedChecked} onChange={handleJpgProcessedChange} />
                            <label>JPEG</label>
                        </div>
                        <div className={jpgProcessedChecked ? "annotations_popUp_visisble" : "annotations_popUp_invisisble"}>
                            <div className="arrow-up"></div>
                            <div className="annotations">
                                <input className="includeAnnotations" type="checkbox" checked={annotations} onChange={handleAnnotationsChange} />
                                <label onClick={handleAnnotationsChange}>{t('include annotations')}</label>
                                <span>
                                    <Range
                                        direction={Direction.Right}
                                        values={scale}
                                        step={0.1}
                                        min={0.5}
                                        max={5}
                                        rtl={false}
                                        onChange={(values) => setScale(values)}

                                        renderMark={({ props, index }) => (
                                            < div className='ticks'
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    height: '10px',
                                                    backgroundColor: '#ffff'
                                                }}
                                            />
                                        )}
                                        renderTrack={({ props, children }) => (
                                            <div className="scaleBar"
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                }}
                                            >
                                                {children}
                                            </div>
                                        )}
                                        renderThumb={({ props }) => (
                                            <div className="thumb"
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                }}
                                            />
                                        )}
                                    />
                                    <label className="scaleLabel">{scale[0]}</label>
                                </span>
                                <label>{t('annotation scale')}</label>
                            </div>
                        </div>
                    </div>

                </div>

                <hr className="hrule1" />


                {selectedImages.some((image: InstanceAndartefactIds) => image.artifactId_raw !== undefined && image.artifactId_raw !== '') && (
                    <>
                        <div className="exportConfigRow">
                            <div className="exportType">
                                <label>{t('raw')}</label>
                            </div>
                            <div className="exportFormat">
                                <div className="format" onClick={handleDicomRawChange}>
                                    <input type="checkbox" checked={dicomRawChecked} onChange={handleDicomRawChange} />
                                    <label className="dicondeLabel">Diconde</label>
                                    <label className="dicomLabel">Dicom</label>
                                </div>
                                <div className="format" onClick={handleTifRawChange}>
                                    <input type="checkbox" checked={tifRawChecked} onChange={handleTifRawChange} />
                                    <label>TIFF</label>
                                </div>
                            </div>
                        </div>
                        <hr className="hrule1" />
                    </>
                )}

                <div className="zip" onClick={handleZipChange}>
                    <input type="checkbox" checked={zipChecked} onChange={handleZipChange} />
                    <label>{t('exportAsZip')}</label>
                </div>
            </div>
        </div>
    );
};

export default ExportDialog;
