import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { throttle } from 'throttle-debounce';
import { apiSlice, device, deviceSet, property } from '../../apis/apiSlice';
import { AppDispatch, RootState } from '../../store';
import { selectCurrentDeviceProperties } from './AcquisitionSlice';
import { getValueForProperty } from './Device';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useORTranslation } from '../Localization/ORLocalization';
import { getLayout } from '../OrderList/OrdersSlice';
import NumericInput from '../Utils/NumericInput';
import { formatISO } from 'date-fns';
import { getTechniqueParameters, getValueForTechniqueParameter, selectTechniqueParameters, selectValueValidationStatus } from './GeneratorTechniqueParametersSlice';
import { selectedWorkitem } from '../ImageDisplay/ImageDisplaySlice';



interface DevicePropProps {
	device: device | deviceSet;
	endpoint: string;
	techniqueParameters?: any;
	property: [string, property];
	isfirstDefaultWidget: boolean;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const DeviceProp = (props: DevicePropProps) => {
	const dispatch = useAppDispatch();

	const { t } = useORTranslation(['Acquisition', 'common']);
	const { language } = useORTranslation([])

	const [error, setError] = useState<string>('');
	const [value, setValue] = useState<any>(undefined);

	const valueRef = useRef<any>();
	valueRef.current = value;

	const currentPropertyValues: [string, any][] = JSON.parse(useAppSelector((state) => selectCurrentDeviceProperties(state, props.device.config.endpoint)));
	const layout: any = useAppSelector((state) => getLayout(state));
	const currentTechniqueParametersString: string | undefined = useAppSelector((state) => selectTechniqueParameters(state));
	const currentTechniqueParameters = currentTechniqueParametersString ? JSON.parse(currentTechniqueParametersString) : undefined;
	const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
	const valueForTechniqueParameter: any = useAppSelector((state) => getValueForTechniqueParameter(state, props.property[0]));
	const valueValidationStatus: number = useAppSelector((state) => selectValueValidationStatus(state));

	const throttleOnValueChange = useCallback(throttle(200, async (inValue: any) => {
		if (props.techniqueParameters) {
			const url = (new URL(props.endpoint)).origin + props.techniqueParameters?.links[0]?.href;
			let bodyInput: any = { actionRef: formatISO(new Date()) };
			currentPropertyValues?.forEach(currentProperty => {
				if (props.techniqueParameters?.input?.properties[currentProperty[0]]) {
					bodyInput = { ...bodyInput, [currentProperty[0]]: currentProperty[1] }
				}
			});
			bodyInput[props.property[0]] = inValue;
			const body = { setTechniqueParameters: { input: bodyInput } };
			try {
				await dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: url, body: body })).unwrap();
			} catch (err: any) {
				setError(err?.error);
			}
		} else {
			const url = (new URL(props.endpoint)).origin + props.property[1]?.links[0]?.href;
			let body: any = undefined;
			if (props.property[0]) {
				if (props.property[1]?.type === 'integer' || props.property[1]?.type === 'number') {
					setValue(inValue);
					body = { [props.property[0]]: Number(inValue) };
				} else if (props.property[1]?.type === 'boolean') {
					body = { [props.property[0]]: !valueRef?.current };
					setValue(valueRef?.current === undefined ? true : !valueRef?.current);
				} else {
					setValue(inValue);
					body = { [props.property[0]]: inValue };
				}

				setError('');
				try {
					await dispatch(apiSlice.endpoints.putWebThingProp.initiate({ url: url, body: body })).unwrap();
				} catch (err: any) {
					setError(err?.error);
				}
			}
		}


	}), [props?.device?.category, props?.device?.id, currentPropertyValues]);

	const throttleOnTechniqueParameterChange = throttle(200, async (up: boolean) => {
		const generatorParams = currentPropertyValues.find((propertyValues: any) => propertyValues[0] === 'generator_params');
		console.log(generatorParams);
		if (Array.isArray(generatorParams) && generatorParams.length > 1) {
			switch (props.property[0]) {
				case 'voltage':
					if (generatorParams[1]?.voltageList) {
						let currentVoltageIndex = -1;
						for (let i = 0; i < generatorParams[1]?.voltageList.length; i++) {
							if (generatorParams[1]?.voltageList[i] === currentTechniqueParameters?.voltage?.value) {
								currentVoltageIndex = i;
							}
						}
						if (currentVoltageIndex > -1) {
							let newVoltage = -1;
							if (up && currentVoltageIndex < generatorParams[1]?.voltageList.length - 1) {
								newVoltage = generatorParams[1]?.voltageList[currentVoltageIndex + 1];
							} else if (!up && currentVoltageIndex > 0) {
								newVoltage = generatorParams[1]?.voltageList[currentVoltageIndex - 1];
							}
							if (newVoltage > -1) {
								const actionRef = currentWorkitemId + "_" + formatISO(new Date());
								dispatch({ type: "GeneratorTechniqueParameters/setCurrentActionRef", payload: actionRef });
								dispatch(getTechniqueParameters({
									voltage: newVoltage,
									exposure: currentTechniqueParameters?.exposure?.value,
									generatorEndpoint: props.device.config.endpoint, actionRef
								}));
							}
						};
					}
					break;
				case 'exposure':
					if (generatorParams[1]?.exposureList) {
						let currentexposureListIndex = -1;
						for (let i = 0; i < generatorParams[1]?.exposureList.length; i++) {
							if (generatorParams[1]?.exposureList[i] === currentTechniqueParameters?.exposure?.value) {
								currentexposureListIndex = i;
							}
						}
						if (currentexposureListIndex > -1) {
							let newExposure = -1;
							if (up && currentexposureListIndex < generatorParams[1]?.exposureList.length - 1) {
								newExposure = generatorParams[1]?.exposureList[currentexposureListIndex + 1];
							} else if (!up && currentexposureListIndex > 0) {
								newExposure = generatorParams[1]?.exposureList[currentexposureListIndex - 1];
							}
							if (newExposure > -1) {
								const d = new Date();
								const actionRef = currentWorkitemId + "_" + formatISO(d) + d.getMilliseconds();
								await dispatch({ type: "GeneratorTechniqueParameters/setCurrentActionRef", payload: actionRef });
								dispatch(getTechniqueParameters({
									voltage: currentTechniqueParameters?.voltage?.value,
									exposure: newExposure,
									generatorEndpoint: props.device.config.endpoint, actionRef
								}));
							}
						};
					}
					break;
				default:
					break
			}
		} else {
			let value: number = getValueForProperty(props.property[0], currentPropertyValues) ?? 0;
			if (up) {
				value = value + 1;
			} else {
				value = value + -1;
			}
			const url = (new URL(props.endpoint)).origin + props.property[1]?.links[0]?.href;
			let body: any = undefined;
			if (props.property[0]) {
				if (props.property[1]?.type === 'integer' || props.property[1]?.type === 'number') {
					setValue(value);
					body = { [props.property[0]]: Number(value) };
				} else if (props.property[1]?.type === 'boolean') {
					body = { [props.property[0]]: !valueRef?.current };
					setValue(valueRef?.current === undefined ? true : !valueRef?.current);
				} else {
					setValue(value);
					body = { [props.property[0]]: value };
				}

				setError('');
				try {
					await dispatch(apiSlice.endpoints.putWebThingProp.initiate({ url: url, body: body })).unwrap();
				} catch (err: any) {
					setError(err?.error);
				}
			}
		}
	});

	useEffect(() => {
		setValue(getValueForProperty(props.property[0], currentPropertyValues));
	}, [JSON.stringify(getValueForProperty(props.property[0], currentPropertyValues)), language]);

	function getPropsItem() {
		if ((props.property[1]?.type === 'integer' || props.property[1]?.type === 'number') &&
			props.property[1]?.minimum !== undefined && props.property[1]?.maximum !== undefined) {
			return <div className="propertyRow" title={`${t(props.property[1]?.description)}`}>
				<div className="propertyRowInputWrapper">
					<NumericInput title={t(props.property[1]?.title) ?? ''} units={props.property[1]?.unit?.replace('milliseconds', 'ms')}
						onPlusChange={throttleOnTechniqueParameterChange} onMinusChange={throttleOnTechniqueParameterChange}
						isLimit={valueForTechniqueParameter?.isLimit}
						startValue={getValueForProperty(props.property[0], currentPropertyValues) ?? 0} valueValidation={valueValidationStatus}
					/>
				</div>
			</div>
		} else if (props.property[1]?.type === 'integer' || props.property[1]?.type === 'number') {
			return <div className="propertyRow" title={`${t(props.property[1]?.description)}`}>
				<div className="propertyRowInputWrapper">
					<NumericInput title={t(props.property[1]?.title) ?? ''} units={props.property[1]?.unit?.replace('milliseconds', 'ms')}
						onPlusChange={throttleOnTechniqueParameterChange} onMinusChange={throttleOnTechniqueParameterChange}
						isLimit={valueForTechniqueParameter?.isLimit}
						startValue={getValueForProperty(props.property[0], currentPropertyValues) ?? 0} valueValidation={-1}
					/>
				</div>
			</div>
		} else if (props.property[1]?.type === 'integer' || props.property[1]?.type === 'number' || props.property[1]?.type === 'string') {
			return <div className="propertyRow" title={`${t(props.property[1]?.description)}`}>
				<div className="propertyRowInputWrapper">
					<label className="propertyRowLabel">{t(props.property[1]?.title) ?? ''}</label>
					<div className={error === '' ? "propertyRow-NumberInput" : "propertyRow-NumberInput_error"} >
						<input type={props.property[1]?.type === 'integer' ? "number" : "text"} onChange={(evt: any) => throttleOnValueChange(evt?.target?.value)} value={value ?? ''}
							title={error === '' ? undefined : error} />
						{props.property[1]?.unit !== undefined ?
							<label className="propertyRowUnit">{props.property[1]?.unit?.replace('milliseconds', 'ms')}</label>
							: null}
					</div>
				</div>
			</div>
		}
		else if (props.property[1]?.type === 'boolean' && props?.device?.category !== 'SET') {
			return <div className="propertyRow" title={`${t(props.property[1]?.description)}`}>
				<div className="propertyRowInputWrapper">
					<label className="propertyRowLabel">{t(props.property[1]?.title) ?? ''}</label>
					<div className={error === '' ? "propertyRow-BooleanInput" : "propertyRow-BooleanInput_error"}
						data-value1={t("on", { ns: "common" })} data-value2={t("off", { ns: "common" })}>
						<input id={`propertyRow-BooleanInput_${props?.device?.id}`} type="checkbox"
							onChange={(evt: any) => throttleOnValueChange(evt?.target?.value)} checked={value ?? false}
							title={error === '' ? undefined : error} />
						<label htmlFor={`propertyRow-BooleanInput_${props?.device?.id}`}></label>
					</div>
				</div>
			</div>
		}
	}

	return (
		<>
			{(props.isfirstDefaultWidget && (props.property[0] !== "softwareTriggerDelay" || layout?.deviceSet_panel_display_footer)) || props.property[0] === 'generator_params' ? <hr className="hrule3" /> : <div className="vSpace" />}
			{getPropsItem()}
		</>
	);
}

export default DeviceProp;
