import React, { useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

import LayoutChooser from "./Tools/LayoutChooser";
import { Constants } from "../../Constants";
import { getHasRawImage, getRawImageActive, getToolInsetActive, selectedWorkitem, selectMatrixColumns, selectMatrixIndex, selectMatrixRows, selectToolInsetVisible } from "./ImageDisplaySlice";
import { selectInstanceIdForSelectedWorkitem, getSelectedWorkitemTitle, getImagePathForCurrentTheme, getSelectedWorkitemState, loadImage, addImageMode, selectArtifactIdForSelectedWorkitem, selectRawArtifactIdForSelectedWorkitem, selectArtifactIdForCorrelationId } from "../OrderList/OrdersSlice";
import cornerstone, { EnabledElement } from "cornerstone-core";
import { selectImagePolarity } from "../OrderList/MainOrderListSlice";
import ImagePolarityIcon from "./ImagePolarityIcon";
import { useORTranslation } from "../Localization/ORLocalization";
import { serviceEndpoints } from "../..";


interface HeaderInsetRightProps {
    orderId: string;
    onSnapshotClick: (imageFileName: string) => void;
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const HeaderInsetRight = (props: HeaderInsetRightProps) => {
    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['NavbarImage']);

    const ref = useRef<HTMLDivElement>(null);

    const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const currentWorkitemState: string = useAppSelector((state) => getSelectedWorkitemState(state));
    //const currentArtifactId: string | undefined = useAppSelector((state) => selectArtifactIdForSelectedWorkitem(state));
    //const currentRawArtifactId: string | undefined = useAppSelector((state) => selectRawArtifactIdForSelectedWorkitem(state));
    const hasRawImage: boolean | undefined = useAppSelector((state) => getHasRawImage(state, selectedMatrixIndex ?? 0));
    const isRawImageActive: boolean | undefined = useAppSelector((state) => getRawImageActive(state, selectedMatrixIndex ?? 0));
    const isToolInsetVisible: boolean | undefined = useAppSelector((state) => selectToolInsetVisible(state));
    const isToolInsetActive: boolean | undefined = useAppSelector((state) => getToolInsetActive(state, selectedMatrixIndex ?? 0));
    const [viewportSync, setViewportSync] = useState<boolean>(false);
    const currentWorkitemTitle: string = useAppSelector((state) => getSelectedWorkitemTitle(state));
    const currentInstanceId: string | undefined = useAppSelector((state) => selectInstanceIdForSelectedWorkitem(state));
    const currentMatrixColumns: number | undefined = useAppSelector((state) => selectMatrixColumns(state));
    const currentMatrixRows: number | undefined = useAppSelector((state) => selectMatrixRows(state));
    const currentImagePolarity: number = useAppSelector((state) => selectImagePolarity(state));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const currentArtifactId: string | undefined = useAppSelector((state) => selectArtifactIdForCorrelationId(state, selectedMatrixIndex ?? 0));
    const currentRawArtifactId: string | undefined = useAppSelector((state) => selectArtifactIdForCorrelationId(state, selectedMatrixIndex ?? 0, true));

    const syncronizeViewports = () => {
        dispatch({ type: "ImageDisplay/setViewportSynchronization", payload: !viewportSync });
        setViewportSync(!viewportSync);
    };

    const swapImagePolarity = () => {
        dispatch({ type: "MainOrderListColumns/setImagePolarity", payload: currentImagePolarity === 1 ? 0 : 1 });
        const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
        cornerstoneElements.forEach(element => {
            cornerstone.updateImage(element.element);
        });
    };

    const showRawImage = () => {
        /* const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
        const displayElement: EnabledElement | undefined = cornerstoneElements.find(
            (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
        );
        if (displayElement && displayElement.viewport) {
            const currentViewport = JSON.parse(JSON.stringify(displayElement?.viewport));
            dispatch({ type: "ImageDisplay/setViewportForMatrixIndex", payload: { matrixIndex: selectedMatrixIndex, viewport: currentViewport, currentWorkitemId: currentWorkitemId } });
        } */

        //console.log("currentArtifactId: " + currentArtifactId + " currentRawArtifactId: " + currentRawArtifactId);

        if (currentArtifactId !== undefined && currentRawArtifactId !== undefined) {

            dispatch(
                loadImage({
                    imageName: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${currentArtifactId}/raw`,
                    imageId: currentWorkitemId,
                    artifactId: currentArtifactId,
                    artifactId_Raw: currentRawArtifactId,
                    createPreviewImage: addImageMode.none,
                    matrixIndex: selectedMatrixIndex ?? -1,
                    display_raw: !isRawImageActive,
                    enabledElementForExport: undefined,
                })
            );


            dispatch({ type: "ImageDisplay/setRawImageActive", payload: { matrixIndex: selectedMatrixIndex, isActive: !isRawImageActive } });
        }

    };

    const showToolsTabPanel = (open: boolean) => {
        dispatch({ type: "ImageDisplay/setToolInsetVisible", payload: open })
    }


    return (
        <div className="headerInsetRight" ref={ref}>
            <div className={isToolInsetActive ? "toolsTabPanel_visible" : "toolsTabPanel_invisible"}>
                {isToolInsetVisible ?
                    <button className="toolsTabPositionButton" title={t("closeToolsChartsPanels") as string} onMouseDown={() => showToolsTabPanel(false)}>
                        <img className="toolsTabPanelImgOpen"
                            src={`${imagePathForCurrentTheme}window-restore-svgrepo-com.svg`}
                            onError={(event: any) => { event.target.src = "/images/window-restore-svgrepo-com.svg"; event.onerror = null }}
                            alt="ToolsTabPanelImg" />
                    </button>
                    :
                    <button className="toolsTabPositionButton" title={t("openToolsChartsPanels") as string} onMouseDown={() => showToolsTabPanel(true)}>
                        <img className="toolsTabPanelImgClose"
                            src={`${imagePathForCurrentTheme}square-o.svg`}
                            onError={(event: any) => { event.target.src = "/images/square-o.svg"; event.onerror = null }}
                            alt="ToolsTabPanelImg" />
                    </button>
                }
            </div>
            <span className={isToolInsetActive ? "vl" : "vl_invisible"} />
            <div className="layoutChooser">
                <button className="layoutChooserButton" title={t("layout") as string} onTouchStart={(evt) => {
                    const mouseoverEvent = new Event('mouseover');
                    evt?.target?.dispatchEvent(mouseoverEvent)
                }}>
                    <img className="layoutChooserImg"
                        src={`${imagePathForCurrentTheme}th.svg`}
                        onError={(event: any) => { event.target.src = "/images/th.svg"; event.onerror = null }}
                        alt="Layout" />
                </button>
                <LayoutChooser Rows={3} Columns={3} />
            </div>
            <span className="vl" />
            <div className={viewportSync ? "viewportSyncButton" : "viewportSyncButton_invisible"} title={t("synchronizeViewports") as string}>
                <img className="viewportSyncButtonImg" onMouseDown={() => syncronizeViewports()} onTouchStart={() => syncronizeViewports()}
                    src={`${imagePathForCurrentTheme}link-svgrepo-com.svg`}
                    onError={(event: any) => { event.target.src = "/images/link-svgrepo-com.svg"; event.onerror = null }}
                    alt="Syncronize viewports" />
            </div>
            <div className={viewportSync ? "viewportSyncButton_invisible" : "viewportSyncButton"} title={t("synchronizeViewports") as string}>
                <img className="viewportSyncButtonImg" onMouseDown={() => syncronizeViewports()} onTouchStart={() => syncronizeViewports()}
                    src={`${imagePathForCurrentTheme}link-broken-svgrepo-com.svg`}
                    onError={(event: any) => { event.target.src = "/images/link-broken-svgrepo-com.svg"; event.onerror = null }}
                    alt="Syncronize viewports" />
            </div>
            <span className="vl" />
            <div className="snapshotButton" title={t("snapshot") as string}>
                <img className="snapshotButtonImg" onMouseDown={() => props.onSnapshotClick(currentWorkitemTitle ?
                    `${currentWorkitemTitle}_${currentMatrixColumns === 1 && currentMatrixRows === 1 ? currentInstanceId : props.orderId}.jpg`
                    : "download-this-image.jpg")} onTouchStart={() => props.onSnapshotClick("download-this-image.jpg")}
                    src={`${imagePathForCurrentTheme}photo-camera-photocamera-svgrepo-com.svg`}
                    onError={(event: any) => { event.target.src = "/images/photo-camera-photocamera-svgrepo-com.svg"; event.onerror = null }}
                    alt="Snapshot" />
            </div>
            <span className="vl" />
            <div className="imagePolarity" title={t("imagePolarity") as string}>
                {/* <img className={currentImagePolarity === 1 ? "imagePolarityImg" : "imagePolarityImg_inverse"} onMouseDown={() => swapImagePolarity()} onTouchStart={() => swapImagePolarity()} src="/images/polarity.svg" alt="invert-svgrepo-com.svg" /> */}
                {/* <div onMouseDown={() => swapImagePolarity()} onTouchStart={() => swapImagePolarity()}>
                    <ImagePolarityIcon polarity={currentImagePolarity} />
                </div> */}
                <img className="imagePolarityIcon" onMouseDown={() => swapImagePolarity()} onTouchStart={() => swapImagePolarity()}
                    src={`${imagePathForCurrentTheme}imagePolarity.svg`}
                    onError={(event: any) => { event.target.src = "/images/imagePolarity.svg"; event.onerror = null }}
                    alt="imagePolarity.svg" />
            </div>
            <span className={hasRawImage ? "vl" : "vl_noRawImage"} />
            <div className={hasRawImage ? "showRawImageButton" : "showRawImageButton_none"} title={t("showRawImage") as string}>
                <img className={isRawImageActive ? "showRawImageImg_active " : "showRawImageImg"} onMouseDown={() => showRawImage()} onTouchStart={() => showRawImage()}
                    src={`${imagePathForCurrentTheme}scale-to-original-svgrepo-com.svg`}
                    onError={(event: any) => { event.target.src = "/images/scale-to-original-svgrepo-com.svg"; event.onerror = null }}
                    alt="showRawImage" />
            </div>
        </div>
    );
};

export default HeaderInsetRight;
