import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { selectIsAcquistionOngoing } from "../Akquisition/AcquisitionSlice";
import { getExportActive, getMatrixIndexForInsert, selectMatrixIndex, getIsImageProcessing, selectReloadApplicationTimeout, selectReloadApplicationTimeoutId } from "./ImageDisplaySlice";
import Spinner from "./Spinner";

interface LoadingIndicatorProps {
  index: number;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const dispatch = useAppDispatch();
  const imageLoadStatus = useAppSelector((state) => state.Orders.imageLoadStatus);
  const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
  const isAkquisitionButtonActive: boolean = useAppSelector((state) => state.Acquisition.isAcquistionButtonActive) ?? false;
  const isAcquistionOngoing: boolean = useAppSelector((state) => selectIsAcquistionOngoing(state)) ?? false;
  const matrixIndexForInsert: number = useAppSelector((state) => getMatrixIndexForInsert(state, -1));
  const isExportActive: boolean = useAppSelector((state) => getExportActive(state));
  const isImageProcessing: boolean = useAppSelector((state) => getIsImageProcessing(state, props.index));
  const [isLoadingIndicatorVisible, setIsLoadingIndicatorVisible] = useState<boolean | undefined>(false);
  const timeoutid: number | undefined = useAppSelector((state) => selectReloadApplicationTimeoutId(state, props.index));
  const timeout: number = useAppSelector((state) => selectReloadApplicationTimeout(state));

  useEffect(() => {

    // console.log( "--------------------- " + props.index + " ---------------------");

    /* console.log("imageLoadStatus: " + imageLoadStatus + " selectedMatrixIndex: " + selectedMatrixIndex + " matrixIndexForInsert: " + matrixIndexForInsert + " props.index: " +
      props.index + " isAkquisitionButtonActive: " + isAkquisitionButtonActive + " isAcquistionOngoing: " + isAcquistionOngoing + " isExportActive: " + isExportActive + "   " +
      " isImageProcessing: " + isImageProcessing); */


    /* if (isAkquisitionButtonActive) {
      setIsLoadingIndicatorVisible((props.index === -1 ||
        (imageLoadStatus === "loading" && selectedMatrixIndex === props.index)) ||
        (isAkquisitionButtonActive && isAcquistionOngoing && selectedMatrixIndex === props.index));
    } else {
      setIsLoadingIndicatorVisible((props.index === -1 ||
        (imageLoadStatus === "loading" && matrixIndexForInsert === props.index)) ||
        (isAkquisitionButtonActive && isAcquistionOngoing && matrixIndexForInsert === props.index));
    } */

    //const oldState = isLoadingIndicatorVisible;

    /* console.log((props.index === -1 ||
      (imageLoadStatus === "loading" && selectedMatrixIndex === props.index && !isExportActive)) ||
      (isAkquisitionButtonActive && isAcquistionOngoing && selectedMatrixIndex === props.index) || isImageProcessing);
    console.log((imageLoadStatus === "loading" && selectedMatrixIndex === props.index && !isExportActive) ||
      (isAkquisitionButtonActive && isAcquistionOngoing && selectedMatrixIndex === props.index) || isImageProcessing); */

    const newState = (props.index === -1 ||
      (imageLoadStatus === "loading" && selectedMatrixIndex === props.index && !isExportActive)) ||
      (isAkquisitionButtonActive && isAcquistionOngoing && selectedMatrixIndex === props.index) || isImageProcessing;

    setIsLoadingIndicatorVisible(newState);

    if (newState && props.index > -1) {
      if (timeoutid !== undefined) {
        console.log("before setting new timeout, clearing current timeout " + timeoutid);
        window.clearTimeout(timeoutid);
      }
      if (timeout > 0) {
        let newid = window.setTimeout(() => {
          dispatch({ type: 'ImageDisplay/setReloadApplicationDialogVisible', payload: true });
        }, timeout * 1000);
        console.log("Add timeout " + newid);
        dispatch({ type: 'ImageDisplay/setReloadApplicationTimeoutId', payload: {matrixIndex: props.index, reloadApplicationTimeoutId: newid} });
      }
    }

    if (!newState) {
      if (timeoutid !== undefined) {
        console.log("Clear timeout " + timeoutid);
        window.clearTimeout(timeoutid);
      }
    }

    return () => { if (timeoutid !== undefined) window.clearTimeout(timeoutid); }
  }, [props.index, imageLoadStatus, selectedMatrixIndex, matrixIndexForInsert, isAkquisitionButtonActive,
    isAcquistionOngoing, isExportActive, isImageProcessing]);

  return <div className="loadingIndicator">{
    isLoadingIndicatorVisible ?
      <Spinner className="Spinner" />
      : null}</div>;
};

export default LoadingIndicator;
