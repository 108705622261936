import { useState } from "react";
import classNames from "classnames";
import "./RepresentativeDialogButton.scss"
import InfoPanel from "../Utils/ConfigurableInfoPanel/InfoPanel";

interface RepresentativeDialogButtonProps {
    className: string
    buttonImageSource: string
}

export function RepresentativeDialogButton (props: RepresentativeDialogButtonProps) {

    const [isVisible, setIsVisible] = useState(false)

    const toggleRepresentativeDialog = () => {
        setIsVisible((oldState) => !oldState)
    };

    const representativeDialogClasses = classNames(
        "representativeDialogContainer",
        {
            "visible": isVisible,
        }
    )

    return (
        <>
            <button className={props.className} id="rep-button" onClick={() => toggleRepresentativeDialog()}>
                <img className='icon' id='CH-REP-logo' src={props.buttonImageSource}/>
            </button>
            <div className={representativeDialogClasses}>
                <div className="header">
                    <img className="icon" id="CH-REP-logo" src={props.buttonImageSource} />
                    <button className="closeButton" onClick={() => toggleRepresentativeDialog()}>
                        <img className="icon" src="/images/Schliessen_Kreuz.svg" />
                    </button>
                </div>
                <div className="body">
                <InfoPanel
                    className="representativeContent"
                    configFile={"/RepresentativeDialog.json"}
                    workitemId={undefined}
                    artifactId={undefined}
                    orderId={''}
                    sources={undefined}
                    editMode={false}
                    editEnabled={false}
                    translationNameSpaces={["ProductInfoDialog"]}
                />

                </div>
            </div>
        </>
    )
}
