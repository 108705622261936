import cornerstone, { EnabledElement } from "cornerstone-core";
import { webGL } from "cornerstone-core";
import { useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { Constants } from "../../Constants";
import { AppDispatch, RootState } from "../../store";
import { addImageMode, getImageDisplayInvalidated, getSelectedWorkitem, isSelectedWorkitemPlanning, loadImage } from "../OrderList/OrdersSlice";
import { DisplayItemType, getCornerstoneTargetIdAtMatrixIndex, getDisplayItemTypeAtMatrixIndex, getImageIdAtMatrixIndex,
  getNewSelectedMatrixIndex, selectedWorkitem, selectIsOverviewAndAnnotationsVisible,
  selectMatrixColumns, selectMatrixIndex, selectMatrixRows, getProtocolCorrelationIdAtMatrixIndex, getSupressWWWCHistonRightMouse,
  selectReloadApplicationDialogVisible} from "./ImageDisplaySlice";
import LoadingIndicator from "./LoadingIndicator";
import ReloadApplicationDialog from "./ReloadApplicationDialog";
import ViewportOverlay from "./ViewportOverlay";
import DosisOverlay from "./DosisOverlay";
import RejectedOverlay from "./RejectedOverlay";
import { render, initRenderer, getRenderCanvas, isWebGLAvailable } from '../FromCornerstoneCore/renderer.js';
import React from "react";
import TooltabsfloatPanel from "./TooltabsfloatPanel";
import useResizeObserver from "use-resize-observer";
import Annotation from "../OrderList/Annotation";
import PdfDocument from "./PdfDocument";
import csTools from 'cornerstone-tools';
import { serviceEndpoints } from "../..";
import { apiSlice } from "../../apis/apiSlice";
import { useORTranslation } from "../Localization/ORLocalization";
import classNames from "classnames";
import CommonDialog from "../Utils/CommonDialog";

type ViewportElementProps = {
  index: number;
  orderId: string;
};

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const ViewportElement = (props: ViewportElementProps) => {
  const dispatch = useAppDispatch();

  const viewportElement = useRef<HTMLDivElement>(null);
  const imageLoadStatus = useAppSelector((state) => state.Orders.imageLoadStatus);
  const [rightMouseDown, setRightMouseDown] = useState<boolean>(false);
  const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
  const currentMatrixColumns: number | undefined = useAppSelector((state) => selectMatrixColumns(state));
  const currentMatrixRows: number | undefined = useAppSelector((state) => selectMatrixRows(state));
  const currentImageId: string | undefined = useAppSelector((state) => getImageIdAtMatrixIndex(state, props.index));
  const currentProtocolCorrelationId: string | undefined = useAppSelector((state) => getProtocolCorrelationIdAtMatrixIndex(state, props.index));
  const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
  const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
  const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
  const imageDisplayInvalidated: boolean = useAppSelector(state => getImageDisplayInvalidated(state));
  const cornerstoneTargetId: string = useAppSelector((state) => getCornerstoneTargetIdAtMatrixIndex(state, props.index));
  const displayItemType = useAppSelector((state) => getDisplayItemTypeAtMatrixIndex(state, props.index));
  const isOverviewAndAnnotationsVisible: boolean = useAppSelector((state) => selectIsOverviewAndAnnotationsVisible(state));
  const isWorkitemPlanning: boolean = useAppSelector((state) => isSelectedWorkitemPlanning(state));
  const matrixImageAtStart: { matrixIndex: number; imageId: string } | undefined = useAppSelector((state) => getNewSelectedMatrixIndex(state));
  const supressWWWCHistonRightMouse: boolean = useAppSelector((state) => getSupressWWWCHistonRightMouse(state));
  const isReloadDialogVisible: boolean = useAppSelector((state) => selectReloadApplicationDialogVisible(state));

  const { t } = useORTranslation(['common']);

  const [isStepRejected, setIsStepRejected] = useState<boolean>(false);

  /* function isRejected(prot: any) {
    let ret = false;

    if (currentProtocolCorrelationId === "")
      console.log("have no currentProtocolCorrelationId");

    if (prot && prot.steps && currentProtocolCorrelationId && currentProtocolCorrelationId !== "") {
      let correlatedSteps = prot.steps.filter((item: any) => item.correlationId === currentProtocolCorrelationId);
      if (correlatedSteps && correlatedSteps.length > 0)
        ret = (correlatedSteps[0].rejected !== undefined);
    }
    return ret;
  }
  const isCurrentProtocolStepRejected: boolean = isRejected(currentWorkitem?.protocol); */

  const onMouseDown = (evt: React.MouseEvent<Element>) => {
    if (evt.button === 2) {
      setRightMouseDown(true);
      if (!supressWWWCHistonRightMouse) {
        dispatch({ type: "ImageDisplay/setWindowLevelHistActive", payload: { matrixIndex: props.index, isActive: true } });
        dispatch({ type: "ImageDisplay/setSelectedToolTab", payload: { matrixIndex: props.index, selectedTooltab: 'WindowLevelHist' } });
      }
    }

    //dispatch({ type: "ImageDisplay/setSelectedIndex", payload: props.index });
    //dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: currentImageId });
  };

  const onClick = (evt: React.MouseEvent<Element>) => {
    dispatch({ type: "ImageDisplay/setSelectedIndex", payload: props.index });
    dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: currentImageId });
  };

  const onMouseUp = (evt: React.MouseEvent<Element>) => {
    if (evt.button === 2) {
      setRightMouseDown(false);
    }
  };

  const handleDragOver = (evt: any) => {
    //evt.stopPropagation();
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "copy"; // Explicitly show this is a copy.
  };

  const handleReloadDialogClose = () => {
    dispatch({ type: "ImageDisplay/setReloadApplicationDialogVisible", payload: false });
  };

  useEffect(() => {
    const element: HTMLElement | null = viewportElement.current;

    // @ts-ignore
    webGL.renderer.initRenderer = initRenderer;
    // @ts-ignore
    webGL.renderer.render = render;
    // @ts-ignore
    webGL.renderer.getRenderCanvas = getRenderCanvas;
    // @ts-ignore
    webGL.renderer.isWebGLAvailable = isWebGLAvailable;

    const options = {
      renderer: "webgl",
      desynchronized: true,
      preserveDrawingBuffer: true,
    };

    // @ts-ignore
    if (element) cornerstone.enable(element, options);

    return () => {
      if (element) cornerstone.disable(element);
    };
  }, [props.orderId, dispatch, imageDisplayInvalidated]);

  useEffect(() => {

    // this function gets called once the user drops the file onto the div
    function handleFileSelect(evt: any) {
      //evt.stopPropagation();
      evt.preventDefault();

      // src may be image from preview panel
      const currentId: string = evt.dataTransfer.getData("text/plain");
      // Get the FileList object that contains the list of files that were dropped
      const files = evt.dataTransfer.files;

      //  drag - drop from preview panel
      if (currentId && currentId.length > 0) {
        try {
          const transferObject: { workitemId: string, artifactId: string, artifactId_Raw: string, correlationId: string } = JSON.parse(currentId);
          dispatch({ type: "ImageDisplay/setProtocolCorrelationId", payload: { matrixIndex: props.index, protocolCorrelationId: transferObject.correlationId } });
          dispatch(
            loadImage({
              imageName: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${transferObject.artifactId}/raw`,
              imageId: transferObject.workitemId,
              artifactId: transferObject.artifactId,
              artifactId_Raw: transferObject.artifactId_Raw,
              createPreviewImage: addImageMode.none,
              matrixIndex: props.index,
              display_raw: false,
              enabledElementForExport: undefined,
            })
          );
        } catch (err) {
          console.log(err)
        }
      } else {
        //  drag - drop from file system
        if (files && files.length > 0) {
          const file: File = files[0];
          if (file) {
            dispatch(loadImage({
              imageName: file, imageId: currentWorkitemId, artifactId: undefined, artifactId_Raw: undefined,
              createPreviewImage: addImageMode.createPreview, matrixIndex: props.index, display_raw: false, enabledElementForExport: undefined
            }));
          }
        }
      }
    }

    // Setup the dnd listeners.
    const dropZone = viewportElement.current;
    dropZone?.addEventListener("dragover", handleDragOver, false);
    dropZone?.addEventListener("drop", handleFileSelect, false);

    return () => {
      /* const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
      const displayElement: EnabledElement | undefined = cornerstoneElements.find(
        (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.index}`
      );
      let currentViewport: cornerstone.Viewport | undefined = undefined;
      if (displayElement && displayElement.viewport && !isWorkitemPlanning) {
        currentViewport = JSON.parse(JSON.stringify(displayElement?.viewport));
        dispatch({ type: "ImageDisplay/setViewportForMatrixIndex", payload: { matrixIndex: props.index, viewport: currentViewport, currentWorkitemId: currentWorkitemId } });
      } */
      dropZone?.removeEventListener("dragover", handleDragOver, false);
      dropZone?.removeEventListener("drop", handleFileSelect, false);
      //setFile(undefined);
    };
  }, [props.orderId, props.index, currentWorkitemId, dispatch]);

  useEffect(() => {
    if (matrixImageAtStart) {
      dispatch({ type: "ImageDisplay/setSelectedIndex", payload: matrixImageAtStart.matrixIndex });
      dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: matrixImageAtStart.imageId });
    }

    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements.find(
      (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.index}`
    );
    if (displayElement && displayElement.element) {
      cornerstone.resize(displayElement.element);
      cornerstone.updateImage(displayElement.element);
    }

    dispatch({ type: "ImageDisplay/cutMatrixImages", payload: {} });
  }, [currentMatrixRows, currentMatrixColumns, dispatch, matrixImageAtStart, props.index, props.orderId, isStepRejected]);

  useEffect(() => {
    let ret = false;

    const prot = currentWorkitem?.protocol;

    if (currentProtocolCorrelationId === "") {
      console.log("have no currentProtocolCorrelationId");
    }



    if (prot && prot.steps && currentProtocolCorrelationId && currentProtocolCorrelationId !== "") {
      let correlatedSteps = prot.steps.filter((item: any) => (item.correlationId === currentProtocolCorrelationId && item.type === Constants.ACQUISITION_STEP_TYPE));
      if (correlatedSteps && correlatedSteps.length > 0)
        ret = (correlatedSteps[0].rejected !== undefined);
    }

    setIsStepRejected(ret);

  }, [currentWorkitem?.protocol ? JSON.stringify(currentWorkitem?.protocol) : '', currentProtocolCorrelationId])


  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: (size: any) => {
      const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
      const displayElement: EnabledElement | undefined = cornerstoneElements.find(
        (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.index}`
      );
      if (displayElement && displayElement.element) {
        cornerstone.resize(displayElement.element);
        //cornerstone.updateImage(displayElement.element);
      }
    },
  });

  const acceptImage = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements.find(
      (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.index}`
    );
    if (displayElement && displayElement.element) {
      const eventData = {
        toolName: 'Finalize',
        toolType: 'Finalize',
        element: displayElement.element,
        measurementData: undefined,
      };
      // @ts-ignore
      cornerstone.triggerEvent(displayElement.element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
    }
  }

  const rejectImage = async (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();

    let rawStepNumberToReject = -1;
    let rawStepToReject = undefined;
    if (currentWorkitem?.protocol?.steps) {
      const n = currentWorkitem?.protocol?.steps.length;
      for (let i = 0; i < n; i++) {
        const rawstep = currentWorkitem?.protocol?.steps[i];
        if (rawstep && rawstep.type === Constants.ACQUISITION_STEP_TYPE && rawstep.rejected === undefined) {
          rawStepNumberToReject = i;
          rawStepToReject = rawstep;
          break;
        }
      }
    }

    console.log("Rejecting protocol step: " + rawStepNumberToReject);
    if (rawStepNumberToReject > -1) {
      await dispatch(apiSlice.endpoints.rejectWorkitemProtocolStep.initiate(
        {
          workitemId: currentWorkitem?.id,
          stepNumber: rawStepNumberToReject,
          body: [t("rejected by user", {ns: 'InfoPanel'})]
        }
      ));

      let newAcquisitionStep: any | undefined = undefined;
      if (rawStepToReject)
        newAcquisitionStep = { type: rawStepToReject.type, scheduled: rawStepToReject.scheduled, correlationId: uuidv4() };
      dispatch(apiSlice.endpoints.addWorkitemProtocolStep.initiate(
        {
          workitemId: currentWorkitem?.id,
          body: rawStepToReject ? newAcquisitionStep : ""
        }
      ));

      dispatch(
        apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
          const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
          if (currentWorkitemId && workitemList && workitemList.includes(currentWorkitemId)) {
            const currentWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitemId);
            currentWorkitem.data.protocol.steps[rawStepNumberToReject].rejected = [""];
            if (newAcquisitionStep) {
              currentWorkitem.data.protocol.steps.push(newAcquisitionStep);
            }
          }
          return data;
        })
      );

      dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 2 });
      //dispatch({ type: 'Acquisition/setAcquisitionButtonActive', payload: true});
    }
  } // rejectImage

  const activeViewPortClasses = classNames(
    "viewportContainer",
    {
      "viewportContainer_selected_mouseDown": (props.index === selectedMatrixIndex) && rightMouseDown,
      "viewportContainer_selected": (props.index === selectedMatrixIndex) && !rightMouseDown,
      // active if only matrix of size 1x1 is selected
      "singleActiveViewPort": (currentMatrixColumns === 1) && (currentMatrixRows === 1),
      // only for first view port
      "topLeftViewPort": props.index === 0,
    }
  )

  return (
    <div className="matrixEelement" key={props.index.toString()} ref={ref}>
      <div className="selectedMatrixIndex-wrapper">

        <div onMouseDown={(e) => onMouseDown(e)} onMouseUp={(e) => onMouseUp(e)} onClick={(e) => onClick(e)}
          className={activeViewPortClasses}
          ref={viewportElement}
          id={`${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.index.toString()}`}
        >
          <canvas
            className={displayItemType === DisplayItemType.image ? "cornerstone-canvas" : "cornerstone-canvas cornerstone-canvas-hide"}
            id={
              imageLoadStatus === "loading" && selectedMatrixIndex === props.index
                ? `cornerstone-canvas_loading_${props.index}`
                : `cornerstone-canvas_${props.index}`
            }
          />

          <PdfDocument matrixIndex={props.index} displayItemType={displayItemType} />
        </div>
      </div>
      <div className={currentWorkitem?.state !== undefined && currentWorkitem?.state !== "COMPLETED" && currentWorkitem?.state !== "CANCELED" && selectedMatrixIndex === props.index && !isStepRejected ? "Accept-Reject" : "Accept-Reject-invisible"}>
        <button className="Accept-button venaDefaultButton" onClick={(evt: any) => acceptImage(evt)} >
          {t('accept')}
        </button>
        <button className="Reject-button venaDefaultButton" onClick={(evt: any) => rejectImage(evt)} >
          {t('reject')}
        </button>
      </div>
      <TooltabsfloatPanel orderId={props.orderId} matrixIndex={props.index} />
      <LoadingIndicator index={props.index} />
      {isOverviewAndAnnotationsVisible ?
        <ViewportOverlay imageLoadStatus={imageLoadStatus} matrixIndex={props.index} orderId={props.orderId} />
        : null}
      {/* <DosisOverlay imageLoadStatus={imageLoadStatus} matrixIndex={props.index} orderId={props.orderId} /> */}
      {isStepRejected ? <RejectedOverlay imageLoadStatus={imageLoadStatus} matrixIndex={props.index} orderId={props.orderId} /> : null}
      <Annotation orderId={props.orderId} workitemId={currentWorkitemId} targetId={cornerstoneTargetId} matrixIndex={props.index} imageLoadStatus={imageLoadStatus} />
      <CommonDialog
        childProps={{}}
        onClose={handleReloadDialogClose}
        title={t(`title`, { ns: 'ReloadDialog' })}
        visible={isReloadDialogVisible}
        okText={t("OK")}
        cancelText={t("cancel")}>
        <ReloadApplicationDialog />
      </CommonDialog>

    </div>
  );
};

export default ViewportElement;
