import React, { useCallback, useEffect, useRef, useState } from "react";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getImagePathForCurrentTheme, getSelectedWorkitem, selectArtifactForSelectedWorkitem, selectArtifactIdForSelectedWorkitem, selectInstanceForWorkitem, selectSeriesDataForWorkitem, selectedStudy } from "../OrderList/OrdersSlice";
import { useORTranslation } from "../Localization/ORLocalization";
import { getSearchString, getSelectedSubject, selectRowsPerPage, selectSelectedPage, selectTotalElement, selectTotalPages } from "./SubjectSlice";
import "./SubjectPanel.scss";
import { CSSTransition } from "react-transition-group";


import { formatISO, parseISO } from "date-fns";
import { apiSlice, useChangeSubjectDetailsMutation, useChangeSubjectIdentityMutation, useRegisterSubjectMutation, useSearchSubjectsQuery, useUpdateStudyDetailsMutation } from "../../apis/apiSlice";
import { setAcquisitionActive } from "../Akquisition/AcquisitionSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { selectPregnancyAgeLimits, selectSubjectDialogVisible, selectedWorkitem } from "../ImageDisplay/ImageDisplaySlice";
import { debounce, throttle } from "throttle-debounce";
import ReactPaginate from "react-paginate";
import InfoPanel from "../Utils/ConfigurableInfoPanel/InfoPanel";

type SubjectPanelProps = {
    orderId: string;
    refresh?: number;
    resetRefresh?: () => void;
    onActionDone?: () => void;
    setOpenStudyDisabled: (disabled: boolean) => void;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

export const getAge = (date: Date) => {
    var today = new Date();
    var birthDate = date;
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

const SubjectPanel = (props: SubjectPanelProps) => {

    const { t } = useORTranslation(['Subject', 'common']);

    const rootDivElement = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const currentSearchString: string = useAppSelector((state) => getSearchString(state));
    const isSubjectDialogVisible: number = useAppSelector((state) => selectSubjectDialogVisible(state));
    const currentStudyAsString: string | undefined = useAppSelector((state) => selectedStudy(state, props.orderId ?? ''));
    const study = currentStudyAsString ? JSON.parse(currentStudyAsString) : undefined;
    const subject: any = useAppSelector((state) => getSelectedSubject(state));

    const selectedRowsPerPage: number = useAppSelector((state) => selectRowsPerPage(state));
    const selectedPage: number = useAppSelector((state) => selectSelectedPage(state));
    const totalPages: number = useAppSelector((state) => selectTotalPages(state));
    const totalElements: number = useAppSelector((state) => selectTotalElement(state));

    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
    const currentSeriesAsString: string | undefined = useAppSelector((state) => selectSeriesDataForWorkitem(state, currentWorkitemId));
    const currentSeries = currentSeriesAsString ? JSON.parse(currentSeriesAsString) : undefined;
    const currentInstanceAsString: string | undefined = useAppSelector((state) => selectInstanceForWorkitem(state, currentWorkitemId));
    const currentInstance = currentInstanceAsString ? JSON.parse(currentInstanceAsString) : undefined;
    const currentArtifactAsString: string | undefined = useAppSelector((state) => selectArtifactForSelectedWorkitem(state));
    const currentArtifact = currentArtifactAsString ? JSON.parse(currentArtifactAsString) : undefined;
    const currentArtifactId: string | undefined = useAppSelector((state) => selectArtifactIdForSelectedWorkitem(state));
    const pregnancyAgeLimits: number[] = useAppSelector((state) => selectPregnancyAgeLimits(state));

    const [selectedSearchRow, setSelectedSearchRow] = useState<number>(-1);
    const [studyDetails, setStudyDetails] = useState<any>(undefined);
    const [open, setOpen] = useState(false);
    const [dummyStudy] = useState({ id: '', state: '', workItems: [], details: { desc: '', opened: formatISO(new Date()), accessionNumber: '', comment: '', title: '' } });
    const [dummySubject, setDummySubject] = useState({ subjectName: { familyName: '', givenName: '', middleName: '', prefix: '', suffix: '' }, subjectId: { id: '', issuer: '' }, details: { comment: '', type: '', birthDate: '', sex: '', age: '', pregnancyStatus: '' } });

    const [registerSubject] = useRegisterSubjectMutation();
    const [changeSubjectIdentity] = useChangeSubjectIdentityMutation();
    const [changeSubjectDetails] = useChangeSubjectDetailsMutation();
    const [UpdateStudyDetails] = useUpdateStudyDetailsMutation();


    const currentSearchStringRef = useRef<string>();
    currentSearchStringRef.current = currentSearchString;

    const studyDetailsRef = useRef<any>();
    studyDetailsRef.current = studyDetails;

    const subjectRef = useRef<any>();
    subjectRef.current = subject;

    const navigate = useNavigate();
    const location = useLocation();

    const {
        data,
        isSuccess,
    }: { data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } =
        useSearchSubjectsQuery(undefined, {
            refetchOnMountOrArgChange: false,
            skip: currentSearchString === undefined || currentSearchString === '',
        })

    const throttleOnSearchString = throttle(200, async (newSearchString: string) => {
        dispatch({ type: "Subject/setSelectedPage", payload: 0 });
        dispatch({ type: "Subject/setSearchString", payload: newSearchString });

        if (newSearchString.length > 2) {
            setOpen(true);
            dispatch(apiSlice.util.invalidateTags([{ type: 'Subject' }]));
        } else {
            setOpen(false);
        }
    });

    const handleSearch = async (evt: any) => {
        evt.stopPropagation();
        evt.preventDefault();
        const newSearchString = evt.target.value;
        if (newSearchString !== undefined) {
            throttleOnSearchString(newSearchString);
        }
    }

    const handleSearchButton = async (evt: any) => {
        evt.stopPropagation();
        evt.preventDefault();
        if (currentSearchStringRef.current !== undefined && currentSearchStringRef.current !== '') {
            //const searchResult = await dispatch(apiSlice.endpoints.searchSubjects.initiate(currentSearchStringRef.current)).unwrap();

            //dispatch({ type: "Subject/setTotalPages", payload: searchResult?.totalPages });
            //dispatch({ type: "Subject/setTotalElements", payload: searchResult?.totalElements });
            dispatch({ type: "Subject/setSelectedPage", payload: 0 });
            dispatch(apiSlice.util.invalidateTags([{ type: 'Subject' }]));

            setOpen(true);
        }
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            //dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
        }
    }

    const registerPatient = (event: any) => {
        dispatch({ type: "ImageDisplay/setSelectedSubject", payload: {} });
        dispatch({ type: "ImageDisplay/setSubjectDialogVisible", payload: 2 });
    }

    const checkSubject = (subject: any) => {
        if (subject?.subjectName?.givenName && subject?.subjectName?.familyName && subject?.details?.birthDate && subject?.details?.pregnancyStatus && (study?.state !== "COMPLETED" || isSubjectDialogVisible < 4)) {
            props.setOpenStudyDisabled(false);
        } else {
            props.setOpenStudyDisabled(true);
        }
    }

    const setUpdateObject = useCallback((arg: any) => {
        const studyDetailsTmp = structuredClone(studyDetailsRef?.current);
        const details = structuredClone(arg?.details);
        setStudyDetails({ ...studyDetailsTmp, ...details })
    }, [])

    /* const setUpdateObjectPatientEditName = useCallback((arg: any) => {
        console.log(arg);

        if (arg?.subjectName && Object.values(arg?.subjectName) && Object.values(arg?.subjectName.length > 0)) {
            if (Object.values(arg?.subjectName)[0] === '') {
                arg.subjectName[Object.keys(arg?.subjectName)[0]] = undefined;
            }
        }

        const newSubject: any = structuredClone(subjectRef.current);
        console.log(newSubject);
        newSubject.subjectName = { ...newSubject?.subjectName, ...arg?.subjectName };
        dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
    }, [])

    const setUpdateObjectPatientEditData = useCallback((arg: any) => {
        console.log(arg);
        let newSubject: any = structuredClone(subjectRef.current);
        console.log(newSubject);
        //newSubject.subjectId = {...newSubject?.subjectId, ...arg?.subjectId};
        const { subjectId, details } = arg;
        if (subjectId) {
            newSubject.subjectId = { ...newSubject?.subjectId, ...subjectId };
        }
        if (details) {
            newSubject.details = { ...newSubject?.details, ...details };
        }
        dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
    }, []) */

    const setUpdateObjectPatientEdit = useCallback((arg: any) => {
        let newSubject: any = structuredClone(subjectRef.current);
        if (arg?.subjectName) {
            if (arg?.subjectName && Object.values(arg?.subjectName) && Object.values(arg?.subjectName.length > 0)) {
                if (Object.values(arg?.subjectName)[0] === '') {
                    arg.subjectName[Object.keys(arg?.subjectName)[0]] = undefined;
                }
            }
            newSubject.subjectName = { ...newSubject?.subjectName, ...arg?.subjectName };
        }

        if (arg?.details || arg?.subjectId) {
            const { subjectId, details } = arg;
            if (subjectId) {
                newSubject.subjectId = { ...newSubject?.subjectId, ...subjectId };
                if(newSubject?.subjectId?.issuer === undefined){
                    newSubject.subjectId.issuer = "client defined";
                }
            }
            if (details) {
                newSubject.details = { ...newSubject?.details, ...details };
            }
        }

        dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
    }, [])

    const openStudy = async () => {
        const submitSubject = structuredClone(subject);
        submitSubject.details = { ...submitSubject.details, type: "patient" };
        submitSubject.subjectNames = [submitSubject.subjectName];
        if (submitSubject?.details?.pregnancyStatus) {
            submitSubject.details.pregnancyStatus = undefined;
        }
        if (submitSubject?.details?.age) {
            submitSubject.details.age = undefined;
        }

        let registerSubjectResult = structuredClone(submitSubject);
        if (isSubjectDialogVisible === 2) {
            if(submitSubject?.subjectIds === undefined && submitSubject?.subjectId !== undefined) {
                submitSubject.subjectIds = [submitSubject?.subjectId];
            }
            registerSubjectResult = structuredClone((await registerSubject({ body: submitSubject }))?.data);
        } else if (isSubjectDialogVisible >= 3 && selectedSearchRow > -1) {
            const orgSubject = structuredClone(data?.content[selectedSearchRow]);
            const hasIdchanged: boolean = isIdChanged(subject, orgSubject);
            const hasNamechanged: boolean = isNameChanged(subject, orgSubject);

            if (hasNamechanged || hasIdchanged) {
                let body: any = {};
                if (hasNamechanged) {
                    const newSubjectNames = orgSubject?.subjectNames;
                    if (newSubjectNames) {
                        newSubjectNames.unshift(submitSubject?.subjectName);
                        body = { ...body, subjectNames: newSubjectNames }
                    }
                }
                if (hasIdchanged) {
                    const newSubjectIds = orgSubject?.subjectIds;
                    if (newSubjectIds) {
                        newSubjectIds.unshift(submitSubject?.subjectId);
                        body = { ...body, subjectIds: newSubjectIds }
                    }
                }

                registerSubjectResult = structuredClone((await changeSubjectIdentity({ registrationId: orgSubject?.registrationId, body: body }))?.data);
            }

            if (isDetailsChanged(subject?.details, orgSubject?.details)) {
                const newSubjectDetails = structuredClone(subject?.details);
                const body: any = newSubjectDetails;
                registerSubjectResult = structuredClone((await changeSubjectDetails({ registrationId: orgSubject?.registrationId, body: body }))?.data);
            }
        }

        if (registerSubjectResult?.details && subject?.details?.pregnancyStatus) {
            registerSubjectResult.details.pregnancyStatus = subject?.details?.pregnancyStatus;
        }
        if (registerSubjectResult?.details?.birthDate && !isNaN(Date.parse(registerSubjectResult?.details?.birthDate))
            && (parseISO(registerSubjectResult?.details?.birthDate)).toLocaleDateString() !== 'Invalid Date') {
            registerSubjectResult.details.age = getAge(parseISO(registerSubjectResult?.details?.birthDate));
        }

        const studyDetailsTmp = structuredClone(studyDetails);
        setStudyDetails({ ...studyDetailsTmp, subject: registerSubjectResult })

        const newStudyId: any = await dispatch(setAcquisitionActive({ setAcquistionButtonActive: true, studyId: undefined, workitemId: "-1", studyInfo: { ...studyDetailsTmp, subject: registerSubjectResult } }));
        dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 1 });

        if (newStudyId?.payload?.studyId) {
            navigate(`/orders/${newStudyId?.payload?.studyId}/${location?.search ?? ''}`);
        }
        dispatch({ type: "ImageDisplay/setSubjectDialogVisible", payload: 0 });
    }


    const setSubjectFromSearchResult = (rowNr: number) => {
        if (data?.content && Array.isArray(data?.content) && data?.content?.length > rowNr) {
            const newSubject = structuredClone(data?.content[rowNr]);

            if (newSubject?.details?.sex === 'male') {
                newSubject.details.pregnancyStatus = 'not_pregnant';
                props.setOpenStudyDisabled(false);
            } else {
                if (newSubject?.details?.pregnancyStatus && (study?.state !== "COMPLETED" || isSubjectDialogVisible < 4)) {
                    props.setOpenStudyDisabled(false);
                } else {
                    props.setOpenStudyDisabled(true);
                }
            }

            dispatch({ type: "ImageDisplay/setSubjectDialogVisible", payload: 1 });
            dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
            setSelectedSearchRow(rowNr);
            setOpen(false);
        }
    }

    const editPatientData = (subject: any) => {
        dispatch({ type: "ImageDisplay/setSubjectDialogVisible", payload: 3 });
    }

    const isNameChanged = (subject1: any, subject2: any): boolean => {
        let ret: boolean = false;

        if ((subject1?.subjectName?.prefix ?? '').trim() !== (subject2?.subjectName?.prefix ?? '').trim()) {
            return true;
        }
        if ((subject1?.subjectName?.givenName ?? '').trim() !== (subject2?.subjectName?.givenName ?? '').trim()) {
            return true;
        }
        if ((subject1?.subjectName?.middleName ?? '').trim() !== (subject2?.subjectName?.middleName ?? '').trim()) {
            return true;
        }
        if ((subject1?.subjectName?.familyName ?? '').trim() !== (subject2?.subjectName?.familyName ?? '').trim()) {
            return true;
        }

        if ((subject1?.subjectName?.suffix ?? '').trim() !== (subject2?.subjectName?.suffix ?? '').trim()) {
            return true;
        }

        return ret;
    }

    const isIdChanged = (subject1: any, subject2: any): boolean => {
        let ret: boolean = false;

        if ((subject1?.subjectId?.id ?? '').trim() !== (subject2?.subjectId?.id ?? '').trim()) {
            return true;
        }
        if ((subject1?.subjectId?.issuer ?? '').trim() !== (subject2?.subjectId?.issuer ?? '').trim()) {
            return true;
        }

        return ret;
    }

    const isDetailsChanged = (obj: any, orgObj: any): boolean => {
        let ret: boolean = false;
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop) && prop !== 'pregnancyStatus') {
                if (typeof prop !== 'object') {
                    if (Object.prototype.hasOwnProperty.call(orgObj, prop)) {
                        if (orgObj[prop] !== obj[prop]) {
                            return true;
                        }
                    }
                } else {
                    return isDetailsChanged(obj[prop], orgObj[prop]);
                }
            }
        }
        return ret;
    }

    useEffect(() => {
        if (props.refresh && props.refresh > 0) {

            if (isSubjectDialogVisible < 4) {
                openStudy();
            } else {
                const newSubject = structuredClone(subject);

                if (study?.details?.subject) {
                    const hasIdchanged: boolean = study?.details?.subject?.subjectIds === undefined || isIdChanged(subject, study?.details?.subject);
                    const hasNamechanged: boolean = study?.details?.subject?.subjectNames === undefined || isNameChanged(subject, study?.details?.subject);
                    if (hasNamechanged || hasIdchanged) {
                        let body: any = {};
                        if (hasNamechanged) {
                            const newSubjectNames = study?.details?.subject?.subjectNames;
                            if (newSubjectNames) {
                                newSubjectNames.unshift(newSubject?.subjectName);
                                body = { ...body, subjectNames: newSubjectNames }
                            } else {
                                body = { ...body, subjectNames: [newSubject?.subjectName] }
                            }
                        }
                        if (hasIdchanged) {
                            const newSubjectIds = study?.details?.subject?.subjectIds;
                            if (newSubjectIds) {
                                newSubjectIds.unshift(newSubject?.subjectId);
                                body = { ...body, subjectIds: newSubjectIds }
                            } else {
                                body = { ...body, subjectIds: [newSubject?.subjectId] }
                            }
                        }

                        changeSubjectIdentity({ registrationId: study?.details?.subject?.registrationId, body: body });
                    }

                    if (study?.details?.subject?.details === undefined || isDetailsChanged(subject?.details, study?.details?.subject?.details)) {
                        const newSubjectDetails = structuredClone(subject?.details);
                        const body: any = newSubjectDetails;
                        changeSubjectDetails({ registrationId: study?.details?.subject?.registrationId, body: body });
                    }
                }

                const newStudy = structuredClone(study);
                if (newStudy?.details && newStudy?.state !== "COMPLETED") {
                    newStudy.details = { ...newStudy.details, ...studyDetails };
                    newStudy.details.subject = newSubject;
                    UpdateStudyDetails({ orderId: props.orderId, body: newStudy });
                }
            }

        }
        return () => {
            if (props.resetRefresh) {
                props.resetRefresh();
            }
            if (props.onActionDone) {
                props.onActionDone();
            }
        };
    }, [props.refresh]);


    useEffect(() => {
        dispatch({ type: "Subject/setSearchString", payload: '' });

        const onBodyClick = (event: Event) => {
            if (rootDivElement.current?.contains(event.target as Node)) {
                return;
            }
            setOpen(false);
        };
        document.body.addEventListener("click", onBodyClick, { capture: true });
        if (isSubjectDialogVisible < 4) {
            dispatch({ type: "Subject/setSelectedSubject", payload: {} });
        }

        dispatch({ type: "Subject/setSelectedPage", payload: 0 });

        return () => {
            document.body.removeEventListener("click", onBodyClick, { capture: true });
        };

    }, []);


    useEffect(() => {

        if (isSubjectDialogVisible === 2) {
            dispatch({ type: "Subject/setSelectedSubject", payload: {} });
        }

        if (subject?.details?.pregnancyStatus && (study?.state !== "COMPLETED" || isSubjectDialogVisible < 4)) {
            props.setOpenStudyDisabled(false);
        } else {
            props.setOpenStudyDisabled(true);
        }

    }, [isSubjectDialogVisible]);


    useEffect(() => {

        if (isSuccess && data) {
            dispatch({ type: "Subject/setTotalPages", payload: data?.totalPages });
            dispatch({ type: "Subject/setTotalElements", payload: data?.totalElements });
        }
    }, [dispatch, data, isSuccess]);


    useEffect(() => {
        //if (subject?.details?.pregnancyStatus !== undefined) {
        checkSubject(subject)
        //}
        if (dummySubject?.details) {
            const newDummySubject = structuredClone(dummySubject);
            newDummySubject.details.birthDate = subject?.details?.birthDate;
            setDummySubject(newDummySubject);
        }

        if (subject?.details?.birthDate && parseISO(subject?.details?.birthDate).toLocaleDateString() !== 'Invalid Date' &&
            pregnancyAgeLimits && Array.isArray(pregnancyAgeLimits) && pregnancyAgeLimits.length >= 2) {
            const age: number = getAge(parseISO(subject?.details?.birthDate));
            if (age < pregnancyAgeLimits[0] || age > pregnancyAgeLimits[1]) {
                const newSubject: any = structuredClone(subject);
                newSubject.details.pregnancyStatus = 'not_pregnant';
                dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
            }
        }
    }, [subject?.details?.pregnancyStatus, subject?.details?.birthDate, subject?.details?.sex, JSON.stringify(subject?.subjectName)]);

    const changeHandlerRowsPerPageSelect = (value: number | null) => {
        dispatch({ type: "Subject/setRowsPerPage", payload: value ?? 0 });
        dispatch(apiSlice.util.invalidateTags([{ type: 'Subject' }]));
    };

    const changeHandlerPageNrSelect = (value: number | null) => {
        dispatch({ type: "Subject/setSelectedPage", payload: value ? value : 0 });
        dispatch(apiSlice.util.invalidateTags([{ type: 'Subject' }]));
    };

    const handleClick = (event: any) => {

    };

    const handlePageClick = (event: any) => {
        throttleOnPageNrSelect(Math.min(event.selected ?? 0, totalPages))
    };

    const handleChange = (event: any) => {
        throttleOnRowsPerPageSelect(event.target.value);
    };

    const isEditEnabled = () => {
        return study?.state !== "COMPLETED";
    };

    const throttleOnRowsPerPageSelect = debounce(500, changeHandlerRowsPerPageSelect);
    const throttleOnPageNrSelect = debounce(500, changeHandlerPageNrSelect);

    return (
        <div className="SubjectPanelWrapper" onContextMenu={(e) => e.preventDefault()}>
            {isSubjectDialogVisible < 4 ?
                <div className={"searchPanel"}>
                    <div className={"SubjectPanelRow"}>
                        <div className={"search-div"}>
                            <div className={"search-fields"}>
                                <div className="divWrapper">
                                    <input type="search" className="searchInput" name="Search" onChange={handleSearch} value={currentSearchString}
                                        onKeyDown={handleKeyDown} placeholder={t("SearchPatient") as string} />
                                </div>
                                <button className="searchButton" onClick={handleSearchButton}>
                                    <img
                                        src={`${imagePathForCurrentTheme}search.svg`}
                                        onError={(event: any) => { event.target.src = "/images//search.svg"; event.onerror = null }}
                                        alt="initiate search" />
                                </button>
                            </div>
                        </div>
                        <div className={"registerPatientButtonWarpper"}>
                            <button className={"registerPatientButton venaDefaultButton"} onClick={(evt) => registerPatient(evt)}>
                                <label>{t('Register patient')}</label>
                                <img
                                    src={`${imagePathForCurrentTheme}RegisterPatient.svg`}
                                    onError={(event: any) => { event.target.src = "/images/RegisterPatient.svg"; event.onerror = null }}
                                    alt="RegisterPatient" />
                            </button>
                        </div>
                    </div>

                    <div className={open ? "searchResultWrapper-visible" : "searchResultWrapper-invisible"} ref={rootDivElement}>
                        <ul className="searchResult">
                            {data?.content?.map((row: any, i: number) =>
                                <li className="searchResultRow" key={i.toString()} onClick={() => setSubjectFromSearchResult(i)}>
                                    <label className="id" title={`${(row?.subjectId?.id ?? '')}`}>{`Id: ${(row?.subjectId?.id ?? '')}`}</label>

                                    <div className="sexImgWrapper">
                                        <img className="sexImg"
                                            src={`${imagePathForCurrentTheme}${row?.details?.sex === 'female' ? 'gender-female-svgrepo-com.svg' : (row?.details?.sex === 'male' ? 'gender-male-svgrepo-com.svg' : 'gender-neuter-fill-svgrepo-com.svg')}`}
                                            onError={(event: any) => { event.target.src = `${row?.details?.sex === 'female' ? '/images/gender-female-svgrepo-com.svg' : (row?.details?.sex === 'male' ? '/images/gender-male-svgrepo-com.svg' : '/images/gender-neuter-fill-svgrepo-com.svg')}`; event.onerror = null }}
                                            alt="gender-female-svgrepo-com.svg" />
                                    </div>

                                    <label className="name" title={`${row?.subjectName?.prefix ?? ''} ${row?.subjectName?.givenName ?? ''} ${row?.subjectName?.middleName ?? ''} ${row?.subjectName?.familyName ?? ''} ${row?.subjectName?.suffix ?? ''} `}>
                                        {`${row?.subjectName?.prefix ?? ''} ${row?.subjectName?.givenName ?? ''} ${row?.subjectName?.middleName ?? ''} ${row?.subjectName?.familyName ?? ''} ${row?.subjectName?.suffix ?? ''} `}
                                    </label>

                                    {row?.details?.birthDate && !isNaN(Date.parse(row?.details?.birthDate))
                                        && (parseISO(row?.details?.birthDate)).toLocaleDateString() !== 'Invalid Date' ?
                                        <label>{`* ${t('date', { date: parseISO(row?.details?.birthDate), ns: 'InfoPanel' })}`}</label>
                                        //<label>{`${(parseISO(row?.details?.birthDate)).toLocaleDateString()} ${Date.parse(row?.details?.birthDate)} ${Math.floor(Date.now() / 1000)}`}</label>
                                        : null}
                                    {/* <label>{`Sex: ${row?.details?.sex ?? ''}`}</label> */}

                                </li>
                            )}
                        </ul>
                        <hr className="hrule1" />
                        <div className="pageSelection">
                            <span className="reactPaginate">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">>"
                                    forcePage={totalElements > 0 ? selectedPage : -1}
                                    onPageChange={handlePageClick}
                                    onClick={handleClick}
                                    pageRangeDisplayed={6}
                                    pageCount={totalPages}
                                    marginPagesDisplayed={1}
                                    previousLabel="<<"
                                    renderOnZeroPageCount={undefined}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                />
                            </span>
                            <span className="total">
                                <span>{totalElements}</span>
                                <label>{t('results', { count: totalElements })}</label>
                            </span>
                            <span className="rowsPerPage">
                                <select onChange={handleChange} value={selectedRowsPerPage}>
                                    {Array.from(Array(5).keys()).map((i: number) =>
                                        <option value={(i * 5 + 5).toString()} key={i.toString()}>{(i * 5 + 5).toString()}</option>
                                    )}
                                </select>
                            </span>
                        </div>
                    </div>


                </div> : null}


            {/* <TransitionGroup className="todo-list" component={null}> */}
            {subject?.subjectName !== undefined ?
                <CSSTransition
                    in={isSubjectDialogVisible === 1}
                    timeout={{
                        enter: 0,
                        exit: 400
                    }}
                    unmountOnExit
                    classNames="patientShort">
                    <div className="patientShort">
                        <fieldset className="fieldset">
                            <legend className="fieldset-Legend">Patient</legend>
                            <h1 className={"fieldset-title"}>{t('Patient')}</h1>
                            <div className={subject?.subjectName ? "subject" : "subject_hidden"}>
                                <InfoPanel className="infoPanelWrapper_Subject" configFile={"/PatientConfig_NewStudy.json"} configFileSection={"Patient"}
                                    workitemId={currentWorkitemId} artifactId={currentArtifactId} orderId={props.orderId ?? ''}
                                    sources={{ currentStudy: study, currentWorkitem, currentSeries, currentInstance, currentArtifact, subject: subject }}
                                    editMode={false} editEnabled={false} setUpdateObject={setUpdateObjectPatientEdit}/>
                            </div>
                            {subject?.subjectName ?
                                <>
                                    <div className={"editPatientWrapper"}>
                                        <button className={"editPatient venaDefaultButton"} onClick={(evt) => editPatientData(subject)}>{t('Edit Patient Data')}</button>
                                    </div>
                                    <div className={"editPatientWrapper2"}>
                                        <button className={"editPatient2 venaDefaultButton"} onClick={(evt) => editPatientData(subject)}>
                                            <img
                                                src={`${imagePathForCurrentTheme}edit2.svg`}
                                                onError={(event: any) => { event.target.src = "/images/edit2.svg"; event.onerror = null }}
                                                alt="edit2" />
                                        </button>
                                    </div>
                                </>
                                : null}
                        </fieldset>
                    </div>
                </CSSTransition>
                :
                <>
                    {isSubjectDialogVisible < 2 ?
                        <div className="InfoPanelWrapper">
                            <div className="InfoPanel">
                                <div className="InfoPanelSearch">
                                    <div className="InfoPanelContent">
                                        <div className="InfoPanelIcon">
                                            <img
                                                src={`${imagePathForCurrentTheme}search.svg`}
                                                onError={(event: any) => { event.target.src = "/images/search.svg"; event.onerror = null }}
                                                alt="search.svg" />
                                        </div>
                                        <label>{t("Search for a patient")}</label>
                                    </div>
                                </div>
                                <div className="InfoPanelRegister">

                                    <div className="InfoPanelContent">
                                        <div className="InfoPanelIcon">
                                            <img
                                                src={`${imagePathForCurrentTheme}RegisterPatient.svg`}
                                                onError={(event: any) => { event.target.src = "/images/RegisterPatient.svg"; event.onerror = null }}
                                                alt="RegisterPatient" />
                                        </div>
                                        <label>{t("Register a new patient")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null}
                </>
            }

            <CSSTransition
                in={isSubjectDialogVisible > 1}
                timeout={{
                    enter: 700,
                    exit: 0
                }}
                unmountOnExit
                classNames="patientRegister">

                <div className="patientRegister">
                    <fieldset className={"fieldset"}>
                        <legend className="fieldset-Legend">Patient name</legend>
                        <h1 className={"fieldset-title"}>{t('Patient')}</h1>
                        <div className="patientName">
                            <InfoPanel className="infoPanelWrapper_Subject" configFile={"/PatientConfig_RegisterPatient.json"} configFileSection={"Name"}
                                workitemId={currentWorkitemId} artifactId={currentArtifactId} orderId={props.orderId ?? ''}
                                sources={{
                                    currentStudy: dummyStudy,
                                    currentWorkitem, currentSeries, currentInstance, currentArtifact, subject: isSubjectDialogVisible < 3 ? dummySubject : subject
                                }}
                                editMode={false} editEnabled={isEditEnabled()} setUpdateObject={setUpdateObjectPatientEdit} />
                        </div>
                    </fieldset>

                    <fieldset className={"fieldset fieldset-patientRegister-data"}>
                        <legend className="fieldset-Legend">Patient data</legend>
                        <div className="patientData">
                            <InfoPanel className="infoPanelWrapper_Subject" configFile={"/PatientConfig_RegisterPatient.json"} configFileSection={"Data"}
                                workitemId={currentWorkitemId} artifactId={currentArtifactId} orderId={props.orderId ?? ''}
                                sources={{
                                    currentStudy: dummyStudy,
                                    currentWorkitem, currentSeries, currentInstance, currentArtifact, subject: isSubjectDialogVisible < 3 ? dummySubject : subject
                                }}
                                editMode={false} editEnabled={isEditEnabled()} setUpdateObject={setUpdateObjectPatientEdit} />
                        </div>
                    </fieldset>
                    {isSubjectDialogVisible > 3 ?
                        <fieldset className={"fieldset"}>
                            <legend className="fieldset-Legend">{t('Study')}</legend>
                            <h1 className={"fieldset-title"}>{t('Study')}</h1>
                            <div className="study">
                                <InfoPanel className="infoPanelWrapper_Subject" configFile={"/PatientConfig_NewStudy.json"} configFileSection={"Study"}
                                    workitemId={currentWorkitemId} artifactId={currentArtifactId} orderId={props.orderId ?? ''}
                                    sources={{
                                        currentStudy: study,
                                        currentWorkitem, currentSeries, currentInstance, currentArtifact, subject: subject
                                    }}
                                    editMode={false} editEnabled={isEditEnabled()} setUpdateObject={setUpdateObject} />
                            </div>
                        </fieldset>
                        : null}
                </div>
            </CSSTransition>
            {/* </TransitionGroup> */}

            {isSubjectDialogVisible < 4 && (subject?.subjectName !== undefined || isSubjectDialogVisible > 1) ?
                <fieldset className={"fieldset"}>
                    <legend className="fieldset-Legend">{t('Study')}</legend>
                    <h1 className={"fieldset-title"}>{t('Study')}</h1>
                    <div className="study">
                        <InfoPanel className="infoPanelWrapper_Subject" configFile={"/PatientConfig_NewStudy.json"} configFileSection={"Study"}
                            workitemId={currentWorkitemId} artifactId={currentArtifactId} orderId={props.orderId ?? ''}
                            sources={{
                                currentStudy: dummyStudy,
                                currentWorkitem, currentSeries, currentInstance, currentArtifact, subject: subject
                            }}
                            editMode={false} editEnabled={true} setUpdateObject={setUpdateObject} />
                    </div>
                </fieldset> : null}
        </div>
    );
};

export default SubjectPanel;
