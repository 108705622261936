import React, { useEffect, useState, useRef } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";

import { AppDispatch, RootState } from "../../store";

import "./FloatingToolsPanel.scss";
import { getImagePathForCurrentTheme } from "../OrderList/OrdersSlice";
import Tools from "./Tools/Tools";
import { selectProcedureSelectionVisibilityState } from "../Procedures/ProcedureSlice";
import { getIsImageMaximized } from "./ImageDisplaySlice";

type FloatingToolsPanelProps = {
    orderId: string;
    width: number;
    height: number;
    dynamicWidth: number;
    dynamicHeight: number;
};

//let resizing: boolean = false;

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const panelWidth = 118;
const topGap = 5;

const FloatingToolsPanel = (props: React.PropsWithChildren<FloatingToolsPanelProps>) => {
    const dispatch = useAppDispatch();


    const [panelSize, setPanelSize] = useState<{ width: number, height: number }>({ width: panelWidth, height: 500 });
    const [panelPosition, setPanelPosition] = useState<{ x: number, y: number }>({ x: -1, y: topGap });
    const [closed, setClosed] = useState<boolean>(false);
    const [overviewHeight, setOverviewHeight] = useState<number>(128);
    const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const isImageMaximized: boolean = useAppSelector((state) => getIsImageMaximized(state));

    const RndRef = useRef<any>();

    const panelSizeRef = useRef<{ width: number, height: number }>();
    panelSizeRef.current = panelSize;

    const leftgap: number = 5;

    const handelClose = (evt: any) => {
        if (!closed) {
            if (panelSizeRef.current) {
                setPanelSize(panelSizeRef.current);
            };
        }
        RndRef?.current?.resizable?.updateSize({
            width: panelSizeRef.current?.width,
            height: closed ? panelSizeRef.current?.height : 27,
        });
        setClosed(!closed);
    };

    const handelPin = (evt: any) => {
        if (RndRef.current && RndRef.current.getSelfElement()) {
            const elem = document.getElementById('toolsPanel');
            if (elem) {
                const currentOverviewHeight = parseInt(getComputedStyle(elem)?.getPropertyValue('--overview_height').replace("px", ""));
                if (currentOverviewHeight) {
                    setOverviewHeight(currentOverviewHeight);
                    const panelHeight = RndRef.current.getSelfElement()?.parentElement?.clientHeight - currentOverviewHeight - 15;
                    setPanelSize({ width: panelWidth, height: panelHeight });
                    RndRef?.current?.resizable?.updateSize({ width: panelWidth, height: closed ? 27 : panelHeight });
                }

            }
            setPanelPosition({ x: RndRef.current.getSelfElement()?.parentElement?.clientWidth - panelWidth - leftgap, y: topGap });

            RndRef?.current?.updatePosition({
                x: RndRef.current.getSelfElement()?.parentElement?.clientWidth - panelWidth - leftgap,
                y: topGap
            });
        }
    };

    React.useEffect(() => {
        if (RndRef.current && RndRef.current.getSelfElement()) {
            const elem = document.getElementById('toolsPanel');
            if (elem) {
                const currentOverviewHeight = parseInt(getComputedStyle(elem)?.getPropertyValue('--overview_height').replace("px", ""));
                if (currentOverviewHeight) {
                    setOverviewHeight(currentOverviewHeight);
                    const panelHeight = RndRef.current.getSelfElement()?.parentElement?.clientHeight - currentOverviewHeight - 15;
                    setPanelSize({ width: panelSize.width, height: panelHeight });
                    RndRef?.current?.resizable?.updateSize({ width: panelSize.width, height: panelHeight });
                }

            }
            setPanelPosition({ x: RndRef.current.getSelfElement()?.parentElement?.clientWidth - panelSize.width - leftgap, y: panelPosition.y });

            RndRef?.current?.updatePosition({
                x: RndRef.current.getSelfElement()?.parentElement?.clientWidth - panelSize.width - leftgap,
                y: panelPosition.y
            });
        }
    }, [RndRef.current, isImageMaximized, props.orderId]);


    useEffect(() => {
        if ( RndRef.current.getSelfElement()?.parentElement?.clientWidth - (panelPosition.x + panelSize.width + leftgap) < 0) {
            setPanelPosition({ x:  RndRef.current.getSelfElement()?.parentElement?.clientWidth - (panelSize.width + leftgap), y: panelPosition.y });
            RndRef?.current?.updatePosition({ x:  RndRef.current.getSelfElement()?.parentElement?.clientWidth - (panelSize.width + leftgap), y: panelPosition.y });
        }

        if (props.dynamicHeight - (panelSize.height + overviewHeight + 15) < 0) {
            const panelHeight = RndRef.current.getSelfElement()?.parentElement?.clientHeight - overviewHeight - 15;
            setPanelSize({ width: panelSize.width, height: panelHeight });
            RndRef?.current?.resizable?.updateSize({ width: panelSize.width, height: panelHeight });
        }

    }, [props.dynamicWidth, props.dynamicHeight]);


    return (
        <Rnd ref={RndRef}
            bounds="parent"
            className={procedureSelectionVisibilityState === 0 ? "FloatingToolsPanel" : "FloatingToolsPanel_invisible"}
            default={{
                x: window.innerWidth,
                y: 10,
                width: panelSize.width,
                height: panelSize.height,
            }}
            disableDragging={false}
            enableResizing={true}
            dragHandleClassName="close"
            onDrag={(evt) => {
                evt.stopPropagation();
            }}
            onDragStart={(evt) => {
                evt.stopPropagation();
            }}
            onDragStop={(evt: any, d: any) => {
                evt.stopPropagation();
                setPanelPosition({ x: d.x, y: d.y });
            }}
            onResize={(e, direction, ref, delta, position) => {
                setPanelSize({ width: ref.offsetWidth, height: ref.offsetHeight });
            }}

        >
            <div className="panel-header">

                <div className="close">
                    <button className="pin-button">
                        <img className={closed ? "pin-button-img_closed" : "pin-button-img"} onClick={(evt) => handelClose(evt)}
                            src={`${imagePathForCurrentTheme}pin.svg`}
                            onError={(event: any) => { event.target.src = "/images/pin.svg"; event.onerror = null }}
                            alt="pin" />
                            <span className="vr"/>
                    </button>
                    <div className="panel-header-drag-area">
                        <span className="vr"/>
                    </div>
                    <button className="close-image-button">
                        <img className="close-image"
                            onClick={(evt) => handelPin(evt)}
                            src={`${imagePathForCurrentTheme}unfold.svg`}
                            onError={(event: any) => { event.target.src = "/images/unfold.svg"; event.onerror = null }}
                            alt="close" />
                    </button>

                </div>
            </div>
            <div className="tools-container">
                <Tools orderId={props.orderId ?? ''} />
            </div>
        </Rnd>
    );
}

export default FloatingToolsPanel;
