import { useSelector } from "react-redux";
import { selectVersionString } from "../OrderList/MainOrderListSlice";
import { ReleaseDateString } from "./ReleaseDateString";

interface VersionStringProps {
    version?: string
}

export function VersionString (props: VersionStringProps) {

    const versionString = useSelector(selectVersionString);

    return (
        <span className="versionInfo">
            {props.version || versionString || ""}
        </span>
    )
}
