import { AppDispatch, RootState } from '../../store';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, subDays, subMonths } from 'date-fns';
import { apiSlice } from '../../apis/apiSlice';
import { useEffect, useState } from 'react';
import { DateRangeT, selectDateMenuEntry, selectSearchDateRange, selectSearchString, selectStudyStateFilter } from './MainOrderListSlice';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './Search.scss'
import CommonDialog from '../Utils/CommonDialog';
import DateRangeSelector from './DateRangeSelector';
import { useORTranslation } from '../Localization/ORLocalization';
import { getImagePathForCurrentTheme } from './OrdersSlice';
import Select, { components } from "react-select";
import { selectIntlConfigOptions } from '../ImageDisplay/ImageDisplaySlice';

interface SearchProps {
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;



const { Option } = components;
const IconOption = (props: any) => (
	<Option {...props}>
		<img
			src={require('../../../public/images/' + props.data.icon)}
			style={{ width: 36 }}
			alt={props.data.label}
		/>
		{props.data.label}
	</Option>
);

const Search = (props: SearchProps) => {

	const { t } = useORTranslation(['Search', 'common']);
	const { language } = useORTranslation([])

	const dispatch = useAppDispatch();

	const intlConfigOptions: any = useAppSelector((state) => selectIntlConfigOptions(state));

	const [datePickerActive, setDatePickerActive] = useState<boolean>(false);

	const [haveCustomDateRange, setHaveCustomDateRange] = useState<boolean>(false);
	const [customDateRange, setCustomDateRange] = useState<DateRangeT>({ startDate: new Date(), endDate: new Date() });
	const [previousDateRangeSelection, setPreviousDateRangeSelection] = useState<string>("all");
	const [rangeSelectOptions, setRangeSelectOptions] = useState<{ key: string, text: string }[]>(
		[
			{ key: "all", text: t("all") },
			{ key: "today", text: t("today") },
			{ key: "yesterday", text: t("yesterday") },
			{ key: "thisweek", text: t("thisweek") },
			{ key: "lastweek", text: t("lastweek") },
			{ key: "thismonth", text: t("thismonth") },
			{ key: "lastmonth", text: t("lastmonth") },
			{ key: "select", text: t("select") },
		]
	);

	const [studyFilter, setStudyFilter] = useState<any>([
		{ value: JSON.stringify(["OPENED", "COMPLETED", "CANCELED"]), label: t('All', { ns: 'OrderList' }), icon: '' },
		{ value: JSON.stringify(["OPENED", "COMPLETED"]), label: t('Active', { ns: 'OrderList' }), icon: '' },
		{ value: JSON.stringify(["OPENED"]), label: t('Opened', { ns: 'OrderList' }), icon: 'StudyStateOpen.svg' },
		{ value: JSON.stringify(["COMPLETED"]), label: t('Completed', { ns: 'OrderList' }), icon: 'StudyStateCompleted.svg' },
		{ value: JSON.stringify(["CANCELED"]), label: t('Canceled', { ns: 'OrderList' }), icon: 'StudyStateClosed.svg' },
	]);

	const searchString: string = useAppSelector((state) => selectSearchString(state));
	const searchDateRange: DateRangeT = useAppSelector((state) => selectSearchDateRange(state));
	const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
	const selectedDateMenuEntry: string | undefined = useAppSelector((state) => selectDateMenuEntry(state));
	const studyStateFilter: string[] = useAppSelector((state) => selectStudyStateFilter(state));

	const [selectedStudyFilter, setSelectedStudyFilter] = useState<any>(studyFilter.find((filter: any) => filter.value === JSON.stringify(['OPENED', 'COMPLETED'])));

	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection'
		}
	]);

	const handleSearchButton = async (evt: any) => {
		evt.stopPropagation();
		evt.preventDefault();
		dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
	}

	const handleKeyDown = (event: any) => {
		if (event.key === 'Enter') {
			dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
		}
	}

	// bound to the search string input field this function fires searches for
	// input strings with length >= 3 and if the input field is cleared
	const handleSearch = async (evt: any) => {
		evt.stopPropagation();
		evt.preventDefault();
		const newSearchString = evt.target.value;
		if (newSearchString) {
			//console.log("Got new search string:" + newSearchString);
			dispatch({ type: "MainOrderListColumns/setSearchString", payload: { "searchString": newSearchString } });

			if (newSearchString.length > 2)
				dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
		} else {
			//console.log("Resetting search string...");
			dispatch({ type: "MainOrderListColumns/setSearchString", payload: { "searchString": "" } });
			dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
		}
	}

	const handleDateMenuSelection = async (evt: any) => {
		var value = evt.target.value;
		var dateRange = undefined;
		var today: Date = new Date();
		var haveRange = true;

		if (value !== 'select') {
			setPreviousDateRangeSelection(value);
		}

		switch (value) {
			case 'today':
				dateRange = {
					startDate: today,
					endDate: today
				}
				break;
			case 'yesterday':
				var yesterday: Date = new Date();
				yesterday.setDate(today.getDate() - 1);
				dateRange = {
					startDate: yesterday,
					endDate: yesterday
				}
				break;
			case 'thisweek':
				dateRange = {
					startDate: startOfWeek(Date.now()),
					endDate: endOfWeek(Date.now())
				}
				break;
			case 'lastweek':
				dateRange = {
					startDate: subDays(startOfWeek(Date.now()), 7),
					endDate: endOfWeek(subDays(startOfWeek(Date.now()), 7))
				}
				break;
			case 'thismonth':
				dateRange = {
					startDate: startOfMonth(Date.now()),
					endDate: endOfMonth(Date.now())
				}
				break;
			case 'lastmonth':
				dateRange = {
					startDate: subMonths(startOfMonth(Date.now()), 1),
					endDate: endOfMonth(subMonths(startOfMonth(Date.now()), 1))
				}
				break;
			case 'select':
				setDateRange(
					[{
						startDate: new Date(),
						endDate: new Date(),
						key: 'selection'
					}]
				)
				setDatePickerActive(true);
				haveRange = false;
				break;
			case 'custom':
				dateRange = customDateRange;
				break;
		} // switch


		dispatch({ type: "MainOrderListColumns/setSelectedDateMenuEntry", payload: value === 'select' ? previousDateRangeSelection : value });

		if (haveRange) {
			if (value === 'custom')
				setHaveCustomDateRange(true);
			else
				setHaveCustomDateRange(false);
			dispatch({ type: "MainOrderListColumns/setSearchDateRange", payload: dateRange });
			//setDatePickerActive(false);
			dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
		}

		return;
	} // handleDateMenuSelection

	const getDateRangeString = (dataRangeArg: DateRangeT) => {
		// TODO trim string avoiding unneeded info, e.g. the year when all years in the range are current
		if (dataRangeArg.startDate.toLocaleDateString(language, intlConfigOptions?.date) !== dataRangeArg.endDate.toLocaleDateString(language, intlConfigOptions?.date))
			return ("" + dataRangeArg.startDate.toLocaleDateString(language, intlConfigOptions?.date) + " - " + dataRangeArg.endDate.toLocaleDateString(language, intlConfigOptions?.date));
		else
			return ("" + dataRangeArg.startDate.toLocaleDateString(language, intlConfigOptions?.date));
	}

	const getDateRangeSelector = () => {
		if (haveCustomDateRange)
			return (
				<Select unstyled className="dateRangeSelector"
					classNames={{ control: (state) => "dateRangeSelectorControl", menu: (state) => "dateRangeSelectorSelect", option: (state) => "dateRangeSelectorSelectOption" }}
					isClearable={false}
					isSearchable={false}
					hideSelectedOptions={true}
					onChange={(evt) => handleDateMenuSelection({ target: evt })}
					options={rangeSelectOptions?.map((v: any) => { return { value: v.key, label: v.text } })}
					value={{ value: 'custom', label: getDateRangeString(customDateRange) }}
				/>
			);
		else
			return (
				<Select unstyled className="dateRangeSelector"
					classNames={{ control: (state) => "dateRangeSelectorControl", menu: (state) => "dateRangeSelectorSelect", option: (state) => "dateRangeSelectorSelectOption" }}
					isClearable={false}
					isSearchable={false}
					hideSelectedOptions={true}
					onChange={(evt) => handleDateMenuSelection({ target: evt })}
					options={rangeSelectOptions?.map((v: any) => { return { value: v.key, label: v.text } })}
					defaultValue={rangeSelectOptions?.map((v: any) => { return { value: v.key, label: v.text } }).find((v: any) => v.value === 'all')}
					value={rangeSelectOptions?.map((v: any) => { return { value: v.key, label: searchDateRange ? getDateRangeString(searchDateRange) : v.text } }).find((v: any) => v.value === selectedDateMenuEntry)}
				/>
			);
	}

	const handleApply = () => {
		if (dateRange && dateRange.length > 0) {
			dispatch({ type: "MainOrderListColumns/setSearchDateRange", payload: dateRange[0] });
			setHaveCustomDateRange(true);
			const currentDateRange = { startDate: dateRange[0].startDate, endDate: dateRange[0].endDate };
			setCustomDateRange(currentDateRange);
			setRangeSelectOptions([...(rangeSelectOptions.filter((val: { key: string, text: string }) => val.key !== "custom")),
			{ key: "custom", text: getDateRangeString(currentDateRange) }])
		}
		dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
	}

	const handleDateRangePickerClose = () => {
		setDatePickerActive(false);
		const element = document.getElementsByClassName("dateRangeSelector");
		if (element?.item(0) && (element.item(0) as HTMLSelectElement)) {
			(element.item(0) as HTMLSelectElement).value = previousDateRangeSelection;
		}
	};

	const handleDateRangeSelection = async (ranges: any) => {
		setDateRange([ranges.selection]);
	}

	const handlestudyFilterSelection = (option: any) => {
		setSelectedStudyFilter(option);

		dispatch({ type: "MainOrderListColumns/setStudyStateFilter", payload: JSON.parse(option?.value) });
		dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));

	}

	useEffect(() => {
		if (searchDateRange) {
			setCustomDateRange({ ...searchDateRange });
			setRangeSelectOptions([...(rangeSelectOptions.filter((val: { key: string, text: string }) => val.key !== "custom")),
			{ key: "custom", text: getDateRangeString(searchDateRange) }])
		}

	}, []);

	useEffect(() => {
		if (studyStateFilter) {
			setSelectedStudyFilter(studyFilter.find((filter: any) => filter.value === JSON.stringify(studyStateFilter)));
		}

	}, [studyStateFilter]);

	useEffect(() => {
		setRangeSelectOptions([
			{ key: "all", text: t("all") },
			{ key: "today", text: t("today") },
			{ key: "yesterday", text: t("yesterday") },
			{ key: "thisweek", text: t("thisweek") },
			{ key: "lastweek", text: t("lastweek") },
			{ key: "thismonth", text: t("thismonth") },
			{ key: "lastmonth", text: t("lastmonth") },
			{ key: "select", text: t("select") },
		]);

		if (selectedDateMenuEntry === undefined) {
			handleDateMenuSelection({ target: { value: 'all' } })
		}

		const newStudyFilter = [
			{ value: JSON.stringify(["OPENED", "COMPLETED", "CANCELED"]), label: t('All', { ns: 'OrderList' }), icon: '' },
			{ value: JSON.stringify(["OPENED", "COMPLETED"]), label: t('Active', { ns: 'OrderList' }), icon: '' },
			{ value: JSON.stringify(["OPENED"]), label: t('Opened', { ns: 'OrderList' }), icon: 'StudyStateOpen.svg' },
			{ value: JSON.stringify(["COMPLETED"]), label: t('Completed', { ns: 'OrderList' }), icon: 'StudyStateCompleted.svg' },
			{ value: JSON.stringify(["CANCELED"]), label: t('Canceled', { ns: 'OrderList' }), icon: 'StudyStateClosed.svg' },
		]

		setStudyFilter(newStudyFilter);
		setSelectedStudyFilter(newStudyFilter.find((filter: any) => filter.value === JSON.stringify(studyStateFilter)));

	}, [language, t]);

	return (
		<div className={"studySearch"}>
			<div className={"search-div"}>
				<div className={"search-calender-dateRange"}>
					<div className={"search-calender"}>
						<img className="search-calender-img" src={`${imagePathForCurrentTheme}Group 2.svg`}
							onError={(event: any) => { event.target.src = "/images/Group 2.svg"; event.onerror = null }}
							alt="Group 2.svg" onClick={(evt: any) => setDatePickerActive(true)} />
					</div>
					<div className="dateRangeSelectorDiv">
						{getDateRangeSelector()}
					</div>
				</div>
				<div className={"search-fields"}>
					<input type="search" className="searchInput" name="Search" onChange={handleSearch} value={searchString}
						onKeyDown={handleKeyDown} placeholder={t("Search") as string} />
					<button className="searchButton" onClick={handleSearchButton}>
						<img
							src={`${imagePathForCurrentTheme}search.svg`}
							onError={(event: any) => { event.target.src = "/images//search.svg"; event.onerror = null }}
							alt="initiate search" />
					</button>
				</div>
				<div className={"study-filter"}>
					<Select unstyled className="studyFilterDropdown"
						classNames={{ control: (state) => "studyFilterControl", menu: (state) => "studyFilterSelect", option: (state) => "studyFilterOption" }}
						isClearable={false}
						isSearchable={false}
						hideSelectedOptions={true}
						onChange={handlestudyFilterSelection}
						options={studyFilter}
						value={selectedStudyFilter}
					//components={{ Option: IconOption }}
					/>
				</div>
			</div>
			<CommonDialog onClose={handleDateRangePickerClose} title={t('dateRangePicker')} visible={datePickerActive} okText={t("apply", { ns: 'common' })} cancelText={t("cancel", { ns: 'common' })}
				onOk={handleApply}
				default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 450) / 2, width: 355, height: undefined }} childProps={{}}>
				<DateRangeSelector
					handleDateRangeSelection={(ranges: any) => handleDateRangeSelection(ranges)}
					dateRange={dateRange}
				/>
			</CommonDialog>
		</div>
	);
}

export default Search;
