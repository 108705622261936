import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useGetworkitemQuery, workitem } from "../../apis/apiSlice";
import { AppDispatch, RootState } from "../../store";
import { getRawImageActive, getDisplayStep, getAllDisplayStepsForRejectedAcquisitions, getRejectedSeriesFromProtocolSteps, getCurrentProtocolStep, getCurrentProtocolStepNumber, getProtocolStepSelectionProps, matrixImagesItem, selectedWorkitem, selectMatrixImages } from "../ImageDisplay/ImageDisplaySlice";

import PlannedItem from "./PlannedItem";
import RejectedSeries from "./RejectedSeries";
import Series from "./Series";
import { Constants } from "../../Constants";

interface WorkitemProps {
    orderId: string;
    workitemId: string;
    workitem?: workitem;
    getRawSeries: boolean;
    includeImageDesc: boolean;
    showPlannings: boolean;
    showDocuments: boolean;
    showRejected: boolean;
    className: string | undefined;
    onImageSelect: (workitem: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined) => void;
}

export type workitemUpdateBodyType = { state: string, workitemId: string, created: string, title: string, desc: string, catalogueId: string };

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const Workitem = (props: WorkitemProps) => {
    const dispatch = useAppDispatch();

    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const matrixImages: matrixImagesItem[] = useAppSelector((state) => selectMatrixImages(state));

    let {
        data,
        isSuccess,
    }: { data: workitem, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } = useGetworkitemQuery(props.workitemId, {
        refetchOnMountOrArgChange: Constants.SYNCHRONOUS_BACKEND ? true : Constants.RTK_QUERY_CACHE_REFETCHONMOUNTORARGCHANGE,
        skip: props.workitem !== undefined,
    })

    if (props.workitem) {
        data = props.workitem;
        isSuccess = true;
    }

    const currentProtocolStep: any = useAppSelector((state) => getCurrentProtocolStep(state, props.workitem ?? data));
    const currentProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.PROCESSING_STEP_TYPE, 'performed', 'rejected', data));

    const currentRawProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.ACQUISITION_STEP_TYPE, 'performed', 'rejected', data));

    const rejectedSeriesAsString: string | undefined = useAppSelector((state) => getRejectedSeriesFromProtocolSteps(state, props.workitem ?? data, props.showRejected));
    const rejectedSeries: any = rejectedSeriesAsString ? JSON.parse(rejectedSeriesAsString) : [];

    const showRawImage: boolean = useAppSelector((state) => getRawImageActive(state, matrixImages.findIndex((mi: { matrixIndex: number; imageId: string }) => mi.imageId === props.workitemId)));

    const displayStepAsString: string | undefined = useAppSelector((state) => getDisplayStep(state, props.workitem ?? data, showRawImage));
    const displayStep = displayStepAsString ? JSON.parse(displayStepAsString) : undefined;

    const rejectedDisplayStepsAsString: string | undefined = useAppSelector((state) => getAllDisplayStepsForRejectedAcquisitions(state, props.workitem ?? data));
    const rejectedDisplaySteps = rejectedDisplayStepsAsString ? JSON.parse(rejectedDisplayStepsAsString) : undefined;

    const protocolCorrelationId: string | undefined = displayStep?.correlationId;

    const protocolStepSelectorProps: any = useAppSelector((state) => getProtocolStepSelectionProps(state, props.workitemId));



    let className: string = props.className ? props.className : (matrixImages ? (props.workitemId === currentWorkitemId
        ? "previewImage-list-img-selected"
        : matrixImages.find((mi: { matrixIndex: number; imageId: string }) => mi.imageId === props.workitemId)
            ? "previewImage-list-img-matrixSelected"
            : "previewImage-list-img") : "preview-image");

    useEffect(() => {

        const getStepType = () => {
            if (data?.protocol?.steps?.find((step: any) => step?.type === Constants.PROCESSING_STEP_TYPE && step?.performed !== undefined)) {
                return Constants.PROCESSING_STEP_TYPE;
            }
            return Constants.ACQUISITION_STEP_TYPE;
        }

        if (props.workitem && isSuccess) {
            switch (data.state) {
                case "SCHEDULED":
                case "IN_PROGRESS":
                    dispatch({ type: 'ImageDisplay/setProtocolStepSelectionProps', payload: { workitemId: props.workitemId, stepType: getStepType(), requiredAttribute: 'performed', excludedAttribute: 'rejected', index: -1, seriesIndex: -1, correlationId: '' } });
                    break;
                case "COMPLETED":
                    dispatch({ type: 'ImageDisplay/setProtocolStepSelectionProps', payload: { workitemId: props.workitemId, stepType: Constants.POSTPROCESSING_STEP_TYPE, requiredAttribute: 'performed', excludedAttribute: 'rejected', index: -1, seriesIndex: -1, correlationId: '' } });
                    break;
            }
        }

        return () => {
            if (props.workitem && isSuccess) {
                dispatch({ type: 'ImageDisplay/removeProtocolStepSelectionProps', payload: props.workitemId });
            }
        }
    }, [isSuccess, props.orderId]);


    /* useEffect(() => {
        if (data.protocol) {
            console.log(data.protocol?.steps);
            console.log(displayStep);
            const foundSteps = data.protocol?.steps?.filter((step: any) => step.type === Constants.ACQUISITION_STEP_TYPE && step?.rejected === undefined && step?.performed === undefined);
            console.log(foundSteps);

            console.log("workitem:" + props.workitemId + " " + data?.id + " " + isSuccess + " isPlanning: " + (foundSteps.length > 0));
            console.log(currentProtocolSettingsStepNumber);
            console.log(currentRawProtocolSettingsStepNumber);
            console.log(currentProtocolStep);
            console.log(protocolStepSelectorProps);
            console.log(currentProtocolStep?.performed?.output?.series);

            const planning: boolean = foundSteps !== undefined && foundSteps.length > 0;

            setIsPlanning(planning)

            const foundRawImageSteps = data.protocol?.steps?.filter((step: any) => step.type === Constants.ACQUISITION_STEP_TYPE &&
                step?.rejected === undefined && step?.performed?.output?.series !== undefined &&
                Array.isArray(step?.performed?.output?.series) && step?.performed?.output?.series.length > 0);

            console.log(foundRawImageSteps);

            const rawImage: boolean = foundRawImageSteps !== undefined && foundRawImageSteps.length > 0;

            setHasRawImage(rawImage)

            const foundImageSteps = data.protocol?.steps?.filter((step: any) => step.type !== Constants.ACQUISITION_STEP_TYPE &&
                step?.rejected === undefined && step?.performed?.output?.series !== undefined &&
                Array.isArray(step?.performed?.output?.series) && step?.performed?.output?.series.length > 0);

            console.log(foundImageSteps);

            let hasImage: boolean = false;
            if (foundImageSteps !== undefined && foundImageSteps.length > 0) {
                for (const step of foundImageSteps) {
                    const rawStep = data.protocol?.steps?.filter((step1: any) => step1.type === Constants.ACQUISITION_STEP_TYPE &&
                        step1?.correlationId === step?.correlationId && step1?.rejected === undefined);
                    if (rawStep !== undefined && rawStep.length > 0) {
                        hasImage = true;
                        break;
                    }
                }
            }

            setHasImage(hasImage);

            console.log("Planning? " + planning + " " + rawImage + " " + hasImage);
        }

    }, [JSON.stringify(data.protocol ? data.protocol : {})]); */

    //console.log(data?.id + " " + currentProtocolStep);


    //console.log(data?.protocol?.steps[currentRawProtocolSettingsStepNumber]?.performed?.output?.series);
    //console.log(currentProtocolStep);


    /* console.log("workitem:"+props.workitemId);

    if(currentProtocolStep && currentProtocolStep.performed?.output?.series)
    console.log("workitem:"+props.workitemId+" / currentProtocolStep.output.series:"+currentProtocolStep.performed?.output?.series); */

    /* if(props.workitem && props.workitem.protocol && props.workitem.protocol.steps && (props.workitem.protocol.steps.length > 0)) {
        console.log(props.workitem.protocol.steps[props.workitem.protocol.steps.length-1].performed.output.series[0]);
    } */



    const getRawSeriesIdsFromDisplayStep = (displaystep: any): any => {
        if (data?.protocol?.steps) {
            const n = data?.protocol?.steps.length;
            for (let i = 0; i < n; i++) {
                const rawstep = data?.protocol?.steps[i];
                if (rawstep && rawstep.type === Constants.ACQUISITION_STEP_TYPE && rawstep.correlationId === displaystep.correlationId) {
                    return rawstep.performed?.output?.series;
                }
            }
        }
        return [];
    }

    const getRawStepNumber = (): number => {
        let rawStepNumber = -1;

        if (data?.protocol?.steps) {
            const n = data?.protocol?.steps.length;
            for (let i = 0; i < n; i++) {
                const rawstep = data?.protocol?.steps[i];
                if (rawstep && rawstep.type === Constants.ACQUISITION_STEP_TYPE && !rawstep.rejected) {
                    rawStepNumber = i;
                    break;
                }
            }
        }

        return rawStepNumber;
    }


    const getRejectedSeriesComponent = (step: any): JSX.Element => {

        let rawSeriesIds = [];
        if (data?.protocol?.steps) {
            const n = data?.protocol?.steps.length;
            for (let i = 0; i < n; i++) {
                const rawstep = data?.protocol?.steps[i];
                if (rawstep && rawstep.type === Constants.ACQUISITION_STEP_TYPE && rawstep.correlationId === step.correlationId) {
                    rawSeriesIds = rawstep.performed?.output?.series;
                }
            }
        }
        // TODO rather use getRawSeriesIdsFromDisplayStep() for showRecover check?
        return (<RejectedSeries key={step.performed?.output?.series} seriesIds={step.performed?.output?.series}
            rawSeriesIds={rawSeriesIds} onImageSelect={props.onImageSelect}
            workitemId={props.workitemId} workitemData={data} orderId={props.orderId}
            getRawSeries={props.getRawSeries} className={className}
            includeImageDesc={true}
            showRecover={displayStep ? getRawSeriesIdsFromDisplayStep(displayStep)?.length === 0 : true} />);
    }

    return (
        <>
            {isSuccess && data.id === props.workitemId && (data?.details?.document_mime_typ === undefined || data?.details?.document_mime_typ !== 'application/pdf' || props.showDocuments) ?
                <>
                    <div className='protocolStepHr' />
                    <div className='protocolStep'>
                        {currentProtocolStep ?
                            <>
                                <Series seriesIds={currentProtocolStep?.performed?.output?.series} rawSeriesIds={data?.protocol?.steps[currentRawProtocolSettingsStepNumber]?.performed?.output?.series} onImageSelect={props.onImageSelect}
                                    workitemId={props.workitemId} workitemData={data} orderId={props.orderId}
                                    getRawSeries={true} className={className} includeImageDesc={props.includeImageDesc}
                                    protocolCorrelationId={currentProtocolStep?.correlationId} currentProtocolStep={currentProtocolStep}/>
                            </> :
                            <>
                                {props.showPlannings && (data?.state === 'SCHEDULED' || data?.state === 'IN_PROGRESS') ?
                                    <PlannedItem orderId={props.orderId} seriesId={undefined} workitemData={data}
                                        workitemId={props.workitemId} catalogueId={data?.details?.catalogueId}
                                        includeImageDesc={props.includeImageDesc} currentProtocolStep={currentProtocolStep}
                                        className={
                                            matrixImages ? (props.workitemId === currentWorkitemId
                                                ? "planned-img-selected"
                                                : matrixImages.find((mi: { matrixIndex: number; imageId: string }) => mi.imageId === props.workitemId)
                                                    ? "planned-img-matrixSelected"
                                                    : "planned-img") : "planned"
                                        } />

                                    : null
                                }
                            </>
                        }
                    </div>
                    {props.showRejected ? (rejectedDisplaySteps.length > 0) ? rejectedDisplaySteps.map((item: any) => getRejectedSeriesComponent(item)) : "" : null}
                </>
                : null}
        </>
    );
}

export default Workitem;
