import { useRef, useState, useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { setCredentials, selectCurrentToken, refreshToken, selectCurrentToCookie, selectRefreshStatus, parseJwt, selectLicense, logOut, selectCurrentRefreshToken } from './authSlice'
import { useLoginMutation } from '../../apis/apiSlice'
import "./Login.scss";
import LoadingIndicator from '../ImageDisplay/LoadingIndicator';
import { Constants } from '../../Constants'
import { checkLicense, revokeToken } from './RequireAuth'
import { AppDispatch, RootState } from '../../store'
import { useORTranslation } from '../Localization/ORLocalization'
import { getImagePathForCurrentTheme, getLayout } from '../OrderList/OrdersSlice'
import { selectVersionString } from '../OrderList/MainOrderListSlice'

export type authResponse = { user: string, access_token: string, refresh_token: string, expires_in: number } | undefined;

export interface LocationState {
    from: {
        pathname: string;
        search: string;

    },
    isFromOrderList?: boolean;
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const Login = () => {

    const { t } = useORTranslation(['common']);

    const userRef = useRef<HTMLInputElement>(null);
    const errRef = useRef<HTMLParagraphElement>(null);
    const [user, setUser] = useState<string>('');
    const [pwd, setPwd] = useState<string>('');
    const [errMsg, setErrMsg] = useState<string>('');
    const [licenseValid, setLicenseValid] = useState<boolean>(false);
    const navigate = useNavigate();

    const [login, { isLoading, isError, error }] = useLoginMutation();
    const location = useLocation();
    const { from } = location.state as LocationState || {};
    const dispatch = useAppDispatch();


    const currentToken = useSelector(selectCurrentToken);
    const toCookie = useSelector(selectCurrentToCookie);
    const refreshStatus = useSelector(selectRefreshStatus);
    const currentLicense = useSelector(selectLicense);
    const currentRefreshToken = useSelector(selectCurrentRefreshToken);
    const layout: any= useSelector(getLayout);

    const versionString = useSelector(selectVersionString);

    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

    const handleLogout = async () => {
        revokeToken(currentRefreshToken, dispatch);
        location.pathname = '/';
        dispatch(logOut());
    }

    useEffect(() => {
        let isLicenseValid: boolean = true;
        let expiresShortly: string = '';
        if (toCookie) {
            isLicenseValid = checkLicense(toCookie?.license)?.valid;
            expiresShortly = checkLicense(toCookie?.license)?.expiresShortly;
            if (isLicenseValid) {

                setLicenseValid(true);
                if (userRef.current) userRef.current.focus();
                if (toCookie && refreshStatus !== 'loading' && currentToken === null) {
                    dispatch(refreshToken({ refresh_token: toCookie.refreshToken, user: toCookie.user }))
                        .unwrap().catch((err: any) => console.log(setErrMsg('no valid license')));
                }
                if (expiresShortly !== '') {
                    dispatch({ type: "Orders/setExpiresWarning", payload: expiresShortly });
                } else {
                    dispatch({ type: "Orders/setExpiresWarning", payload: '' });
                }
            } else {
                setLicenseValid(false);
                setErrMsg('no valid license');
                handleLogout();
            }
        }
    }, [dispatch, toCookie, refreshStatus, currentToken, currentLicense])


    useEffect(() => {

        if (licenseValid) setErrMsg('');
    }, [user, pwd, licenseValid])

    useEffect(() => {
        if (isError) {
            setErrMsg(error?.data?.error_description ? error.data.error_description : error?.error);
        }
    }, [isError, error?.error, error?.data?.error, error?.data?.error_description])

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        try {
            const userData = await login({ grant_type: 'password', username: user, password: pwd, scope: [] }).unwrap();

            let isLicenseValid = false;
            let expiresShortly: string = '';
            if (userData?.access_token) {
                const jwtData = parseJwt(userData.access_token);
                const license = jwtData[Constants.TOKEN_LICENSE_TAG];
                isLicenseValid = checkLicense(license)?.valid;
                expiresShortly = checkLicense(license)?.expiresShortly;
            }
            if (isLicenseValid) {
                setLicenseValid(true);
                dispatch(setCredentials({ ...userData, user }));
                if (expiresShortly !== '') {
                    dispatch({ type: "Orders/setExpiresWarning", payload: expiresShortly });
                } else {
                    dispatch({ type: "Orders/setExpiresWarning", payload: '' });
                }
                navigate(from ?? "/");
            } else {
                setLicenseValid(false);
                setErrMsg('no valid license');
            }

            setUser('');
            setPwd('');

        } catch (err: any) {
            setErrMsg(err?.data?.error_description ? err?.data?.error_description : err?.error);
            if (errRef.current) errRef.current.focus();
        }
    }

    const handleUserInput = (e: any) => setUser(e.target.value);

    const handlePwdInput = (e: any) => setPwd(e.target.value);

    const openProductInfoDialog = () => {
        dispatch({ type: "ProductInfoDialog/changeProductInfoDialogVisible", payload: true });
    };

    const content =
        toCookie && checkLicense(toCookie?.license)?.valid && refreshStatus !== 'failed' ? (
            (currentToken ?
                <Navigate to={from ? from.pathname + from.search : '/'} state={{ from: location }} replace /> :
                <LoadingIndicator index={-1} />
            )
        ) :
            (isLoading ?
                <LoadingIndicator index={-1} /> :
                (
                    <div className="loginWrapper" style={{ backgroundImage: `url('${layout?.background_image}')` }}>
                        <section className="login">
                            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                            <img className="Logo" src={layout?.logoPath} alt="Logo" />
                            <form onSubmit={handleSubmit}>
                                <input className='user'
                                    type="text"
                                    id="username"
                                    ref={userRef}
                                    value={user}
                                    onChange={handleUserInput}
                                    autoComplete="off"
                                    required
                                    autoFocus
                                />
                                <input className='pwd'
                                    type="password"
                                    id="password"
                                    onChange={handlePwdInput}
                                    value={pwd}
                                    required
                                />
                                <button className='LoginButton' disabled={!(pwd && user)}>{t('login')}</button>
                            </form>

                        </section>
                        <p className=" versionString">
                                    {versionString}
                        </p>
                        <div className="infoButtonWrapper">
                            <button className="infoButton" onClick={() => openProductInfoDialog()}>
                                <label className="infoButtonLabel">Info</label>
                                <img className="newSubjectImg" src={`${imagePathForCurrentTheme}info_button.svg`}
                                    onError={(event: any) => { event.target.src = "/images/info_button.svg"; event.onerror = null }}
                                    alt="Info" />
                            </button>
                        </div>
                    </div>
                ))

    return content
}
export default Login
