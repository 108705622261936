import React from "react";
import cornerstone from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import './OrderInfo.scss';
import ItemActionButtonGroup from '../Utils/ItemActionButtonGroup';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getActiveToolIcon, getExportActive, selectedWorkitem, selectIsOverviewAndAnnotationsVisible, selectOrderInfoVisible, selectToolsPanel } from "./ImageDisplaySlice";
import HeaderInsetRight from "./HeaderInsetRight";
import {
    getImagePathForCurrentTheme,
    getLayout,
    getOrderDescBarConfig,
    getSelectedWorkitem, selectArtifactForSelectedWorkitem, selectArtifactIdForSelectedWorkitem, selectCurrentOrder,
    selectedStudy, selectInstanceForWorkitem, selectSeriesDataForWorkitem
} from "../OrderList/OrdersSlice";
import { apiSlice, useCancelStudyMutation, useCompleteStudyMutation } from "../../apis/apiSlice";
import InfoPanel from "../Utils/ConfigurableInfoPanel/InfoPanel";
import { submit } from "../Utils/ConfirmDialog";
import { selectDeviceRunnerError, selectDeviceRunnerPath, setAcquisitionActive } from "../Akquisition/AcquisitionSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { selectAllowComplete } from "../OrderList/MainOrderListSlice";
import { Constants } from "../../Constants";
import { useORTranslation } from "../Localization/ORLocalization";
import Tools from "./Tools/Tools";
import User from "../common/User";
import { selectProcedureSelectionVisibilityState, selectedComponentTreeVisible, selectedXrayHelperVisible } from "../Procedures/ProcedureSlice";

const BaseAnnotationTool = cornerstoneTools.importInternal('base/BaseAnnotationTool');
const getToolState = cornerstoneTools.getToolState;

export type Order = {
    Id: string;
    Date: string;
    Description: string;
    orderId: string;
};

interface OrderInfoProps {
    orderId: string;
    onSnapshotClick: (imageFileName: string) => void;
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const OrderInfo = (props: OrderInfoProps) => {
    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['InfoPanel', 'NavbarImage']);

    const [completeStudyDetails] = useCompleteStudyMutation();
    const [cancelStudyDetails] = useCancelStudyMutation();
    const location = useLocation();
    const navigate = useNavigate()

    const currentOrderId: string | undefined = useAppSelector((state) => selectCurrentOrder(state));

    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));

    //const study: any = useAppSelector((state) => selectedStudy(state, currentOrderId ?? ''));
    const currentStudyAsString: string | undefined = useAppSelector((state) => selectedStudy(state, props.orderId ?? ''));
    const study = currentStudyAsString ? JSON.parse(currentStudyAsString) : undefined;
    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
    const currentSeriesAsString: string | undefined = useAppSelector((state) => selectSeriesDataForWorkitem(state, currentWorkitemId));
    const currentSeries = currentSeriesAsString ? JSON.parse(currentSeriesAsString) : undefined;
    const currentInstanceAsString: string | undefined = useAppSelector((state) => selectInstanceForWorkitem(state, currentWorkitemId));
    const currentInstance = currentInstanceAsString ? JSON.parse(currentInstanceAsString) : undefined;
    const currentArtifactAsString: string | undefined = useAppSelector((state) => selectArtifactForSelectedWorkitem(state));
    const currentArtifact = currentArtifactAsString ? JSON.parse(currentArtifactAsString) : undefined;
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const isExportActive: boolean = useAppSelector((state) => getExportActive(state));

    const layout: any = useAppSelector((state) => getLayout(state));

    const currentArtifactId: string | undefined = useAppSelector((state) => selectArtifactIdForSelectedWorkitem(state));
    const allowComplete: boolean = useAppSelector((state) => selectAllowComplete(state));
    const activeToolIcon: string = useAppSelector((state) => getActiveToolIcon(state));
    const isToolsPanel: boolean = useAppSelector((state) => selectToolsPanel(state));
    const isOrderInfoVisible: boolean = useAppSelector((state) => selectOrderInfoVisible(state));
    const isOverviewAndAnnotationsVisible: boolean = useAppSelector((state) => selectIsOverviewAndAnnotationsVisible(state));
    const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));
    const componentTreeVisible: boolean = useAppSelector((state) => selectedComponentTreeVisible(state));
    const deviceRunnerPath: string = useAppSelector((state) => selectDeviceRunnerPath(state));
    const deviceRunnerError: string = useAppSelector((state) => selectDeviceRunnerError(state));

    const orderDescBarConfig: any = useAppSelector((state) => getOrderDescBarConfig(state));

    const isXrayhelperVisible: any = useAppSelector((state) => selectedXrayHelperVisible(state));

    const dotsButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
        dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 2 });
    };

    const finalizeStudyButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
        dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 2 });
    };

    const editButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
        dispatch({ type: "ImageDisplay/setOrderInfoVisible", payload: !isOrderInfoVisible });
    };

    const completeButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
        completeStudyDetails({ orderId: props.orderId }).unwrap();
    };

    const cancelButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
        cancelStudyDetails({ orderId: props.orderId }).unwrap();
        dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
        dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
        dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: "" });
        navigate(`/${location?.search ?? ''}`);
    };

    const openDicomTagBrowser = () => {
        dispatch({ type: "DicomTagBrowser/changeDicomTagBrowserVisible", payload: true });
    };

    const onToolsPanel = () => {
        dispatch({ type: "ImageDisplay/setToolsPanel", payload: !isToolsPanel });
    };

    const onAnnotationsVisible = () => {
        dispatch({ type: "ImageDisplay/setIsOverviewAndAnnotationsVisible", payload: !isOverviewAndAnnotationsVisible });

        const toolList = cornerstoneTools.store?.state?.tools?.filter(
            (tool: any) =>
                (tool.mode === 'active' ||
                    tool.mode === 'passive' ||
                    tool.mode === 'enabled') &&
                tool instanceof BaseAnnotationTool
        );
        toolList?.forEach((tool: any) => {
            if (tool?.element?.id !== Constants.OVERVIEW_ELEMENT_NAME) {
                const toolState = getToolState(tool.element, tool.name);
                if (toolState && toolState.data) {
                    for (let i = 0; i < toolState.data.length; i++) {
                        toolState.data[i].visible = !isOverviewAndAnnotationsVisible;
                        cornerstone.updateImage(tool.element);
                    }
                }
            }
        });
    };

    const setProcedureSelectionVisibilityState = (state: number) => {
        dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: state });
        if (state <= 1) {
            try {
                console.log("putChangeOperationMode false");
                dispatch(apiSlice.endpoints.putChangeOperationMode.initiate(
                    {
                        deviceRunnerPath: deviceRunnerPath, qualifier: 'default',
                        active: false, studyId: undefined, workitemId: undefined
                    })).unwrap();
            } catch (error) {
                dispatch({ type: "Acquisition/setDeviceRunnerError", payload: 'device runner connection error' });;
            }
        }
    }

    const setXrayHelperState = (isVisible: boolean) => {
        dispatch({ type: "Procedure/setXrayHelperVisible", payload: isVisible });
    }

    const toggleComponentTreeVisible = () => {
        dispatch({ type: "Procedure/setComponentTreeVisible", payload: !componentTreeVisible });
    };

    return (
        <div className="buttonBar" key='buttonBar'>
            <div className="orderInfo" key="orderInfo">
                {<InfoPanel className="infoPanelWrapper_OrderInfo" configFile={allowComplete ? "/OrderDescBarConfig.json" : "/OrderDescBarConfig_noOrderState.json"}
                    workitemId={currentWorkitemId} artifactId={currentArtifactId} orderId={currentOrderId ?? ''}
                    sources={{ currentStudy: study, currentWorkitem, currentSeries, currentInstance, currentArtifact }}
                    editMode={false} editEnabled={study && (study.state !== 'COMPLETED' || !allowComplete)} />}
                <div className="actionButtonGroup">
                    <ItemActionButtonGroup ignoreFirstVLine={orderDescBarConfig?.content === undefined || orderDescBarConfig?.content?.length === 0} callbacks={{
                        downloadButtonClick: dotsButtonClick,
                        editButtonClick: editButtonClick,
                        finalizeStudyButtonClick: finalizeStudyButtonClick,
                        closeButtonClick: () => { submit([t(`confirmationClose${Constants.ORDER_IDENTIFIER}`), t('confirmationQuestion')], completeButtonClick) },
                        cancelButtonClick: () => { submit([t(`confirmationCancel${Constants.ORDER_IDENTIFIER}`), t('confirmationQuestion')], cancelButtonClick) }
                    }}
                        titles={{
                            downloadButtonClick: t("exportImages") as string,
                            editButtonClick: t(`edit${Constants.ORDER_IDENTIFIER}Info`) as string,
                            closeButtonClick: t(`complete${Constants.ORDER_IDENTIFIER}`) as string,
                            cancelButtonClick: t(`cancel${Constants.ORDER_IDENTIFIER}`) as string,
                            finalizeStudyButtonClick: t("completeStudy") as string
                        }} />
                </div>
            </div>

            {!(procedureSelectionVisibilityState > 0 && layout?.showProcedureSelectionPanel) ?
                <>
                    <HeaderInsetRight onSnapshotClick={props.onSnapshotClick} orderId={currentOrderId ?? ''} key="HeaderInsetRight" />
                    <div className={isExportActive ? "progress_wrapper" : "progress_wrapper_invisible"}>
                        <label>{t("imageExport")}:</label>
                        <div className="progress">

                            <div className="progress-bar" />
                            <div className="progress-stripes"></div>
                        </div>
                    </div>
                    {layout?.display_tool_in_navbar ?
                        <div className="NavbarTools">
                            <Tools orderId={currentOrderId ?? ''} />
                        </div>
                        : null}

                    <div className="ButtonsRight">
                        {activeToolIcon !== '' ?
                            <>
                                <div className="ToolsActive" title={t('activeTool', { ns: 'NavbarImage' }) as string}>
                                    <img className="ToolsActiveImg" src={activeToolIcon} alt="Active Tool" />
                                </div>
                                <span className="vl" />
                            </>
                            :
                            null}
                        <div className="dicomTagBrowserButton" title={t('dicomTagBrowser', { ns: 'NavbarImage' }) as string}>
                            <img className="dicomTagBrowserImg" onMouseDown={() => openDicomTagBrowser()} onTouchStart={() => openDicomTagBrowser()}
                                src={`${imagePathForCurrentTheme}missiridia-Universal-information-symbol.svg`}
                                onError={(event: any) => { event.target.src = "/images/missiridia-Universal-information-symbol.svg"; event.onerror = null }}
                                alt="tag browser" />
                        </div>
                        <span className="vl" />

                        <div className="ToolsPanel" title='Tools Panel'>
                            {isToolsPanel ?
                                <img className="ToolsPanelImg" onMouseDown={() => onToolsPanel()} onTouchStart={() => onToolsPanel()}
                                    src={`${imagePathForCurrentTheme}panel-right-svgrepo-com.svg`}
                                    onError={(event: any) => { event.target.src = "/images/panel-right-svgrepo-com.svg"; event.onerror = null }}
                                    alt="tag browser" />
                                :
                                <img className="ToolsPanelImg" onMouseDown={() => onToolsPanel()} onTouchStart={() => onToolsPanel()}
                                    src={`${imagePathForCurrentTheme}panel-right-svgrepo-com1.svg`}
                                    onError={(event: any) => { event.target.src = "/images/panel-right-svgrepo-com1.svg"; event.onerror = null }}
                                    alt="tag browser" />
                            }
                        </div>

                        <span className="vl1" />

                        <div className="AnnotationsOnOff" title={isOverviewAndAnnotationsVisible ? t('hideAnnotations', { ns: 'NavbarImage' }) as string : t('showAnnotations', { ns: 'NavbarImage' }) as string}>
                            {isOverviewAndAnnotationsVisible ?
                                <img className="ToolsPanelImg" onMouseDown={() => onAnnotationsVisible()} onTouchStart={() => onAnnotationsVisible()}
                                    src={`${imagePathForCurrentTheme}annotationen_aus1.svg`}
                                    onError={(event: any) => { event.target.src = "/images/annotationen_aus1.svg"; event.onerror = null }}
                                    alt="tag browser" />
                                :
                                <img className="ToolsPanelImg" onMouseDown={() => onAnnotationsVisible()} onTouchStart={() => onAnnotationsVisible()}
                                    src={`${imagePathForCurrentTheme}annotationen_ein1.svg`}
                                    onError={(event: any) => { event.target.src = "/images/annotationen_ein1.svg"; event.onerror = null }}
                                    alt="tag browser" />
                            }
                        </div>

                        <div className="NavbarUser">
                            <User />
                        </div>
                    </div>
                </> :
                <>
                    <>
                        <button className={procedureSelectionVisibilityState === 1 && !isXrayhelperVisible ? "componentTree_visible" : "componentTree_invisible"} onMouseDown={() => toggleComponentTreeVisible()}>
                            <img className={"componentTreeVisibleImg"}
                                src={componentTreeVisible ? `${imagePathForCurrentTheme}person-03-svgrepo-com.svg` : `${imagePathForCurrentTheme}tree-svgrepo-com.svg`}
                                onError={(event: any) => { event.target.src = componentTreeVisible ? "/images/person-03-svgrepo-com.svg" : "/images/tree-svgrepo-com.svg"; event.onerror = null }}
                                alt="tag browser" />
                        </button>
                    </>
                    <>
                        <button type="submit" className={procedureSelectionVisibilityState > 0 && currentWorkitem?.protocol ? "xrayHelperButton" : "xrayHelperButton_invisisble"}
                            onMouseDown={() => setXrayHelperState(!isXrayhelperVisible)}  onTouchStart={() => setXrayHelperState(!isXrayhelperVisible)}>
                            {isXrayhelperVisible ?
                                <img className="xrayHelperButtonImg"
                                    src={`${imagePathForCurrentTheme}annotationen_aus1.svg`}
                                    onError={(event: any) => { event.target.src = "/images/annotationen_aus1.svg"; event.onerror = null }}
                                    alt="tag browser" />
                                :
                                <img className="xrayHelperButtonImg"
                                    src={`${imagePathForCurrentTheme}annotationen_ein1.svg`}
                                    onError={(event: any) => { event.target.src = "/images/annotationen_ein1.svg"; event.onerror = null }}
                                    alt="tag browser" />
                            }
                            <label>{t("xrayHelper", {ns: "xrayhelper"})}</label>
                        </button>
                        <button type="submit" className={procedureSelectionVisibilityState === 1 && currentWorkitem?.protocol && deviceRunnerError === '' ? "exposeButton" : "exposeButton_invisisble"} onMouseDown={() => setProcedureSelectionVisibilityState(2)}>Expose</button>
                        <button type="submit" className={procedureSelectionVisibilityState === 2 ? "planButton" : "planButton_invisisble"} onMouseDown={() => { setProcedureSelectionVisibilityState(1) }}>Plan</button>
                        <label className={deviceRunnerError === '' ? "deviceRunnerErrorLable_invisible" : "deviceRunnerErrorLable"}>{"no device runner"}</label>
                    </>
                </>

            }
        </div>
    );
}

export default OrderInfo;
