import React from "react";

import { TypedUseSelectorHook, useDispatch, useSelector, } from "react-redux";
import { AppDispatch, RootState } from "../../store";


import "./GenderSelection2.scss";
import { getSelectedSubject } from "./SubjectSlice";
import { useORTranslation } from "../Localization/ORLocalization";

type GenderSelection2Props = {
    required?: boolean;
    editEnabled?: boolean;
};

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const GenderSelection2 = (props: GenderSelection2Props) => {

    const { t } = useORTranslation(['Subject', 'common']);

    const dispatch = useAppDispatch();

    const subject: any = useAppSelector((state) => getSelectedSubject(state));

    const onSexClicked = (value: string) => {
        if (props.editEnabled ?? false) {
            const newSubject: any = structuredClone(subject);
            let { details } = newSubject;

            if (value === 'male') {
                details = { ...details, pregnancyStatus: 'not_pregnant' };
            } else {
                details = { ...details, pregnancyStatus: undefined };
            }

            newSubject.details = { ...details, sex: value };
            dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
        }
    }

    const female = () =>
        <React.Fragment>
            <path d="M18.5 8.5C18.5 12.0899 15.5899 15 12 15C8.41015 15 5.5 12.0899 5.5 8.5C5.5 4.91015 8.41015 2 12 2C15.5899 2 18.5 4.91015 18.5 8.5Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.5 19H16.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 22L12 15" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </React.Fragment>;

    const male = () =>
        <React.Fragment>
            <path d="M15 15.5C15 19.0899 12.0899 22 8.5 22C4.91015 22 2 19.0899 2 15.5C2 11.9101 4.91015 9 8.5 9C12.0899 9 15 11.9101 15 15.5Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 2H22V9" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.5 10.5L22 2" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </React.Fragment>;

    const other = () =>
        <React.Fragment>
            <path d="M1 5.63282C1.00014 4.87503 1.2086 4.13186 1.60257 3.48454C1.99655 2.83722 2.56089 2.31065 3.23391 1.96239C3.90694 1.61413 4.66274 1.45758 5.41873 1.50985C6.17471 1.56211 6.90178 1.82119 7.52047 2.25876C8.13917 2.69633 8.62567 3.29555 8.92681 3.99094C9.22795 4.68632 9.33214 5.45111 9.228 6.20171C9.12385 6.95231 8.81538 7.65983 8.33629 8.24696C7.8572 8.83409 7.22593 9.27822 6.51148 9.53082C6.10835 9.67335 5.75934 9.9374 5.51256 10.2866C5.26579 10.6358 5.13339 11.0529 5.13361 11.4805V11.8332" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.13389 17.0002C4.99685 17.0002 4.86543 16.9458 4.76853 16.8489C4.67163 16.752 4.61719 16.6205 4.61719 16.4835C4.61719 16.3465 4.67163 16.215 4.76853 16.1181C4.86543 16.0212 4.99685 15.9668 5.13389 15.9668" stroke="black" strokeWidth="1.4" />
            <path d="M5.13477 17.0002C5.2718 17.0002 5.40323 16.9458 5.50013 16.8489C5.59703 16.752 5.65147 16.6205 5.65147 16.4835C5.65147 16.3465 5.59703 16.215 5.50013 16.1181C5.40323 16.0212 5.2718 15.9668 5.13477 15.9668" stroke="black" strokeWidth="1.4" />
        </React.Fragment>;

    const getIcon = (sex: string) => {
        switch (sex) {
            case 'female':
                return female();
            case 'male':
                return male();
            default:
                return other();
        } // switch
    }

    return (
        <div className="GenderSelection2">
            <label>
                <span>{t('Sex', { ns: 'InfoPanel' })}</span>
                <span className="required">&nbsp;{`${props.required ? '*' : ''}`}</span>
            </label>
            <div className="GenderSelectionButtons2">
                <div className={`${subject?.details?.sex === 'female' ? 'GenderSelectionButtonWrapper_selected' : 'GenderSelectionButtonWrapper'}`} onClick={() => onSexClicked('female')} id={props.editEnabled ? 'enabled' : 'disabled'}>
                    <svg className="GenderSelectionButton2" fill="none" viewBox="0 0 25 25" id="Flat">
                        {getIcon('female')}
                    </svg>
                </div>
                <div className={`${subject?.details?.sex === 'male' ? 'GenderSelectionButtonWrapper_selected' : 'GenderSelectionButtonWrapper'}`} onClick={() => onSexClicked('male')} id={props.editEnabled ? 'enabled' : 'disabled'}>
                    <svg className="GenderSelectionButton2" fill="none" viewBox="0 0 25 25" id="Flat">
                        {getIcon('male')}
                    </svg>
                </div>
                <div className={`${subject?.details?.sex === 'other' ? 'GenderSelectionButtonWrapper_selected' : 'GenderSelectionButtonWrapper'}`} onClick={() => onSexClicked('other')} id={props.editEnabled ? 'enabled' : 'disabled'}>
                    <svg className="GenderSelectionButton2" fill="none" viewBox="0 0 11.868 17.199" id="Flat">
                        {getIcon('')}
                    </svg>
                </div>

            </div>
        </div >
    );
};

export default GenderSelection2;
