import React, { useCallback, useEffect, useRef, useState } from "react";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";


import "./DatePicker.scss";
import DatePickerReact, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getImagePathForCurrentTheme } from "../OrderList/OrdersSlice";
import CommonDialog from "../Utils/CommonDialog";
import DateSelector from "./DateSelector";
import { format, parseISO } from "date-fns";
import { getSelectedSubject } from "./SubjectSlice";
import { useORTranslation } from "../Localization/ORLocalization";
import { getAge } from "./SubjectPanel";
import de from "date-fns/locale/de";
import { selectDataFormat } from "../ImageDisplay/ImageDisplaySlice";
registerLocale("de", de);

type DatePickerProps = {
    displayOptionsString?: string;
    required?: boolean;
    editEnabled?: boolean;
};

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const DatePicker = (props: DatePickerProps) => {

    const dispatch = useAppDispatch();

    const { t , locales, language} = useORTranslation(['Subject', 'common']);

    const subject: any = useAppSelector((state) => getSelectedSubject(state));
    const dateFormat: string | undefined = useAppSelector((state) => selectDataFormat(state));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

    const [datePickerActive, setDatePickerActive] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<string>(format(new Date(Date.now()), "yyyy-MM-dd"));

    const selectedDateRef = useRef<string>();
    selectedDateRef.current = selectedDate;
    const subjectRef = useRef<any>();
    subjectRef.current = subject;

    const handleDatePickerClose = () => {
        setDatePickerActive(false);
    };

    const handleDateSelection = async (date: any) => {
        setSelectedDate(format(date, "yyyy-MM-dd"));
        const newSubject: any = structuredClone(subjectRef.current);
        const { details } = newSubject;
        newSubject.details = { ...details, birthDate: format(date, "yyyy-MM-dd"), age: getAge(date) };
        dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
        setDatePickerActive(false);
    }

    const Image = React.memo(function Image() {
        return <img className="birthDateImg"
            src={`${imagePathForCurrentTheme}calender-svgrepo-com.svg`}
            onError={(event: any) => { event.target.src = "/images/calender-svgrepo-com.svg"; event.onerror = null }}
            alt="tag browser" />;
    });

    const displayOptions = props?.displayOptionsString !== undefined ? JSON.parse(props?.displayOptionsString) : undefined;

    const setStartDate = (date: any) => {
        setSelectedDate(format(date, "yyyy-MM-dd"));
    }

    const onFocusout = useCallback((evt: any) => {
        const newSubject: any = structuredClone(subjectRef.current);
        const { details } = newSubject;
        newSubject.details = { ...details, birthDate: selectedDateRef.current, age: selectedDateRef.current ? getAge(parseISO(selectedDateRef.current)) : 0 };
        dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
    }, [selectedDate])


    const getDatePickerInput = (readonly: boolean, className: string, iconFirst: boolean) => {

        return (
            <>
                {iconFirst ?
                    <button className="birthDateButton venaDefaultButton" onClick={() => setDatePickerActive(true && (props.editEnabled ?? false))} disabled={!(props.editEnabled ?? false)}>
                        <Image />
                    </button> : null
                }

                <div className={className}>
                    <DatePickerReact
                        selected={selectedDate ? parseISO(selectedDate) : new Date(Date.now())}
                        onChange={(date) => setStartDate(date)}
                        dateFormat={dateFormat ? dateFormat : "P"}
                        locale={locales}
                        onBlur={(evt: any) => onFocusout(evt)}
                        customInput={<input type="text" className="input" value={selectedDate} readOnly={false} />}
                        readOnly={!props.editEnabled}
                    />
                </div>

                {!iconFirst ?
                    <button className="birthDateButton venaDefaultButton" onClick={() => setDatePickerActive(true && (props.editEnabled ?? false))} disabled={!(props.editEnabled ?? false)}>
                        <Image />
                    </button> : null
                }
            </>
        )
    }

    useEffect(() => {
        if (subject?.details?.birthDate && parseISO(subject?.details?.birthDate).toLocaleDateString() !== 'Invalid Date') {
            setSelectedDate(subject?.details?.birthDate);
        }
    }, []);

    return (
        <div className="birthDate">
            {displayOptions && displayOptions.fieldset ?
                <fieldset className="fieldsetDatePicker">
                    {displayOptions?.hideLegend ?
                        null
                        :
                        <legend className="fieldsetLegendDatePicker">
                            <span >{t('Birth date', { ns: 'InfoPanel' })}</span>
                            <span className="required" >
                                &nbsp;{`${props.required ? '*' : ''}`}
                            </span>
                        </legend>
                    }
                    {getDatePickerInput(true, "datePickerContentEditable_edit", false)}
                </fieldset> :
                <>
                    {displayOptions && displayOptions.twolines ?
                        <div className="divDatePicker_twolines">
                            <span className="itemLabelWrapper">
                                <label className="itemLabel">{t('Birth date', { ns: 'InfoPanel' })}</label>
                                <span className="required" >
                                    &nbsp;{`${props.required ? '*' : ''}`}
                                </span>
                            </span>
                            <span className="datePicker_input">
                                {getDatePickerInput(false, "datePickerContentEditable_edit", true)}
                            </span>
                        </div> :
                        <div className="divDatePicker">
                            {displayOptions && displayOptions.hideTitle ?
                                null
                                :
                                <label>{t('Birth date', { ns: 'InfoPanel' })}</label>
                            }
                            {getDatePickerInput(false, "datePickerContentEditable_edit", false)}
                        </div>}
                </>
            }
            <CommonDialog zIndex={101} onClose={handleDatePickerClose} title={t('Birth date', { ns: 'InfoPanel' })} visible={datePickerActive} okText={''} cancelText={t("cancel", { ns: 'common' })}
                default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 450) / 2, width: 355, height: undefined }} childProps={{}}
                enableResizing={false}>
                <DateSelector date={parseISO(selectedDate)}
                    handleDateSelection={(ranges: any) => handleDateSelection(ranges)}
                />
            </CommonDialog>
        </div>
    );
};

export default DatePicker;
