import {createSlice} from '@reduxjs/toolkit'

export interface ProductInfoDialogStatus {
    isProductInfoDialogVisible: boolean
}

const initialState: ProductInfoDialogStatus = {
    isProductInfoDialogVisible: false,
}

const ProductInfoDialogSlice = createSlice({
    name: 'ProductInfoDialog',
    initialState,
    reducers: {
        changeProductInfoDialogVisible(state, action) {
            const isProductInfoDialogVisible: boolean = action.payload;
            state.isProductInfoDialogVisible = isProductInfoDialogVisible;
        },
    }
});

export const { changeProductInfoDialogVisible } = ProductInfoDialogSlice.actions;
export const ProductInfoDialogReducer = ProductInfoDialogSlice.reducer;
