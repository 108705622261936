import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState, } from "react";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstoneMath from "cornerstone-math";
import { getFilterForColor } from "./Tools/utils";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import * as cornerstoneFileImageLoader from "cornerstone-file-image-loader";
import * as dicomParser from "dicom-parser";

import Akquisition from '../Akquisition/Akquisition';
import { getImageDisplayInvalidated, getLayout } from "../OrderList/OrdersSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { Constants } from "../../Constants";
import TifRaw2FileImageLoader from "./TifRaw2FileImageLoader";
import TifRaw2UriImageLoader from "./TifRaw2UriImageLoader";
import ViewportElement from "./ViewportElement";
import { wadoRsMetaDataProvider, wadoUriMetaDataProvider } from "./MetadataProvider";
import DicomTagBrowser from "../DicomTagBrowser/DicomTagBrowser";
import { getIsImageMaximized, selectCornerstoneInitialized, selectExportDialogVisible, selectFinalizeStudyDialogVisible, selectMatrixColumns, selectMatrixIndex, selectMatrixRows, selectProcedureEditVisible } from "./ImageDisplaySlice";
import Messages from '../common/Messages';
import OrderInfoPanel from "../OrderList/OrderInfoPanel";
import ExportDialog from "../Preview/ExportDialog";
import FinalizeStudyDialog from "../Preview/FinalizeStudyDialog";
import CommonDialog from "../Utils/CommonDialog";
import { selectIsMessageHistoryVisible } from "../common/MessageSlice";
import { moveAnnotationWithCompletedTrigger } from "./Tools/utils";
import { useORTranslation } from "../Localization/ORLocalization";
import { selectTheme } from "../OrderList/MainOrderListSlice";
import BodyPartSelection from "../Procedures/BodyPartSelection";
import { selectProcedureSelectionVisibilityState } from "../Procedures/ProcedureSlice";
import { Model } from "flexlayout-react";
import FloatingToolsPanel from "./FloatingToolsPanel";
import useResizeObserver from "use-resize-observer";
import ProcedureDataEdit from "../Procedures/ProcedureDataEdit";

const BaseAnnotationTool = cornerstoneTools.importInternal('base/BaseAnnotationTool');
const CLICKPROXIMITY = 12;

type CornerstoneElementProps = {
    orderId: string;
    model: Model;
};

export const initCornerstone = () => {
    cornerstoneTools.external.cornerstone = cornerstone;
    cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
    cornerstoneWebImageLoader.external.cornerstone = cornerstone;
    cornerstoneTools.external.Hammer = Hammer;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    cornerstoneFileImageLoader.external.cornerstone = cornerstone;

    cornerstoneTools.init({ showSVGCursors: true });
    if (cornerstoneTools.store?.state) {
        cornerstoneTools.store.state.clickProximity = CLICKPROXIMITY;
    }
    cornerstoneTools.textStyle.setFontFamily("Ubuntu");
    cornerstoneTools.textStyle.setFontSize(16);
    // Set the tool width
    cornerstoneTools.toolStyle.setToolWidth(1.5);

    // Set color for inactive tools
    cornerstoneTools.toolColors.setToolColor('rgba(178, 194, 0, 1)');
    cornerstoneTools.toolColors.setActiveColor('rgba(255, 255, 0, 1)');



    cornerstoneTools.disableLogger();
    //cornerstoneTools.enableLogger('*');

    cornerstoneWADOImageLoader.webWorkerManager.initialize({
        maxWebWorkers: navigator.hardwareConcurrency || 1,
        startWebWorkersOnDemand: true,
        taskConfiguration: {
            decodeTask: {
                initializeCodecsOnStartup: false,
                usePDFJS: false,
                strict: false,
            },
        },
    });

    cornerstone.registerImageLoader('tiffile', TifRaw2FileImageLoader.loadtifImage);
    cornerstone.registerImageLoader('raw2file', TifRaw2FileImageLoader.loadtifImage);
    cornerstone.registerImageLoader('tifUri', TifRaw2UriImageLoader.loadtifImage);
    cornerstone.registerImageLoader('raw2Uri', TifRaw2UriImageLoader.loadtifImage);

    cornerstone.metaData.addProvider(wadoRsMetaDataProvider);
    cornerstone.metaData.addProvider(wadoUriMetaDataProvider);

    /* cornerstoneWADOImageLoader.webWorkerManager.initialize({
        maxWebWorkers: navigator.hardwareConcurrency || 1,
        startWebWorkersOnDemand: true,
        taskConfiguration: {
            decodeTask: {
                initializeCodecsOnStartup: false,
                usePDFJS: false,
                strict: false,
            },
        },
    }); */

    // make sure the "measurement completed" event is fired when an annotation is moved
    // in total (not by handle) so that we can store/restore this edit
    Reflect.defineProperty(BaseAnnotationTool.prototype, 'toolSelectedCallback', {
        value: function (evt: any, annotation: any, interactionType = 'mouse') {
            // @ts-ignore
            moveAnnotationWithCompletedTrigger(evt, this.name, annotation, this.options, interactionType)
        }
    });

} // initCornerstone


const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const CornerstoneElement = forwardRef((props: CornerstoneElementProps, snapshotRef: any) => {

    const { t } = useORTranslation(['ExportDialog', 'FinalizeStudyDialog', 'common', 'NavbarImage']);

    const dispatch = useAppDispatch();
    const matrixContainerRef = useRef<HTMLDivElement>(null);
    const selectedMatrixElementRef = useRef<HTMLDivElement>(null);
    const rootDivElement = useRef<HTMLDivElement>(null);
    const viewportElementCreatePreview = useRef<HTMLDivElement>(null);
    const isCornerstoneInitialized: boolean = useAppSelector(state => selectCornerstoneInitialized(state));
    const currentMatrixColumns: number | undefined = useAppSelector(state => selectMatrixColumns(state));
    const currentMatrixRows: number | undefined = useAppSelector(state => selectMatrixRows(state));
    const imageDisplayInvalidated: boolean = useAppSelector(state => getImageDisplayInvalidated(state));
    const isExportDialogVisible: number = useAppSelector((state) => selectExportDialogVisible(state));
    const isProcedureEditVisible: number = useAppSelector((state) => selectProcedureEditVisible(state));
    const isFinalizeStudyDialogVisible: number = useAppSelector((state) => selectFinalizeStudyDialogVisible(state));
    const isDicomTagBrowserVisible: boolean = useAppSelector((state) => state.DicomTagBrowser.isDicomTagBrowserVisible);
    const isMessageHistoryVisible: boolean = useAppSelector((state) => selectIsMessageHistoryVisible(state));
    const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
    const currentTheme: string = useAppSelector((state) => selectTheme(state));
    const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));
    const layout: any = useAppSelector((state) => getLayout(state));
    const isImageMaximized: boolean = useAppSelector((state) => getIsImageMaximized(state));

    const [panelWidth, setPanelWidth] = useState<number>(0);
    const [panelHeight, setPanelHeight] = useState<number>(0);

    const [panelDynamicWidth, setPanelDynamicWidth] = useState<number>(0);
    const [panelDynamicHeight, setPanelDynamicHeight] = useState<number>(0);

    useImperativeHandle(snapshotRef, () => ({
        get matrixContainer() {
            return matrixContainerRef.current;
        },
        get selectedMatrixElement() {
            return selectedMatrixElementRef.current;
        },
    }));



    const { width, height } = useResizeObserver<HTMLDivElement>({ ref: matrixContainerRef });

    if (!isCornerstoneInitialized) {
        initCornerstone();
        dispatch({ type: 'ImageDisplay/setCornerstoneInitialized', payload: true });
    }

    const handleExportDialogClose = () => {
        dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 0 });
    };

    const handleFinalizeStudyDialogClose = () => {
        dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 0 });
    };

    const handleDicomTagBrowserClose = () => {
        dispatch({ type: 'DicomTagBrowser/changeDicomTagBrowserVisible', payload: false });
    };

    const handleMessageHistoryClose = () => {
        dispatch({ type: 'Message/setMessageHistoryVisible', payload: false });
    };

    const handleProdedureEditClose = () => {
        dispatch({ type: "ImageDisplay/setProcedureEditVisible", payload: -1 });
    };

    useEffect(() => {
        const createPreviewElement: HTMLElement | null = viewportElementCreatePreview.current;

        if (createPreviewElement) {
            const options = {
                renderer: "webgl",
                desynchronized: true,
                preserveDrawingBuffer: true,
            };
            // @ts-ignore
            cornerstone.enable(createPreviewElement, options);
        }
        //if (createPreviewElement) cornerstone.enable(createPreviewElement);

        dispatch({ type: 'Orders/setImageDisplayInvalidated', payload: false });

        let parentElement = rootDivElement.current?.parentElement;

        parentElement?.setAttribute("key", "ViewerPanel_key");
        return () => {
            //TifRaw2FileImageLoader.purge();
            if (createPreviewElement) cornerstone.disable(createPreviewElement);
            dispatch({ type: "ImageDisplay/setCurrentIndex", payload: -1 });
            dispatch({ type: "ImageDisplay/setSelectedIndex", payload: -1 });
            dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 0 });
            dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 0 });
        }
    }, [dispatch, props.orderId, imageDisplayInvalidated]);


    useEffect(() => {
        const r = document.querySelector(':root');
        const elem = document.getElementById('container');

        if (r && elem) {
            const toolColor = getComputedStyle(elem)?.getPropertyValue('--toolColor');
            const toolActiveColor = getComputedStyle(elem)?.getPropertyValue('--toolActiveColor');

            if (toolColor) {
                cornerstoneTools.toolColors.setToolColor(toolColor);
            }
            if (toolActiveColor) {
                cornerstoneTools.toolColors.setActiveColor(toolActiveColor);
            }

            const toolButtonColor = getComputedStyle(elem)?.getPropertyValue('--toolButtonColor');

            if (toolButtonColor) {
                const svgFilter = getFilterForColor(toolButtonColor);
                // @ts-ignore
                r.style.setProperty('--filterToolButtonColor', svgFilter);
            }
            const toolButtonActiveColor = getComputedStyle(elem)?.getPropertyValue('--toolButtonActiveColor');
            if (toolButtonActiveColor) {
                const svgFilter = getFilterForColor(toolButtonActiveColor);
                // @ts-ignore
                r.style.setProperty('--filterToolButtonActiveColor', svgFilter);
            }
            const toolButtonTriangleColor = getComputedStyle(elem)?.getPropertyValue('--toolButtonTriangleColor');
            if (toolButtonTriangleColor) {
                const svgFilter = getFilterForColor(toolButtonTriangleColor);
                // @ts-ignore
                r.style.setProperty('--filterToolButtonTriangleColor', svgFilter);
            }
            const activeIconColor = getComputedStyle(elem)?.getPropertyValue('--activeIconColor');
            if (activeIconColor) {
                const svgFilter = getFilterForColor(activeIconColor);
                // @ts-ignore
                r.style.setProperty('--filterActiveIconColor', svgFilter);
            }
        }
    }, [currentTheme]);



    useLayoutEffect(() => {
        setPanelWidth(rootDivElement?.current?.clientWidth ?? 0);
        setPanelHeight(rootDivElement?.current?.clientHeight ?? 0);
    }, [isImageMaximized]);

    useEffect(() => {
        setPanelDynamicWidth(rootDivElement?.current?.clientWidth ?? 0);
        setPanelDynamicHeight(rootDivElement?.current?.clientHeight ?? 0);
    }, [rootDivElement?.current?.clientWidth, rootDivElement?.current?.clientHeight, width]);

    return (
        <div className="containerCornerstoneElement" ref={rootDivElement}>
            <OrderInfoPanel default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 450) / 2, width: 450, height: undefined }} orderId={props.orderId} />
            {procedureSelectionVisibilityState > 0 && layout?.showProcedureSelectionPanel ?
                <div className="canvasOverlay">
                    <BodyPartSelection orderId={props.orderId} model={props.model} />
                </div>
                : null
            }
            <div className="matrixContainer" ref={matrixContainerRef}
                style={{ gridTemplateColumns: `${100 / (currentMatrixColumns ?? 1)}% `.repeat((currentMatrixColumns ?? 1)) }}>
                {[...Array((currentMatrixColumns ?? 1) * (currentMatrixRows ?? 1))].map((x, i) =>
                    <div className="viewportElementWrapper" key={i.toString()} ref={i === selectedMatrixIndex ? selectedMatrixElementRef : null}>
                        <ViewportElement index={i} orderId={props.orderId} key={i.toString()} />
                    </div>
                )}
            </div>


            <div id={Constants.CREATE_PREVIEW_IMAGE_ELEMENT} className={Constants.CREATE_PREVIEW_IMAGE_ELEMENT} ref={viewportElementCreatePreview}
                style={{ visibility: "hidden", height: "100%", background: "rgba(0, 0, 0, 1.0)", position: "fixed", zIndex: -1, top: "-100em" }}>
                <canvas className="cornerstone-canvas" style={{ height: "100%", background: "rgba(0, 0, 0, 1.0)" }} />
            </div>

            {layout?.display_tools_in_floating_Panel && procedureSelectionVisibilityState === 0 ?
                <FloatingToolsPanel orderId={props.orderId} width={panelWidth} height={panelHeight ?? 0} dynamicWidth={panelDynamicWidth} dynamicHeight={panelDynamicHeight} />
                : null}

            <Akquisition orderId={props.orderId} />
            <CommonDialog onClose={handleExportDialogClose} title={t('imageExport')} visible={isExportDialogVisible > 0} okText={t("export")} cancelText={t("cancel", { ns: 'common' })}
                default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 700) / 2, width: 550, height: undefined }} childProps={{}}>
                <ExportDialog orderId={props.orderId} isCalledFromOrderList={false} />
            </CommonDialog>
            {false?
            <CommonDialog onOk={undefined} keepOnOK={true} onClose={handleFinalizeStudyDialogClose} title={t('FinalizeStudyDialog:finalizeStudy')} visible={isFinalizeStudyDialogVisible > 0} okText={t('FinalizeStudyDialog:finalize')} cancelText={t("cancel", { ns: 'common' })}
                default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 700) / 2, width: 550, height: undefined }} childProps={{}}>
                <FinalizeStudyDialog orderId={props.orderId} showWaitPACS={false} isCalledFromOrderList={false} />
            </CommonDialog>:null}
            <CommonDialog onClose={handleDicomTagBrowserClose} title={t('dicomTagBrowser', { ns: 'NavbarImage' })} visible={isDicomTagBrowserVisible} okText="" cancelText={t("close", { ns: 'common' })}
                default={{ x: (window.innerWidth - 700) / 20, y: (window.innerHeight - 500) / 6, width: 700, height: 400 }} childProps={{}}>
                <DicomTagBrowser />
            </CommonDialog>
            <CommonDialog onClose={handleMessageHistoryClose} title={'Messages'} visible={isMessageHistoryVisible} okText="" cancelText={t("close", { ns: 'common' })}
                default={{ x: (window.innerWidth - 700) / 20, y: (window.innerHeight - 500) / 6, width: 700, height: 500 }} childProps={{}}>
                <Messages />
            </CommonDialog>
            <CommonDialog onClose={handleProdedureEditClose} title={t('Edit procedure data', {ns: 'PreviewPanel'})} visible={isProcedureEditVisible > -1}
                okText={t('save', { ns: 'common' })}
                cancelText={t("close", { ns: 'common' })}
                default={{ x: (window.innerWidth - 600) / 20, y: (window.innerHeight - 500) / 6, width: 600, height: 250 }} childProps={{}}>
                <ProcedureDataEdit orderId={props.orderId} />
            </CommonDialog>
        </div >
    );

})

export default CornerstoneElement;
