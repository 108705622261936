import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../../store'
import { apiSlice, macro } from '../../apis/apiSlice';
import { formatISO } from 'date-fns';
import { getSelectedWorkitem } from '../OrderList/OrdersSlice';
import { t } from "../../components/Localization/ORLocalization";

export interface IProcedureSlice {
    procedureSelectionVisibilityState: number;
    selectedComponentSubtree: string[];
    selectedProcedureSettingVariantArray: string[];
    selectedXRayHelperVariantArray: string[];
    selectedGeneratorId: number;
    selectedProcedure: any | undefined;
    selectedMacro: macro | undefined;
    componentTreeVisible: boolean;
    xrayHelperVisible: boolean;
    favouriteTag: string;
    isFavoritesSelected: boolean;
}

const initialState: IProcedureSlice = {
    procedureSelectionVisibilityState: 0,
    selectedComponentSubtree: ['human', 'Adult'],
    selectedProcedureSettingVariantArray: [],
    selectedXRayHelperVariantArray: [],
    selectedGeneratorId: 14,
    selectedProcedure: undefined,
    selectedMacro: undefined,
    componentTreeVisible: false,
    xrayHelperVisible: false,
    favouriteTag: '',
    isFavoritesSelected: true,
};

const transformProtocol = (protocol: any) => {
    let ret: any = structuredClone(protocol);
    let correlationId = uuidv4();
    ret.steps = [];
    if (protocol?.steps && Array.isArray(protocol?.steps)) {
        for (const step of protocol?.steps) {
            if (step?.type === 'de.or.protocol.steps/processing' && step?.scheduled?.processing && Array.isArray(step?.scheduled?.processing)) {
                const defaultProcessing = step?.scheduled?.processing.find((processing: any) => processing.isDefault);
                if (defaultProcessing?.processingId) {
                    const newProcessingStep = { type: 'de.or.protocol.steps/processing', scheduled: { processing: defaultProcessing?.processingId } };
                    ret.steps.push({...newProcessingStep, correlationId: correlationId});
                } else {
                    ret.steps.push({...step, correlationId: correlationId});
                }
            } else {
                ret.steps.push({...step, correlationId: correlationId});
            }
        }
    }

    return ret;
}


export const setProcedure = createAsyncThunk('Procedure/setProcedure', async (args: { procedure: any, orderId: string }, thunkApi) => {
    const getState: () => RootState = thunkApi.getState;
    thunkApi.dispatch({ type: "Procedure/setSelectedProcedure", payload: args.procedure });
    thunkApi.dispatch({ type: "Procedure/setSelectedMacro", payload: undefined });
    const promise = thunkApi.dispatch(apiSlice.endpoints.getProcedureSetting.initiate({ procedureCode: args.procedure.procedureCode, variant: getState().Procedure.selectedProcedureSettingVariantArray }));
    const { data: data_procedureSetting, isSuccess: isSuccess_procedureSetting } = await promise;
    if (isSuccess_procedureSetting) {
        const indexMacro = getState().Procedure.selectedMacro?.procedureList.indexOf(args?.procedure?.procedureCode) ?? -1;
        const currentWorkitemAsString = getSelectedWorkitem(getState());
        const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
        const currentWorkitemId: string = getState()?.ImageDisplay.selectedWorkitem;

        if (indexMacro < 1) {
            if (currentWorkitem && currentWorkitemId !== undefined && currentWorkitemId !== '') {
                const newWorkitemDetails = {
                    ...currentWorkitem?.details, procedureName: args.procedure?.procedureName,
                    projection: args?.procedure?.projection, procedureCode: args?.procedure?.procedureCode,
                    targetExposureIndex: args.procedure?.targetExposureIndex, densityStep: 0, patientThickness: undefined, patientSizeIndex: 0
                }
                thunkApi.dispatch(apiSlice.endpoints.updateWorkitemDetails1.initiate(
                    {
                        workitemId: currentWorkitem?.id,
                        body: {
                            details: newWorkitemDetails
                        }
                    }
                ));

                const newProtocol = transformProtocol(data_procedureSetting?.protocol);

                thunkApi.dispatch(apiSlice.endpoints.updateWorkitemProtocol.initiate(
                    {
                        workitemId: currentWorkitem?.id,
                        body: {
                            protocol: newProtocol
                        }
                    }
                ));

                thunkApi.dispatch(
                    apiSlice.util.updateQueryData('getStudyWithWorkitems', args.orderId, (data: any) => {
                        const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                        if (currentWorkitem?.id && workitemList && workitemList.includes(currentWorkitem?.id)) {
                            const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitem?.id);
                            if (tmpWorkitem?.data) {
                                tmpWorkitem.data.details = newWorkitemDetails;
                                tmpWorkitem.data.protocol = newProtocol;
                            }
                        }
                        return data;
                    })
                );
            } else {

                const newProtocol = transformProtocol(data_procedureSetting?.protocol);
                try {
                    const newWorkitem: any = await thunkApi.dispatch(apiSlice.endpoints.putWorkitem.initiate({
                        details: {
                            procedureName: args.procedure?.procedureName,
                            projection: args?.procedure?.projection, procedureCode: args?.procedure?.procedureCode,
                            ref_study: args?.orderId, created: formatISO(new Date()),
                            catalogueId: '0', document_mime_typ: '',
                            title: args.procedure?.procedureName,
                            targetExposureIndex: args.procedure?.targetExposureIndex,
                            densityStep: 0, patientThickness: undefined, patientSizeIndex: 0,
                            desc: t(args.procedure?.procedureName + "_" + args.procedure?.procedureCode, {ns: 'Procedures'}) + " " + (args?.procedure?.projection ?? ''),
                        },
                        protocol: newProtocol
                    })).unwrap();
                    const newWorkitemId = newWorkitem?.id;
                    thunkApi.dispatch(
                        apiSlice.util.updateQueryData('getStudyWithWorkitems', args.orderId, (data: any) => {
                            const workitemList1 = data?.study?.workItems.map((id: string) => id);
                            const workitemList2 = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                            if (newWorkitemId && workitemList1 && workitemList2 && !workitemList1.includes(newWorkitemId)) {
                                data.study.workItems.push(newWorkitemId);
                                data.workitems.push({ data: newWorkitem });
                            }
                            return data;
                        })
                    )
                    //thunkApi.dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: newWorkitem?.id });
                } catch (error) {
                    console.log(error);
                }

            }
        }
    }
    promise.unsubscribe();
});

export const setMacro = createAsyncThunk('Procedure/setMacro', async (args: { macro: any, orderId: string }, thunkApi) => {
    const getState: () => RootState = thunkApi.getState;
    thunkApi.dispatch({ type: "Procedure/setSelectedMacro", payload: args?.macro });
    const promise = thunkApi.dispatch(apiSlice.endpoints.getMacroProcedures.initiate(args?.macro?.procedureList));
    const { data: data_macroProcedures, isSuccess: isSuccess_macroProcedures } = await promise;
    if (isSuccess_macroProcedures && data_macroProcedures && Array.isArray(data_macroProcedures) && data_macroProcedures.length > 0) {
        const currentWorkitemAsString = getSelectedWorkitem(getState());
        const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
        const promise_procedureSetting = thunkApi.dispatch(apiSlice.endpoints.getProcedureSetting.initiate({ procedureCode: data_macroProcedures[0].procedureCode, variant: getState().Procedure.selectedProcedureSettingVariantArray }));
        const { data: data_procedureSetting, isSuccess: isSuccess_procedureSetting } = await promise_procedureSetting;
        if (isSuccess_procedureSetting) {
            let startAddingWorkitemForMacro = 0;
            if (currentWorkitem) {
                const newWorkitemDetails = {
                    ...currentWorkitem?.details,
                    procedureCode: data_macroProcedures[0]?.procedureCode,
                    procedureName: data_macroProcedures[0]?.procedureName,
                    projection: data_macroProcedures[0]?.projection,
                    title: data_macroProcedures[0]?.procedureName,
                    desc: data_macroProcedures[0]?.procedureName + " " + data_macroProcedures[0]?.projection,
                    densityStep: 0,
                    patientThickness: undefined,
                    patientSizeIndex: 0
                }
                const newProtocol = transformProtocol(data_procedureSetting?.protocol);
                thunkApi.dispatch(apiSlice.endpoints.updateWorkitemDetails1.initiate(
                    {
                        workitemId: currentWorkitem?.id,
                        body: {
                            details: newWorkitemDetails,
                        }
                    }
                ));
                thunkApi.dispatch(apiSlice.endpoints.updateWorkitemProtocol.initiate(
                    {
                        workitemId: currentWorkitem?.id,
                        body: {
                            protocol: newProtocol
                        }
                    }
                ));
                thunkApi.dispatch(
                    apiSlice.util.updateQueryData('getStudyWithWorkitems', args.orderId, (data: any) => {
                        const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                        if (currentWorkitem?.id && workitemList && workitemList.includes(currentWorkitem?.id)) {
                            const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitem?.id);
                            if (tmpWorkitem?.data) {
                                tmpWorkitem.data.details = newWorkitemDetails;
                                tmpWorkitem.data.protocol = newProtocol;
                            }
                        }
                        return data;
                    })
                );
                startAddingWorkitemForMacro = 1;
                promise_procedureSetting.unsubscribe();
            }

            if (data_macroProcedures.length > startAddingWorkitemForMacro) {
                for (let i = startAddingWorkitemForMacro; i < data_macroProcedures.length; i++) {
                    const promise_procedureSetting = thunkApi.dispatch(apiSlice.endpoints.getProcedureSetting.initiate({ procedureCode: data_macroProcedures[i].procedureCode, variant: getState().Procedure.selectedProcedureSettingVariantArray }));
                    const { data: data_procedureSetting, isSuccess: isSuccess_procedureSetting } = await promise_procedureSetting;
                    if (isSuccess_procedureSetting) {

                        const newProtocol = transformProtocol(data_procedureSetting?.protocol);

                        try {
                            const newWorkitem: any = await thunkApi.dispatch(apiSlice.endpoints.putWorkitem.initiate({
                                details: {
                                    procedureName: data_macroProcedures[i]?.procedureName,
                                    projection: data_macroProcedures[i]?.projection, procedureCode: data_macroProcedures[i]?.procedureCode,
                                    ref_study: args?.orderId, created: formatISO(new Date()),
                                    catalogueId: '0', document_mime_typ: ''
                                },
                                protocol: newProtocol
                            })).unwrap();
                            const newWorkitemId = newWorkitem?.id;
                            thunkApi.dispatch(
                                apiSlice.util.updateQueryData('getStudyWithWorkitems', args.orderId, (data: any) => {
                                    const workitemList1 = data?.study?.workItems.map((id: string) => id);
                                    const workitemList2 = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                                    if (newWorkitemId && workitemList1 && workitemList2 && !workitemList1.includes(newWorkitemId)) {
                                        data.study.workItems.push(newWorkitemId);
                                        data.workitems.push({ data: newWorkitem });
                                    }
                                    return data;
                                })
                            )
                        } catch (error) {
                            console.log(error);
                        }
                    }
                    promise_procedureSetting.unsubscribe();
                }
            }
        }
    }
    promise.unsubscribe();
});



const ProcedureSlice = createSlice({
    name: 'Procedure',
    initialState,
    reducers: {
        setProcedureSelectionVisibilityState(state, action) {
            const procedureSelectionVisibilityState: number = action.payload;
            state.procedureSelectionVisibilityState = procedureSelectionVisibilityState;
        },
        setSelectedComponentSubtree(state, action) {
            const selectedComponentSubtree: string[] = action.payload;
            state.selectedComponentSubtree = selectedComponentSubtree;
        },
        setSelectedProcedureSettingVariantArray(state, action) {
            const selectedProcedureSettingVariantArray: string[] = action.payload;
            state.selectedProcedureSettingVariantArray = selectedProcedureSettingVariantArray;
        },
        setSelectedXRayHelperVariantArray(state, action) {
            const selectedXRayHelperVariantArray: string[] = action.payload;
            state.selectedXRayHelperVariantArray = selectedXRayHelperVariantArray;
        },
        setSelectedGeneratorId(state, action) {
            const selectedGeneratorId: number = action.payload;
            state.selectedGeneratorId = selectedGeneratorId;
        },
        setComponentTreeVisible(state, action) {
            const componentTreeVisible: boolean = action.payload;
            state.componentTreeVisible = componentTreeVisible;
        },
        setXrayHelperVisible(state, action) {
            const xrayHelperVisible: boolean = action.payload;
            state.xrayHelperVisible = xrayHelperVisible;
        },
        setSelectedProcedure(state, action) {
            const selectedProcedure: any = action.payload;
            state.selectedProcedure = selectedProcedure;
        },
        setSelectedMacro(state, action) {
            const selectedMacro: macro = action.payload;
            state.selectedMacro = selectedMacro;
        },
        setFavouriteTag(state, action) {
            const favouriteTag: string = action.payload;
            state.favouriteTag = favouriteTag;
        },
        setIsFavoritesSelected(state, action) {
            const isFavoritesSelected: boolean = action.payload;
            state.isFavoritesSelected = isFavoritesSelected;
        },
    }
});

export const { setProcedureSelectionVisibilityState, setSelectedComponentSubtree,
    setSelectedProcedureSettingVariantArray, setSelectedMacro, setFavouriteTag,
    setSelectedGeneratorId, setComponentTreeVisible, setSelectedProcedure,
    setSelectedXRayHelperVariantArray, setXrayHelperVisible, setIsFavoritesSelected } = ProcedureSlice.actions
export default ProcedureSlice.reducer;

export const selectProcedureSelectionVisibilityState = (state: RootState): number => state.Procedure.procedureSelectionVisibilityState;

export const selectedComponentSubtree = (state: RootState) => state.Procedure.selectedComponentSubtree;

export const selectedProcedureSettingVariantArray = (state: RootState) => state.Procedure.selectedProcedureSettingVariantArray;

export const selectedXRayHelperVariantArray = (state: RootState) => state.Procedure.selectedXRayHelperVariantArray;

export const selectedGeneratorId = (state: RootState) => state.Procedure.selectedGeneratorId;

export const selectXrayHelper = (state: RootState, procedureCode: string) => {
    if (procedureCode) {
        const selectXrayHelper = apiSlice.endpoints.getXrayHelperByProcedureCode.select({ procedureCode: procedureCode });
        //console.log(procedureId);
        //console.log(selectXrayHelper(state));
        return selectXrayHelper(state)?.data ?? undefined;
    } else {
        return undefined;
    }
};

export const selectedComponentTreeVisible = (state: RootState) => state.Procedure.componentTreeVisible;

export const selectedXrayHelperVisible = (state: RootState) => state.Procedure.xrayHelperVisible;

export const selectProcedure = (state: RootState) => state.Procedure.selectedProcedure;

export const selectMacro = (state: RootState) => state.Procedure.selectedMacro;

export const selectFavouriteTag = (state: RootState) => state.Procedure.favouriteTag;

export const selectIsFavoritesSelected = (state: RootState) => state.Procedure.isFavoritesSelected;
