import React, { useEffect, useRef, useState } from 'react';
import { Layout, Model, TabNode, IJsonModel, Actions, TabSetNode, Node, DockLocation, Action, BorderNode } from "flexlayout-react";
import { useLocation, useParams } from "react-router-dom";

import html2canvas from "html2canvas";
import { saveAs } from 'file-saver';

import PreviewPanel from '../Preview/PreviewPanel';
import CornerstoneElement from './CornerstoneElement';
import PreviewPanelInfo from './PreviewPanelInfo';
import OrderInfo from './OrderInfo';
import './index.scss';
import './ImageDisplay.scss';
import { ITabRenderValues } from 'flexlayout-react/declarations/view/Layout';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import Tooltabs from './Tooltabs';

import { getIsImageMaximized, getToolInsetActive, selectMatrixIndex, selectToolInsetVisible, selectToolsPanel, selectTooltabsPosition } from './ImageDisplaySlice';

import ToolsPanel from './ToolsPanel';
import { useORTranslation } from '../Localization/ORLocalization';
import { selectTheme } from '../OrderList/MainOrderListSlice';
import { selectAcquistionButtonActive, selectDeviceSets, switchDeviceset } from '../Akquisition/AcquisitionSlice';
import { getImagePathForCurrentTheme, getLayout, getSelectedWorkitem } from '../OrderList/OrdersSlice';
import ProcedureList from '../Procedures/ProcedureList';
import { selectIsFavoritesSelected, selectProcedureSelectionVisibilityState, selectedComponentSubtree } from '../Procedures/ProcedureSlice';
import AcquisitionPanel from '../Akquisition/AcquisitionPanel';


export const json: IJsonModel = {
    global: {
        tabEnableClose: false, tabSetMinHeight: 80, splitterSize: 0, tabSetHeaderHeight: 40, tabSetTabStripHeight: 42,
        tabEnableRename: false, tabEnableDrag: false, tabSetEnableDrag: false, tabSetEnableDrop: false,
        tabSetEnableDivide: false, enableEdgeDock: false, tabSetEnableMaximize: false
    },

    layout: {
        "type": "row",
        "weight": 100,
        "id": "Row",
        "children": [
            {
                "type": "tabset",
                "weight": 9,
                "selected": 0,
                "enableMaximize": false,
                "enableTabStrip": true,
                "id": "PreviewPanelTabset",
                "children": [
                    {
                        "type": "tab",
                        "name": "",
                        "className": "PreviewPanel_className",
                        "id": "PreviewPanel_id",
                        "component": "PreviewPanel",
                        "config": {
                            "font": "10"
                        }
                    }
                ],
            },
            {
                "type": "tabset",
                "weight": 0,
                "selected": 0,
                "enableMaximize": false,
                "enableDeleteWhenEmpty": false,
                "id": "ToolsTabset_Left",
                "classNameTabStrip": "ToolsTabPanelTabStrip",
                "children": [],
            },
            {
                "type": "tabset",
                "weight": 91,
                "selected": 0,
                "id": "ImageTabset",
                "classNameTabStrip": "ImagePanelTabStrip",
                "enableDivide": true,
                "children": [
                    {
                        "type": "tab",
                        "className": "ImageTab",
                        "name": '',
                        "component": "Image"
                    }
                ],
            },
            {
                "type": "tabset",
                "weight": 0,
                "selected": 0,
                "enableMaximize": false,
                "enableClose": false,
                "enableDeleteWhenEmpty": false,
                "id": "ToolsTabset_Right",
                "classNameTabStrip": "ToolsTabPanelTabStrip",
                "children": [],
            },
            {
                "type": "tabset",
                "weight": 0,
                "selected": 0,
                "enableMaximize": false,
                "enableClose": false,
                "enableDeleteWhenEmpty": false,
                "id": "Procedures",
                "classNameTabStrip": "ProceduresTabStrip",
                "children": [],
            },
            {
                "type": "tabset",
                "weight": 0,
                "selected": 0,
                "enableClose": false,
                "enableDrag": true,
                "enableDrop": true,
                "enableDivide": true,
                "id": "Tools-Tabset",
                "classNameTabStrip": "ToolsPanelTabStrip",
                "children": [
                    {
                        "type": "tab",
                        "name": "",
                        "component": "ToolsPanel",
                        "id": "ToolsPanel"
                    }
                ]
            },
        ]
    }
};


export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const Viewer = () => {

    const [model, setModel] = useState<Model>(Model.fromJson(json));

    const r = document.querySelector(':root');
    let weightPreviewPanelFromCss: number | undefined = undefined;
    const elem = document.getElementById('container');
    if (r && elem) {
        const weightPreviewPanelFromCssString = getComputedStyle(elem)?.getPropertyValue('--previePanelSetWidth_Percent');
        weightPreviewPanelFromCss = Number(weightPreviewPanelFromCssString);
        //console.log("weightPreviewPanelFromCss 0: " + weightPreviewPanelFromCss);
    }

    const [weightPreviewPanel, setWeightPreviewPanel] = useState<number>(weightPreviewPanelFromCss ?? 9);
    const [weightImagePanel, setWeightImagePanel] = useState<number>(weightPreviewPanelFromCss ? (100 - weightPreviewPanelFromCss) : 91);
    const [weightToolsPanel, setWeightToolsPanel] = useState<number>(7.7);

    const weightPreviewPanelRef = useRef<number>();
    weightPreviewPanelRef.current = weightPreviewPanel;
    const weightImagePanelRef = useRef<number>();
    weightImagePanelRef.current = weightImagePanel;

    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['ExportDialog', 'common']);

    const { id } = useParams<string>();
    const snapshotRef = useRef<any>(null);
    const LayoutRef = useRef<Layout>(null);

    const query = useQuery();
    const deviceRunnerPath: string | null = query?.get("device-runner");

    const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
    const isToolInsetVisible: boolean | undefined = useAppSelector((state) => selectToolInsetVisible(state));
    const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));
    const isToolInsetActive: boolean | undefined = useAppSelector((state) => getToolInsetActive(state, selectedMatrixIndex ?? 0));
    const isAcquisitionButtonActive: boolean = useAppSelector((state) => selectAcquistionButtonActive(state)) ?? false;
    const tooltabsPosition: string = useAppSelector((state) => selectTooltabsPosition(state));
    const isToolsPanel: boolean = useAppSelector((state) => selectToolsPanel(state));
    const currentTheme: string = useAppSelector((state) => selectTheme(state));
    const layout: any = useAppSelector((state) => getLayout(state));
    const currentComponentSubtree: string[] = useAppSelector((state) => selectedComponentSubtree(state));
    const deviceSetsString: string = useAppSelector((state) => selectDeviceSets(state));
    const deviceSets: any[] = JSON.parse(deviceSetsString);
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const isImageMaximized: boolean = useAppSelector((state) => getIsImageMaximized(state));
    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
    const isFavoritesSelected = useAppSelector((state) => selectIsFavoritesSelected(state));


    const { language } = useORTranslation([]);

    const tooltabsPositionRef = useRef<string>();
    tooltabsPositionRef.current = tooltabsPosition;

    const layoutRef = useRef<any>();
    layoutRef.current = layout;

    const factory = (node: TabNode) => {
        var component = node.getComponent();
        if (component === "text") {
            return (<div className="panel">Panel {node.getName()}</div>);
        } else if (component === "Image") {
            return (
                <CornerstoneElement ref={snapshotRef} orderId={id ?? ''} model={model} />
            );
        } else if (component === "ToolsPanel") {
            return (
                <ToolsPanel orderId={id ?? ''} />
            );
        } else if (component === "PreviewPanel") {
            return (
                <PreviewPanel orderId={id ?? ''} />
            );
        } else if (component === "ToolsTabs") {
            return (<div className='toolTabs_Panel'><Tooltabs orderId={id ?? ''} matrixIndex={selectedMatrixIndex ?? 0} /></div>);
        } else if (component === "ProcedureSelection") {
            return (<ProcedureList showFavourites={false} orderId={id ?? ''} />);
        }
        else if (component === "Favourites") {
            return (<ProcedureList showFavourites={true} orderId={id ?? ''} />);
        }
        else if (component?.startsWith("AcquisitionPanel_")) {
            if (component.split('_')?.length > 1) {
                const deviceSetId: number = parseInt(component.split('_')[1]);
                if (!isNaN(deviceSetId) && deviceSets !== undefined) {
                    const currentDeviceSet = deviceSets?.find((deviceSet: any) => deviceSet?.data?.deviceSet?.id === deviceSetId)?.data?.deviceSet;
                    return (<AcquisitionPanel orderId={id ?? ''} deviceSet={currentDeviceSet} />);
                }
            }
            return null;

        }
    }

    const onRenderTab = (node: TabNode, renderValues: ITabRenderValues) => {
        if (node.getComponent() === "PreviewPanel") {
            renderValues.buttons.push(<PreviewPanelInfo orderId={id ?? ''} key="PreviewPanelInfo" />);
        }

        /* if (node.getComponent() === "Image") {
            renderValues.buttons.push(<Tools node={node} orderId={id ?? ''} key="Tools"/>);
        } */

        if (node.getComponent() === "Image") {
            renderValues.buttons.push(<OrderInfo onSnapshotClick={exportAsImage} orderId={id ?? ''} key="OrderInfo" />);
        }
        if (node.getComponent() === "ToolsTabs") {
            renderValues.buttons.push(<img className='toolIcon' src='/images/bar-chart-analysis-svgrepo-com.svg' alt='/images/bar-chart-analysis-svgrepo-com.svg' key="toolscharts" />);
        }
        if (node.getComponent() === "ProcedureSelection") {
            renderValues.buttons.push(
                <span className='procedureSelectionTabsetHeader' key="procedureSelectionTabsetHeader" >
                    <img className="toolIcon" src={`${imagePathForCurrentTheme}ribs-x-rays-svgrepo-com.svg`}
                        onError={(event: any) => { event.target.src = "/images/ribs-x-rays-svgrepo-com.svg"; event.onerror = null }}
                        alt="fribs-x-rays-svgrepo-com.svg" />
                    {!isFavoritesSelected ?
                        <label>{`${!layoutRef?.current?.hideBodyText && currentComponentSubtree !== undefined && currentComponentSubtree.length >= 2 ? currentComponentSubtree[1] : ''} ${!layoutRef?.current?.hideBodyText ? '-' : ''} ${currentComponentSubtree !== undefined && currentComponentSubtree.length >= 3 ? t(currentComponentSubtree[2], {ns: 'Procedures'}) : ''}`}</label>
                        : null}
                </span>
            );
        }
        if (node.getComponent() === "Favourites") {
            renderValues.buttons.push(
                <span className='procedureSelectionTabsetHeader_favorites' key="procedureSelectionTabsetHeader_favorites" >
                    <img className="toolIcon" src={`${imagePathForCurrentTheme}favorites-svgrepo-com.svg`}
                        onError={(event: any) => { event.target.src = "/images/favorites-svgrepo-com.svg"; event.onerror = null }}
                        alt="favorites-svgrepo-com.svg" />
                </span>
            );
        }

        if (node.getComponent()?.startsWith("AcquisitionPanel_")) {
            if (node.getComponent() && node.getComponent()!.split('_')?.length > 1) {
                const deviceSetId: number = parseInt(node.getComponent()!.split('_')[1]);
                if (!isNaN(deviceSetId) && deviceSets !== undefined) {
                    const currentDeviceSetAll = deviceSets?.find((deviceSet: any) => deviceSet?.data?.deviceSet?.id === deviceSetId);
                    const currentDeviceSet = currentDeviceSetAll?.data?.deviceSet;
                    const readiness = currentDeviceSetAll?.data?.deviceWebThingProps?.readiness;
                    renderValues.buttons.push(
                        <span className="currentDeviceSet" key={"DeviceSet" + deviceSetId} id={currentTheme}>
                            <span className="currentDeviceSetIconWrapper" id={readiness}>
                                <img className="currentDeviceSetIcon" src={currentDeviceSet?.title === 'Table' ? `${imagePathForCurrentTheme}table-svgrepo-com2.svg` : `${imagePathForCurrentTheme}table-svgrepo-com3.svg`}
                                    onError={(event: any) => { event.target.src = currentDeviceSet?.title === 'Table' ? "/images/table-svgrepo-com2.svg" : "/images/table-svgrepo-com3.svg"; event.onerror = null }}
                                    alt="table-svgrepo-com2.svg" />
                            </span>
                            <span className="currentDeviceSetIconWrapper_noColor">
                                <img className="currentDeviceSetIcon" src={currentDeviceSet?.title === 'Table' ? `${imagePathForCurrentTheme}table-svgrepo-com2.svg` : `${imagePathForCurrentTheme}table-svgrepo-com3.svg`}
                                    onError={(event: any) => { event.target.src = currentDeviceSet?.title === 'Table' ? "/images/table-svgrepo-com2.svg" : "/images/table-svgrepo-com3.svg"; event.onerror = null }}
                                    alt="table-svgrepo-com2.svg" />
                            </span>
                            <label className='currentDeviceSetLabel' id={readiness} key={"DeviceSetLabel" + deviceSetId}>{t(currentDeviceSet?.title, {ns: 'Acquisition'})}</label>
                        </span>
                    );
                }
            }
        }
    }

    /* const onRenderTabSet = (node: TabSetNode | BorderNode, renderValues: ITabSetRenderValues) => {
        if (node.getId() === 'ImageTabset') {
            //console.log(node);
            //console.log(renderValues);
            const rect = node?.getRect();
            if (rect.width > 0 && rect.height > 0 ) {
                dispatch({ type: "ImageDisplay/setImageTabSize", payload: { width: rect?.width, height: rect?.height } });
            }
        }
    } */

    const onToolsTabsClose = (node: any) => {
        dispatch({ type: "ImageDisplay/setToolInsetVisible", payload: false });
    }

    const onToolsTabsResize = (node: any) => {
        // if toolsTabs are visible shift XRayButton to the right
        let r = document.querySelector(':root');
        if (r) {
            switch (tooltabsPositionRef.current) {
                case 'left':
                    // @ts-ignore
                    r.style.setProperty('--XRayButtonSVG_inactive_shift', `${node?.rect?.width > 0 ? node?.rect?.width : 0}px`);
                    break;
                case 'right':
                    // @ts-ignore
                    r.style.setProperty('--tools_shift', `${node?.rect?.width > 0 ? node?.rect?.width : 0}px`);
                    break;
                case 'bottom':
                    // @ts-ignore
                    r.style.setProperty('--xRayButton_shift', `${(node?.rect?.height + model?.getActiveTabset()?.getTabStripHeight()) > 0 ? node?.rect?.height + model?.getActiveTabset()?.getTabStripHeight() : 0}px`);
                    break;
                case 'top':
                    // @ts-ignore
                    r.style.setProperty('--tools_offset', `${(node?.rect?.height + model?.getActiveTabset()?.getTabStripHeight()) > 0 ? node?.rect?.height + model?.getActiveTabset()?.getTabStripHeight() : 0}px`);
                    break;
                default:
                    console.log("tooltabsPosition " + tooltabsPosition + " not found");
            }
        }
    }

    const exportAsImage = async (imageFileName: string) => {
        if (snapshotRef?.current?.matrixContainer) {
            const canvas = await html2canvas(snapshotRef.current.matrixContainer);
            const blob: Blob | null = await new Promise(res => canvas?.toBlob(res));
            if (blob) {
                saveAs(blob, imageFileName);
            }
        }
    };


    useEffect(() => {
        if (deviceRunnerPath && deviceRunnerPath.length > 0) {
            const proto: string = (window.location.protocol === 'https:') ? 'https://' : 'http://';
            const connection: string = proto + deviceRunnerPath;
            dispatch({ type: "Acquisition/setDeviceRunnerPath", payload: connection });
            dispatch({ type: "Acquisition/setDeviceRunnerActive", payload: true });
        }
    }, [deviceRunnerPath, dispatch]);

    useEffect(() => {
        if (isAcquisitionButtonActive) {
            onToolsTabsClose(null);
        }
    }, [isAcquisitionButtonActive]);

    useEffect(() => {
        dispatch({ type: "ImageDisplay/setToolInsetVisible", payload: false });
    }, [dispatch, id]);

    useEffect(() => {
        if (!isToolInsetActive) {
            dispatch({ type: "ImageDisplay/setToolInsetVisible", payload: false });
        }
    }, [isToolInsetActive]);

    useEffect(() => {
        const previewPanelTabset = model.getNodeById("PreviewPanelTabset") as TabSetNode;
        const toolsTabset_Left = model.getNodeById("ToolsTabset_Left") as TabSetNode;
        const toolsTabset_Right = model.getNodeById("ToolsTabset_Right") as TabSetNode;

        const toolsWidthString = layout?.tools_width;
        const toolsMarginString = layout?.tools_margin_right;
        const toolsWidth = parseFloat(toolsWidthString);
        const toolsMargin = parseFloat(toolsMarginString);

        //resetLayout(isToolsPanel);
        //model.doAction(Actions.updateNodeAttributes("Tools-Tabset", { weight: isToolsPanel ? 7.7 : 0.0 }));
        //model.doAction(Actions.updateNodeAttributes("Tools-Tabset", { width: isToolsPanel ? (variables?.Tools_width ?  parseFloat(variables?.Tools_width) : 0.0) : 0.0 }));
        model.doAction(Actions.updateNodeAttributes("Tools-Tabset", { width: isToolsPanel ? ((toolsWidth ?? 0) + (toolsMargin ?? 0)) : 0.0 }));
        model.doAction(Actions.updateNodeAttributes("PreviewPanelTabset", { weight: weightPreviewPanel }));
        model.doAction(Actions.updateNodeAttributes("ImageTabset", {
            weight: 100 - previewPanelTabset?.getWeight()
                - toolsTabset_Left?.getWeight() - toolsTabset_Right?.getWeight() - (isToolsPanel ? 7.7 : 0.0)
        }));


        /* if (isToolsPanel) {
            dispatch({ type: "Overview/changeOverviewVisible", payload: false });
        } */
    }, [dispatch, isToolsPanel, model, weightImagePanel]);

    useEffect(() => {
        model.doAction(Actions.renameTab("ToolsPanel", `${t('ToolsPanel', { ns: 'Tools' })}`));
    }, [language]);

    useEffect(() => {

        if (layout) {
            const r = document.querySelector(':root');
            const elem = document.getElementById('container');
            if (r && elem) {
                const weightPreviewPanelFromCss = getComputedStyle(elem)?.getPropertyValue('--previePanelSetWidth_Percent');
                setWeightPreviewPanel(Number(weightPreviewPanelFromCss));
                setWeightImagePanel(100 - Number(weightPreviewPanelFromCss));
            }

            const toolsTabset_Left = model.getNodeById("ToolsTabset_Left") as TabSetNode;
            const toolsTabset_Right = model.getNodeById("ToolsTabset_Right") as TabSetNode;
            const toolsWidthString = layout?.tools_width;
            const toolsMarginString = layout?.tools_margin_right;
            const toolsWidth = parseFloat(toolsWidthString);
            const toolsMargin = parseFloat(toolsMarginString);
            model.doAction(Actions.updateNodeAttributes("Tools-Tabset", { width: isToolsPanel ? ((toolsWidth ?? 0) + (toolsMargin ?? 0)) : 0.0 }));
            model.doAction(Actions.updateNodeAttributes("PreviewPanelTabset", { weight: Number(weightPreviewPanelFromCss) }));
            model.doAction(Actions.updateNodeAttributes("ImageTabset", {
                weight: (100 - Number(weightPreviewPanelFromCss))
                    - toolsTabset_Left?.getWeight() - toolsTabset_Right?.getWeight() - (layout?.dockToolsPanel ? 7.7 : 0.0)
            }));

            const tabSetTabStripHeight = layout?.tabSetTabStripHeight;
            const tabSetHeaderHeight = layout?.tabSetHeaderHeight;
            const imagePanelEnableMaximize = layout?.imagePanelEnableMaximize ?? false;
            model.doAction(Actions.updateModelAttributes({ tabSetTabStripHeight: tabSetHeaderHeight }));
            model.doAction(Actions.updateModelAttributes({ tabSetTabStripHeight: tabSetTabStripHeight }));
            model.doAction(Actions.updateNodeAttributes("ImageTabset", { enableMaximize: (procedureSelectionVisibilityState !== undefined && procedureSelectionVisibilityState === 0) ? imagePanelEnableMaximize : false }));
        }
    }, [currentTheme, layout]);

    useEffect(() => {
        if (layout) {
            const imagePanelEnableMaximize = layout?.imagePanelEnableMaximize ?? false;
            model.doAction(Actions.updateNodeAttributes("ImageTabset", { enableMaximize: (procedureSelectionVisibilityState !== undefined && procedureSelectionVisibilityState === 0) ? imagePanelEnableMaximize : false }));
        }
    }, [procedureSelectionVisibilityState, currentTheme]);

    // open tabset for tool context panel
    useEffect(() => {
        const onToolsTabsetClose = (node: any) => {
            resetLayout(isToolsPanel);
        }

        if (isToolInsetVisible) {
            let r = document.querySelector(':root');
            if (isToolInsetActive) {
                //const toolsTabsNode = addToolTabsPanel();
                let toolsTabsNode: Node | undefined = model.getNodeById("ToolsTabs");
                switch (tooltabsPosition) {
                    case 'left':
                        if (!toolsTabsNode) toolsTabsNode = model.doAction(Actions.addNode(
                            { type: "tab", component: "ToolsTabs", name: "", id: "ToolsTabs", enableClose: true, className: "toolTabs", enableDrop: true, enableDrag: true, enableDivide: true },
                            "ToolsTabset_Left", DockLocation.CENTER, 0));
                        model.doAction(Actions.updateNodeAttributes("ToolsTabset_Left", { weight: layoutRef?.current?.toolContextPanelWeight ?? 25 }));
                        //model.doAction(Actions.updateNodeAttributes("ImageTabset", { weight: weightImagePanel - layoutRef?.current?.toolContextPanelWeight ?? 25 }));
                        model.doAction(Actions.updateNodeAttributes("ImageTabset", { weight: (weightImagePanelRef.current ?? 91) - (layoutRef?.current?.toolContextPanelWeight ?? 25) - (isToolsPanel ? weightToolsPanel : 0.0) }));
                        // @ts-ignore
                        r?.style.setProperty('--splitter_size_left', `${4}px`);
                        break;
                    case 'right':
                        if (!toolsTabsNode) toolsTabsNode = model.doAction(Actions.addNode(
                            { type: "tab", component: "ToolsTabs", name: "", id: "ToolsTabs", enableClose: true, className: "toolTabs", enableDrop: true, enableDrag: true, enableDivide: true },
                            "ToolsTabset_Right", DockLocation.CENTER, 0));
                        model.doAction(Actions.updateNodeAttributes("ToolsTabset_Right", { weight: layoutRef?.current?.toolContextPanelWeight ?? 25 }));
                        //model.doAction(Actions.updateNodeAttributes("ImageTabset", { weight: weightPreviewPanel - layoutRef?.current?.toolContextPanelWeight ?? 25 }));
                        model.doAction(Actions.updateNodeAttributes("ImageTabset", { weight: (weightImagePanelRef.current ?? 91) - (layoutRef?.current?.toolContextPanelWeight ?? 25) - (isToolsPanel ? weightToolsPanel : 0.0) }));

                        // @ts-ignore
                        r?.style.setProperty('--splitter_size_right', `${4}px`);
                        break;
                    case 'bottom':
                        if (!toolsTabsNode) toolsTabsNode = model.doAction(Actions.addNode(
                            { type: "tab", component: "ToolsTabs", name: "", id: "ToolsTabs", enableClose: true, className: "toolTabs", enableDrop: true, enableDrag: true, enableDivide: true },
                            "ToolsTabset_Right", DockLocation.CENTER, 0));
                        model.doAction(Actions.moveNode("ToolsTabs", "ImageTabset", DockLocation.BOTTOM, 0));
                        const newTabsetNode: TabSetNode = toolsTabsNode?.getParent() as TabSetNode;
                        if (newTabsetNode) {
                            model.doAction(Actions.updateNodeAttributes(newTabsetNode.getId(), { weight: layoutRef?.current?.toolContextPanelWeight ?? 25, "enableMaximize": false, "classNameTabStrip": 'ToolsTabStrip_Bottom' }));
                        }
                        // @ts-ignore
                        r.style.setProperty('--splitter_size_bottom', `${4}px`);
                        break;
                    case 'top':
                        if (!toolsTabsNode) toolsTabsNode = model.doAction(Actions.addNode(
                            { type: "tab", component: "ToolsTabs", name: "", id: "ToolsTabs", enableClose: true, className: "toolTabs", enableDrop: true, enableDrag: true, enableDivide: true },
                            "ToolsTabset_Right", DockLocation.CENTER, 0));
                        model.doAction(Actions.moveNode("ToolsTabs", "ImageTabset", DockLocation.TOP, 0));
                        const newTabsetNode_top: TabSetNode = toolsTabsNode?.getParent() as TabSetNode;
                        if (newTabsetNode_top) {
                            model.doAction(Actions.updateNodeAttributes(newTabsetNode_top.getId(), { weight: layoutRef?.current?.toolContextPanelWeight ?? 25, "enableMaximize": false, "classNameTabStrip": 'ToolsTabStrip_Bottom' }));
                        }
                        // @ts-ignore
                        r.style.setProperty('--splitter_size_bottom', `${4}px`);
                        break;
                    default:
                        console.log("tooltabsPosition " + tooltabsPosition + " not found");
                }

                toolsTabsNode?.setEventListener("close", onToolsTabsClose);
                toolsTabsNode?.setEventListener("resize", onToolsTabsResize);
            }
        } else {
            onToolsTabsetClose(undefined);
        }

        return () => {
            const toolsTabsNode: Node | undefined = model.getNodeById("ToolsTabs");
            toolsTabsNode?.removeEventListener("close");
            toolsTabsNode?.removeEventListener("resize");
        }
    }, [isToolInsetVisible]);

    useEffect(() => {
        if (procedureSelectionVisibilityState === 1 && layout?.showProcedureSelectionPanel) {
            resetLayout(isToolsPanel);

            model.doAction(Actions.deleteTab("ToolsTabs"));
            for (let i = 0; i < deviceSets?.length; i++) {
                model.doAction(Actions.deleteTab("AcquisitionPanel_" + (deviceSets[i]?.data?.deviceSet?.id).toString()));
            }

            model.doAction(Actions.moveNode("ToolsTabset_Right", "Row", DockLocation.RIGHT, 0));
            model.doAction(Actions.updateNodeAttributes("ToolsTabset_Right", { weight: 0, }));


            let r = document.querySelector(':root');

            let procedureSelectionNode: Node | undefined = model.getNodeById("ProcedureSelection");
            if (!procedureSelectionNode) procedureSelectionNode = model.doAction(Actions.addNode(
                { type: "tab", component: "ProcedureSelection", name: "", id: "ProcedureSelection", enableClose: true, className: "toolTabs", enableDrop: true, enableDrag: true, enableDivide: true },
                "Procedures", DockLocation.CENTER, -1, false));

            let favouritesNode: Node | undefined = model.getNodeById("Favourites");
            if (!favouritesNode) favouritesNode = model.doAction(Actions.addNode(
                { type: "tab", component: "Favourites", name: "", id: "Favourites", enableClose: true, className: "toolTabs", enableDrop: true, enableDrag: true, enableDivide: true },
                "Procedures", DockLocation.CENTER, -1, true));

            model.doAction(Actions.updateNodeAttributes("Tools-Tabset", { width: 0.0 }));

            model.doAction(Actions.updateNodeAttributes("Procedures", { weight: layoutRef?.current?.toolContextPanelWeight ?? 25 }));
            model.doAction(Actions.updateNodeAttributes("ImageTabset", { weight: (weightImagePanelRef.current ?? 91) - (layoutRef?.current?.toolContextPanelWeight ?? 25) - (isToolsPanel ? weightToolsPanel : 0.0) }));


            // @ts-ignore
            r?.style.setProperty('--splitter_size_right', `${4}px`);
            procedureSelectionNode?.setEventListener("close", onToolsTabsClose);
            procedureSelectionNode?.setEventListener("resize", onToolsTabsResize);
            favouritesNode?.setEventListener("close", onToolsTabsClose);
            favouritesNode?.setEventListener("resize", onToolsTabsResize);
        } else if (procedureSelectionVisibilityState === 2 && layout?.showProcedureSelectionPanel) {
            if (model.getNodeById("ProcedureSelection") === undefined) {
                resetLayout(isToolsPanel);
                model.doAction(Actions.moveNode("ToolsTabset_Right", "Row", DockLocation.RIGHT, 0));
                model.doAction(Actions.updateNodeAttributes("ToolsTabset_Right", { weight: 0, }));
            }

            model.doAction(Actions.deleteTab("ProcedureSelection"));
            model.doAction(Actions.deleteTab("Favourites"));

            let r = document.querySelector(':root');


            for (let i = 0; i < deviceSets?.length; i++) {
                if (deviceSets[i]?.data?.deviceSet?.config?.default) {
                    //dispatch({ type: "Acquisition/setSelectedDeviceSetId", payload: deviceSets[i]?.data?.deviceSet?.id });
                    dispatch(switchDeviceset({ devicedetId: deviceSets[i]?.data?.deviceSet?.id, procedureCode: currentWorkitem?.details?.procedureCode }));
                }

                let acquisitionPanelNode: Node | undefined = model.getNodeById("AcquisitionPanel_" + (deviceSets[i]?.data?.deviceSet?.id).toString());
                if (!acquisitionPanelNode) acquisitionPanelNode = model.doAction(Actions.addNode(
                    { type: "tab", component: "AcquisitionPanel_" + (deviceSets[i]?.data?.deviceSet?.id).toString(), name: "", id: "AcquisitionPanel_" + (deviceSets[i]?.data?.deviceSet?.id).toString(), enableClose: false, className: "toolTabs", enableDrop: true, enableDrag: true, enableDivide: true },
                    "Procedures", DockLocation.CENTER, -1, deviceSets[i]?.data?.deviceSet?.config?.default));

                acquisitionPanelNode?.setEventListener("close", onToolsTabsClose);
                acquisitionPanelNode?.setEventListener("resize", onToolsTabsResize);
            }

            model.doAction(Actions.updateNodeAttributes("Tools-Tabset", { width: 0.0 }));

            if (model.getNodeById("ProcedureSelection") === undefined) {
                model.doAction(Actions.updateNodeAttributes("Procedures", { weight: layoutRef?.current?.toolContextPanelWeight ?? 25 }));
                model.doAction(Actions.updateNodeAttributes("ImageTabset", { weight: (weightImagePanelRef.current ?? 91) - (layoutRef?.current?.toolContextPanelWeight ?? 25) - (isToolsPanel ? weightToolsPanel : 0.0) }));
            }

            // @ts-ignore
            r?.style.setProperty('--splitter_size_right', `${4}px`);


        } else {
            model.doAction(Actions.deleteTab("ProcedureSelection"));
            model.doAction(Actions.deleteTab("Favourites"));
            for (let i = 0; i < deviceSets?.length; i++) {
                model.doAction(Actions.deleteTab("AcquisitionPanel_" + (deviceSets[i]?.data?.deviceSet?.id).toString()));
            }
            model.doAction(Actions.moveNode("Procedures", "Row", DockLocation.RIGHT, 0));
            model.doAction(Actions.moveNode("Tools-Tabset", "Row", DockLocation.RIGHT, 0));
            resetLayout(isToolsPanel);
        }
        return () => {
            const toolsWidthString = layout?.tools_width;
            const toolsMarginString = layout?.tools_margin_right;
            const toolsMargin = parseFloat(toolsMarginString);
            const toolsWidth = parseFloat(toolsWidthString);
            model.doAction(Actions.updateNodeAttributes("Tools-Tabset", { width: isToolsPanel ? ((toolsWidth ?? 0) + (toolsMargin ?? 0)) : 0.0 }));
            if (procedureSelectionVisibilityState === 1 && layout?.showProcedureSelectionPanel) {
                const procedureSelectionNode: Node | undefined = model.getNodeById("ProcedureSelection");
                const favouritesNode: Node | undefined = model.getNodeById("Favourites");
                procedureSelectionNode?.removeEventListener("close");
                procedureSelectionNode?.removeEventListener("resize");
                favouritesNode?.removeEventListener("close");
                favouritesNode?.removeEventListener("resize");
            } else if (procedureSelectionVisibilityState === 2 && layout?.showProcedureSelectionPanel) {
                for (let i = 0; i < deviceSets?.length; i++) {
                    const acquisitionPanelNode: Node | undefined = model.getNodeById("AcquisitionPanel_" + (deviceSets[i]?.data?.deviceSet?.id).toString());
                    acquisitionPanelNode?.removeEventListener("close");
                    acquisitionPanelNode?.removeEventListener("resize");
                }
            }
        }

    }, [procedureSelectionVisibilityState, layout?.showProcedureSelectionPanel, deviceSets?.length])


    useEffect(() => {
        if (currentComponentSubtree !== undefined && currentComponentSubtree.length > 2) {
            model.doAction(Actions.updateNodeAttributes("Procedures", { selected: 0 }));
        } else {
            model.doAction(Actions.updateNodeAttributes("Procedures", { selected: 1 }));
        }
    }, [currentComponentSubtree]);

    /* useEffect(() => {
        if (procedureSelectionVisibilityState > 0) {
            model.doAction(Actions.updateNodeAttributes("Procedures", { selected: 1 }));
            console.log(procedureSelectionVisibilityState);
        }
    }, [procedureSelectionVisibilityState]); */

    const onAction = (action: Action) => {
        if (action.type === 'FlexLayout_MaximizeToggle') {
            dispatch({ type: "ImageDisplay/setIsImageMaximized", payload: !isImageMaximized });
        }
        if (action.type === 'FlexLayout_MoveNode') {
            if (action?.data?.fromNode === 'ToolsTabs' && action?.data?.toNode === 'ImageTabset' &&
                action?.data?.location === 'left' && tooltabsPositionRef.current !== 'left') {
                const toolsTabsNode: Node | undefined = model.getNodeById("ToolsTabs");
                const toolsTabsTabset_left: TabSetNode | undefined = model.getNodeById("ToolsTabset_Left") as TabSetNode;
                const toolsTabsTabset_right: TabSetNode | undefined = model.getNodeById("ToolsTabset_Right") as TabSetNode;
                const currentWeight: number = toolsTabsTabset_right.getWeight();
                if (toolsTabsNode && toolsTabsTabset_left && toolsTabsTabset_right) {
                    //resetLayout();
                    resetLayout(isToolsPanel);
                    const r = document.querySelector(':root');
                    if (r) {
                        // @ts-ignore
                        r.style.setProperty('--splitter_size_left', `${4}px`);
                    }
                    model.doAction(Actions.updateNodeAttributes("ToolsTabset_Left", { weight: currentWeight ? currentWeight : layoutRef?.current?.toolContextPanelWeight ?? 25 }));
                    if (toolsTabsTabset_left.getChildren() === undefined || toolsTabsTabset_left.getChildren().length === 0) {
                        model.doAction(Actions.moveNode("ToolsTabs", "ToolsTabset_Left", DockLocation.CENTER, 0));
                    }
                    model.doAction(Actions.updateNodeAttributes("ImageTabset", { weight: (weightImagePanelRef.current ?? 91) - (isToolsPanel ? weightToolsPanel : 0.0) - (currentWeight ? currentWeight : layoutRef?.current?.toolContextPanelWeight ?? 25) }));
                    dispatch({ type: "ImageDisplay/setTooltabsPosition", payload: 'left' });
                }
                return undefined;
            } else if (action?.data?.fromNode === 'ToolsTabs' && action?.data?.toNode === 'ImageTabset' &&
                action?.data?.location === 'right' && tooltabsPositionRef.current !== 'right') {
                const toolsTabsNode: Node | undefined = model.getNodeById("ToolsTabs");
                const toolsTabsTabset_left: TabSetNode | undefined = model.getNodeById("ToolsTabset_Left") as TabSetNode;
                const toolsTabsTabset_right: TabSetNode | undefined = model.getNodeById("ToolsTabset_Right") as TabSetNode;
                const currentWeight: number = toolsTabsTabset_left.getWeight();
                if (toolsTabsNode && toolsTabsTabset_left && toolsTabsTabset_right) {
                    resetLayout(isToolsPanel);
                    const r = document.querySelector(':root');
                    if (r) {
                        // @ts-ignore
                        r.style.setProperty('--splitter_size_right', `${4}px`);
                    }
                    model.doAction(Actions.updateNodeAttributes("ToolsTabset_Right", { weight: currentWeight ? currentWeight : layoutRef?.current?.toolContextPanelWeight ?? 25 }));
                    if (toolsTabsTabset_right.getChildren() === undefined || toolsTabsTabset_right.getChildren().length === 0) {
                        model.doAction(Actions.moveNode("ToolsTabs", "ToolsTabset_Right", DockLocation.CENTER, 0));
                    }

                    model.doAction(Actions.updateNodeAttributes("ImageTabset", { weight: (weightImagePanelRef.current ?? 91) - (isToolsPanel ? weightToolsPanel : 0.0) - (currentWeight ? currentWeight : layoutRef?.current?.toolContextPanelWeight ?? 25) }));
                    dispatch({ type: "ImageDisplay/setTooltabsPosition", payload: 'right' });
                }
                return undefined;

            } else if (action?.data?.fromNode === 'ToolsTabs' && action?.data?.toNode === 'ImageTabset' &&
                action?.data?.location === 'bottom' && tooltabsPositionRef.current !== 'bottom') {
                const toolsTabsNode: Node | undefined = model.getNodeById("ToolsTabs");
                if (toolsTabsNode) {
                    //resetLayout();
                    resetLayout(isToolsPanel);
                    const r = document.querySelector(':root');
                    if (r) {
                        // @ts-ignore
                        r.style.setProperty('--splitter_size_bottom', `${4}px`);
                    }
                    model.doAction(Actions.moveNode("ToolsTabs", "ImageTabset", DockLocation.BOTTOM, 0));
                    const newNode: Node | undefined = model.getNodeById("ToolsTabs");
                    const newTabsetNode: TabSetNode = newNode?.getParent() as TabSetNode;
                    if (newTabsetNode) {
                        model.doAction(Actions.updateNodeAttributes(newTabsetNode.getId(), {
                            weight: layoutRef?.current?.toolContextPanelWeight ?? 25, "enableMaximize": false,
                            "classNameTabStrip": 'ToolsTabStrip_Bottom', "classNameHeader": 'ToolsTabHeader_Bottom'
                        }));
                    }
                    dispatch({ type: "ImageDisplay/setTooltabsPosition", payload: 'bottom' });
                }
                return undefined;

            } else if (action?.data?.fromNode === 'ToolsTabs' && action?.data?.toNode === 'ImageTabset' &&
                action?.data?.location === 'top' && tooltabsPositionRef.current !== 'top') {
                const toolsTabsNode: Node | undefined = model.getNodeById("ToolsTabs");
                if (toolsTabsNode) {
                    resetLayout(isToolsPanel);
                    const r = document.querySelector(':root');
                    if (r) {
                        // @ts-ignore
                        r.style.setProperty('--splitter_size_bottom', `${4}px`);
                    }
                    model.doAction(Actions.moveNode("ToolsTabs", "ImageTabset", DockLocation.TOP, 0));
                    const newNode: Node | undefined = model.getNodeById("ToolsTabs");
                    const newTabsetNode: TabSetNode = newNode?.getParent() as TabSetNode;
                    if (newTabsetNode) {
                        model.doAction(Actions.updateNodeAttributes(newTabsetNode.getId(), {
                            weight: layoutRef?.current?.toolContextPanelWeight ?? 25, "enableMaximize": false,
                            "classNameTabStrip": 'ToolsTabStrip_Bottom', "classNameHeader": 'ToolsTabHeader_Bottom'
                        }));
                    }
                    dispatch({ type: "ImageDisplay/setTooltabsPosition", payload: 'top' });
                }
                return undefined;

            }
            /* else if (action?.data?.fromNode === 'ToolsPanel' && action?.data?.toNode === 'ImageTabset' &&
                action?.data?.location === 'top') {
                console.log(action?.data?.location);
                const toolsNode: Node | undefined = model.getNodeById("Tools");
                if (toolsNode) {
                    resetLayout(true);
                    //  const r = document.querySelector(':root');
                    //  if (r) {
                    //      // @ts-ignore
                    //      r.style.setProperty('--splitter_size_bottom', `${4}px`);
                    //  }
                    model.doAction(Actions.deleteTabset("Tools-Tabset"));
                    model.doAction(Actions.moveNode("Tools", "ImageTabset", DockLocation.TOP, 0));
                    const newNode: Node | undefined = model.getNodeById("Tools");
                    const newTabsetNode: TabSetNode = newNode?.getParent() as TabSetNode;
                    if (newTabsetNode) {
                        model.doAction(Actions.updateNodeAttributes(newTabsetNode.getId(), {
                            weight: 7.4, "enableMaximize": false,
                            "classNameTabStrip": 'Tools-Tabset', "classNameHeader": 'Tools-Tabset'
                        }));
                    }

                    model.doAction(Actions.updateNodeAttributes("PreviewPanelTabset", { weight: 9 }));

                    console.log(model);
                    //dispatch({ type: "ImageDisplay/setTooltabsPosition", payload: 'top' });
                }
                return undefined;
            }  */
            else if (action?.data?.fromNode === 'ToolsPanel' && action?.data?.toNode === 'ImageTabset' &&
                action?.data?.location !== 'top') {
                return action;
            }
            else {
                return undefined;
            }
        } else if (action.type === 'FlexLayout_SelectTab') {
            if (action?.data?.tabNode?.startsWith("AcquisitionPanel_")) {
                if (action?.data?.tabNode?.split('_')?.length > 1) {
                    const deviceSetId: number = parseInt(action?.data?.tabNode?.split('_')[1]);
                    //dispatch({ type: "Acquisition/setSelectedDeviceSetId", payload: deviceSetId });
                    dispatch(switchDeviceset({ devicedetId: deviceSetId, procedureCode: currentWorkitem?.details?.procedureCode }));
                }
            }
            return action;
        } else {
            return action;
        }
    }

    const resetLayout = (isToolsPanelActive: boolean) => {
        const r = document.querySelector(':root');

        /* const previewPanelTabset = model.getNodeById("PreviewPanelTabset") as TabSetNode;
        console.log("resetLayout PreviewPanelTabset :" + previewPanelTabset?.getWeight());
        const imageTabset = model.getNodeById("ImageTabset") as TabSetNode;
        console.log("resetLayout ImageTabset :" + imageTabset?.getWeight());
        const toolsTabset_Left = model.getNodeById("ToolsTabset_Left") as TabSetNode;
        console.log("resetLayout ToolsTabset_Left :" + toolsTabset_Left?.getWeight());
        const toolsTabset_Right = model.getNodeById("ToolsTabset_Right") as TabSetNode;
        console.log("resetLayout ToolsTabset_Right :" + toolsTabset_Right?.getWeight());
        const toolsTabset = model.getNodeById("Tools-Tabset") as TabSetNode;
        console.log("resetLayout Tools-Tabset :" + toolsTabset?.getWeight()); */

        // @ts-ignore
        r?.style.setProperty('--XRayButtonSVG_inactive_shift', `${0}px`);
        // @ts-ignore
        r?.style.setProperty('--tools_shift', `${0}px`);
        // @ts-ignore
        r?.style.setProperty('--xRayButton_shift', `${0}px`);
        // @ts-ignore
        r?.style.setProperty('--tools_offset', `${0}px`);
        // @ts-ignore
        r?.style.setProperty('--splitter_size_left', `${0}px`);
        // @ts-ignore
        r?.style.setProperty('--splitter_size_right', `${0}px`);
        // @ts-ignore
        r?.style.setProperty('--splitter_size_bottom', `${0}px`);

        //model.doAction(Actions.deleteTab("ToolsTabs"));
        const toolsTabsNode: Node | undefined = model.getNodeById("ToolsTabs");
        const parentTabset: TabSetNode = toolsTabsNode?.getParent() as TabSetNode;
        //remove tab from tabset to remove tabset
        if (parentTabset?.getClassNameTabStrip() === 'ToolsTabStrip_Bottom') {
            model.doAction(Actions.moveNode("ToolsTabs", "ToolsTabset_Left", DockLocation.CENTER, 0));
        }
        model.doAction(Actions.updateNodeAttributes("PreviewPanelTabset", { weight: weightPreviewPanelRef.current }));
        model.doAction(Actions.updateNodeAttributes("ToolsTabset_Left", { weight: 0 }));
        model.doAction(Actions.updateNodeAttributes("ToolsTabset_Right", { weight: 0 }));
        model.doAction(Actions.updateNodeAttributes("Procedures", { weight: 0 }));
        //model.doAction(Actions.updateNodeAttributes("ImageTabset", { weight: weightImagePanel }));
        //model.doAction(Actions.updateNodeAttributes("Tools-Tabset", { weight: 0 }));

        model.doAction(Actions.updateNodeAttributes("ImageTabset", { weight: isToolsPanelActive ? (weightImagePanelRef.current ?? 91) - weightToolsPanel : (weightImagePanelRef.current ?? 91) }));
        model.doAction(Actions.updateNodeAttributes("Tools-Tabset", { weight: isToolsPanelActive ? weightToolsPanel : 0.0 }));
    }

    return (
        <div className="app" id="app">
            <div className="contents">
                <Layout
                    model={model}
                    ref={LayoutRef}
                    onRenderTab={onRenderTab}
                    //onRenderTabSet={onRenderTabSet}
                    realtimeResize={true}
                    onAction={onAction}
                    factory={factory.bind(this)} />
            </div>
        </div>
    );

}

export default Viewer;
