import MainNavbar from './components/common/Navbar';
import ActionButton from './components/OrderList/ActionButton';
import { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { RootState } from './store'
import OrderList from './pages/OrderList';
import OrderDetail from './pages/OrderDetail';
import type { AppDispatch } from './store'
import { mqttConnect } from './apis/Mqtt/mqttActions';
import { apiSlice, useConfigQuery } from './apis/apiSlice';
import { CatalogueEntry } from './components/Akquisition/CatalogueSlice';
import React from 'react';
import FooterOrderlist from './components/common/FooterOrderlist';
import RequireAuth from './components/Auth/RequireAuth';
import Login from './components/Auth/Login';
import { getFilterForColor } from "./components/ImageDisplay/Tools/utils";
import { selectTheme } from './components/OrderList/MainOrderListSlice';
import { getLayout, getThemes, getWorkflow, selectCurrentOrder } from './components/OrderList/OrdersSlice';
import SubjectPanel from './components/Subject/SubjectPanel';
import CommonDialog from './components/Utils/CommonDialog';
import { selectSubjectDialogVisible } from './components/ImageDisplay/ImageDisplaySlice';
import { useORTranslation } from './components/Localization/ORLocalization';
import { getSelectedSubject } from './components/Subject/SubjectSlice';
import Footer from './components/common/Footer';
import { ProductInfoDialog } from './components/ProductInfoDialog/ProductInfoDialog';

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type ToolsListEntry = {
	name: string;
	icon: string;
	hasState: boolean;
	sticky: boolean;
	subMenu: ToolsListEntry[];
	config: any;
	id: string;
};

const App = () => {

	const dispatch = useAppDispatch();
	const { t } = useORTranslation(['OrderList', 'ExportDialog', 'FinalizeStudyDialog', 'common', 'xrayhelper']);

	const data_catalogue: any = useAppSelector(apiSlice.endpoints.getCatalogue.select());
	const currentTheme: string = useAppSelector((state) => selectTheme(state));
	const layout: any = useAppSelector((state) => getLayout(state));
	const workflow: any = useAppSelector((state) => getWorkflow(state));
	const themes: string[] = useAppSelector((state) => getThemes(state));
	const isSubjectDialogVisible: number = useAppSelector((state) => selectSubjectDialogVisible(state));
	const isProductInfoDialogVisible: boolean = useAppSelector((state) => state.ProductInfoDialog.isProductInfoDialogVisible);
	const currentOrderId: string | undefined = useAppSelector((state) => selectCurrentOrder(state));
	const subject: any = useAppSelector((state) => getSelectedSubject(state));

	const [isSubjectPanelOkDisabled, setIsSubjectPanelOkDisabled] = useState<boolean>(false);

	const {
		data,
		isSuccess,
	}: { data: any, isSuccess: boolean } = useConfigQuery({ file: 'GlobalConfig.json' }, {
		refetchOnMountOrArgChange: true,
	})

	const handleSujectPanelDialogClose = () => {
		dispatch({ type: "ImageDisplay/setSubjectDialogVisible", payload: 0 });
	};

    const handleProductInfoDialogClose = () => {
        dispatch({ type: 'ProductInfoDialog/changeProductInfoDialogVisible', payload: false });
    };

	useEffect(() => {
		dispatch(mqttConnect());
	}, [dispatch]);

	/* useEffect(() => {
		if (isSuccess && data) {
			console.log(JSON.stringify(data));
			dispatch({ type: "MainOrderListColumns/setImagePolarity", payload: data?.imagePolarity });
			dispatch({ type: "MainOrderListColumns/setVersionString", payload: data?.versionString });
			dispatch({ type: "MainOrderListColumns/setTheme", payload: data?.theme });
			dispatch({ type: "MainOrderListColumns/setShowThemeSelection", payload: data?.showThemeSelection });
			dispatch({ type: "Acquisition/setDeviceRunnerPath", payload: data?.Device_Runner_URL });
			dispatch({ type: "ImageDisplay/setDrawHandlesOnHover", payload: data?.drawHandlesOnHover ?? false });
			dispatch({ type: "ImageDisplay/setPixelReplication", payload: data?.pixelReplication ?? false });
			dispatch({ type: "ImageDisplay/setMaxLineWidth", payload: data?.maxLineWidth });
			dispatch({ type: "Procedure/setSelectedProcedureSettingVariantArray", payload: data?.procedureSettingVariantList });
			dispatch({ type: "Procedure/setSelectedXRayHelperVariantArray", payload: data?.xrayHelperVariantList });
			dispatch({ type: "Procedure/setFavouriteTag", payload: data?.favoriteTag });
			if (data?.Device_Runner_URL) {
				dispatch({ type: "Acquisition/setDeviceRunnerActive", payload: true });
			} else {
				dispatch({ type: "Acquisition/setDeviceRunnerActive", payload: false });
			}

			const elem = document.getElementById('MainHtml');
			if (elem) {
				elem.classList.add(data?.theme);
			}
			const elemTitle = document.getElementById('mainTitle');
			if (elemTitle) {
				elemTitle.innerHTML = data?.theme;
			}
		}
	}, [dispatch, isSuccess, data]); */

	useEffect(() => {
		if (data_catalogue.isSuccess) {
			dispatch({ type: 'Catalogue/purgeCatalogue' });
			data_catalogue?.data?.forEach((Element: CatalogueEntry) => dispatch({ type: 'Catalogue/setCatalogue', payload: Element }));
		}
	}, [dispatch, data_catalogue.isSuccess, data_catalogue?.data]);

	useEffect(() => {
		const r = document.querySelector(':root');
		const elem = document.getElementById('container');

		let currentThemesDir = './';
		if (themes.length > 0) {
			currentThemesDir = `Themes/${currentTheme}`;
		}

		dispatch(apiSlice.endpoints.config.initiate({ file: 'ToolsConfig.json', path: currentThemesDir }));

		dispatch(apiSlice.endpoints.config.initiate({ file: 'Layout.json', path: currentThemesDir }));

		dispatch(apiSlice.endpoints.config.initiate({ file: 'WorkflowConfig.json', path: currentThemesDir }));

		if (r && elem) {
			const color_active_border = getComputedStyle(elem)?.getPropertyValue('--color-active-border');
			if (color_active_border) {
				const svgFilter = getFilterForColor(color_active_border);
				// @ts-ignore
				r.style.setProperty('--filterColorActiveBorder', svgFilter);
			}
			const iconColor = getComputedStyle(elem)?.getPropertyValue('--icon-Color');
			if (iconColor) {
				const svgFilter = getFilterForColor(iconColor);
				// @ts-ignore
				r.style.setProperty('--filterIconColor', svgFilter);
			}
		}


	}, [currentTheme, data?.theme]);

	useEffect(() => {
		dispatch({ type: "ImageDisplay/setToolsPanel", payload: layout?.dockToolsPanel ?? false });

		const r = document.querySelector(':root');
		if (r && layout?.tabSetTabStripHeight) {
			// @ts-ignore
			r.style.setProperty('--tabSetTabStripHeightpx', layout?.tabSetTabStripHeight + 'px');
		}

		if (layout?.favicon) {
			var link = document.querySelector("link[rel~='icon']");
			if (!link) {
				link = document.createElement('link');
				//@ts-ignore
				link.rel = 'icon';
				document.head.appendChild(link);
			}

			//@ts-ignore
			link.href = layout?.favicon;
		}

	}, [JSON.stringify(layout)]);

	useEffect(() => {
		if (workflow) {
			dispatch({ type: "MainOrderListColumns/setAllowComplete", payload: workflow.allowComplete });
			dispatch({ type: "ImageDisplay/setViewportStorageType", payload: workflow.viewportStorage });
			dispatch({ type: "ImageDisplay/setReloadApplicationTimeout", payload: workflow.applicationReloadTimeout });
			dispatch({ type: "ImageDisplay/setDeviationIndexThresholds", payload: workflow.deviationIndexThresholds });
			dispatch({ type: "ImageDisplay/setShowDeviationIndexWidget", payload: workflow.showDeviationIndexWidget });
			dispatch({ type: "ImageDisplay/setShowDeviationIndexValue", payload: workflow.showDeviationIndexValue });
			dispatch({ type: "ImageDisplay/setShowSValue", payload: workflow.showSValue });
			dispatch({ type: "ImageDisplay/setShowExposureIndexValue", payload: workflow.showExposureIndexValue });
			dispatch({ type: "ImageDisplay/setHideActionButtonCloseWorkitem", payload: workflow.hideActionButtonCloseWorkitem });
			dispatch({ type: "ImageDisplay/setCutToBMOnFinalize", payload: workflow.cutToBMOnFinalize });
			dispatch({ type: "ImageDisplay/setActivatePlanningMode", payload: workflow.activatePlanningMode });
			dispatch({ type: "ImageDisplay/setSupressWWWCHistonRightMouse", payload: workflow.supressWWWCHistonRightMouse });
			dispatch({ type: "MainOrderListColumns/setStudyStateFilter", payload: workflow.defaultStudyStateFilter });
		}
	}, [workflow]);

	useEffect(() => {
		function addcss(theme: string) {
			const head = document.getElementsByTagName('head')[0];
			if (head) {
				const linkElement = document.createElement('link');
				linkElement.setAttribute('rel', 'stylesheet');
				linkElement.setAttribute('type', 'text/css');
				linkElement.setAttribute('href', `/Themes/${theme}/${theme}.css`);
				head.appendChild(linkElement);
			}
		}

		if (themes) {
			themes?.forEach((theme: string) => {
				addcss(theme);
			})
		};

	}, [themes]);

	return (
		<>
			{isSuccess ?
				<div className="container" id="container">
					{/* <ConnectedRouter history={history}> */}
					<BrowserRouter>
						<MainNavbar />
						<Routes>
							{/* <Switch> */}
							<Route path="/" element={
								<RequireAuth>
									<OrderList />
									<div className="footer_orderlist">
										<FooterOrderlist />
									</div>
								</RequireAuth>}>
							</Route>
							<Route path="/orders/:id" element={
								<RequireAuth>
									<div className="main-area">
										<OrderDetail />
									</div>
									{/* <div className="main-footer">
										<Footer />
									</div> */}
								</RequireAuth>}>
							</Route>
							<Route path="/device-runner/*/orders/:id" element={
								<RequireAuth>
									<div className="main-area">
										<OrderDetail />
									</div>
									{/* <div className="main-footer">
										<Footer />
									</div> */}
								</RequireAuth>}>
							</Route>
							<Route path="/acquisition" element={
								<RequireAuth>
									<div className="main-area">
										<ActionButton title="Objekt röntgen" />
									</div>
								</RequireAuth>}>
							</Route>
							<Route path="/login" element={<Login />} />
							{/* </Switch> */}
						</Routes>
						<CommonDialog zIndex={100} onClose={handleSujectPanelDialogClose} title={t(`${isSubjectDialogVisible === 4 ? t("Edit study data", { ns: 'Subject' }) : t("New Study", { ns: 'Subject' })}`)} visible={isSubjectDialogVisible > 0}
							okText={t(`${isSubjectDialogVisible === 4 ? t('save', {ns: 'common'}) : t('Add study', {ns: 'Subject'})}`)} cancelText={t("cancel", { ns: 'common' })} okDisabled={isSubjectPanelOkDisabled}
							default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 900) / 2, width: 1150, height: undefined }} childProps={{}}
							skipFooter={isSubjectDialogVisible === 1 && subject?.subjectName === undefined} hideCloseButton={layout?.showDialogCloseCross} iconSource={`${isSubjectDialogVisible === 4 ? '' : 'Neuer_Roentgenauftrag.svg'}`}>
							<SubjectPanel orderId={currentOrderId ?? ''} setOpenStudyDisabled={(disabled: boolean) => setIsSubjectPanelOkDisabled(disabled)} />
						</CommonDialog>
						<CommonDialog
							title="Info"
							visible={isProductInfoDialogVisible}
							onClose={handleProductInfoDialogClose}
							okText=""
							cancelText=""
							skipFooter={true}
							default={{ x: 0, y: 30, width: 469, height: undefined }}
							className="productInfoDialog"
							childProps={{}}
							enableResizing={false}
						>
							<ProductInfoDialog />
						</CommonDialog>
					</BrowserRouter>
					{/* </ConnectedRouter> */}

				</div> : null}
		</>
	);
}

export default App;
