import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";

import { pointInFreehand } from "./utils";
const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const getNewContext = csTools.import("drawing/getNewContext");
const getToolState = csTools.getToolState;
const addToolState = csTools.addToolState;
const draw = csTools.import("drawing/draw");
const setShadow = csTools.import("drawing/setShadow");
const drawRect = csTools.import("drawing/drawRect");
const Cursors = csTools.importInternal('tools/cursors');
const rectangleRoiCursor = Cursors.rectangleRoiCursor;
const getLogger = csTools.importInternal('util/getLogger');
const external = csTools.external;
const logger = getLogger('tools:annotation:WhitePointTool');

/**
 * @public
 * @class WhitePointTool
 * @memberof Tools
 *
 * @classdesc Tool for setting the roi/whitepoint to a new rectangular region.
 * @extends BaseAnnotationTool
 */
export default class WhitePointTool extends BaseAnnotationTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'Whitepoint',
            supportedInteractionTypes: ['Mouse', 'Touch'],
            configuration: {
                drawHandles: false,
                //drawHandlesOnHover: true,
                hideHandlesIfMoving: true,
                renderDashed: false,
                shadow: true,
            },
            svgCursor: rectangleRoiCursor,
        };

        super(props, defaultProps);
    }

    activeCallback(element) {
        //console.log("wp active");
        // enable display of previously used ROI

        let toolData = getToolState(element, 'ProcessingROI');
        if (toolData === undefined) { return; }
        let update = false;
        for (let i = 0; i < toolData.data.length; i++) {
            let data = toolData.data[i];
            data.visible = true;
            update = true;
        }
        if (update) {
            external.cornerstone.updateImage(element);
        }
    }

    passiveCallback(element) {
        //console.log("wp passive");
        // disable display of previously used ROI
        let toolData = getToolState(element, 'ProcessingROI');
        if (toolData === undefined) { return; }
        let update = false;
        for (let i = 0; i < toolData.data.length; i++) {
            let data = toolData.data[i];
            data.visible = false;
            update = true;
        }
        if (update) {
            external.cornerstone.updateImage(element);
        }
    }

    // providing an own addNewMeasurement to prevent initial drag interaction
    addNewMeasurement(evt, interactionType) {
        evt.preventDefault();
        evt.stopPropagation();
        const eventData = evt.detail;
        const element = eventData.element;
        const measurementData = this.createNewMeasurement(eventData);

        if (!measurementData) {
            return;
        }

        addToolState(element, this.name, measurementData);

        external.cornerstone.updateImage(element);

        const modifiedEventData = {
            toolName: this.name,
            toolType: this.name,
            element: evt.target,
            measurementData: measurementData,
        };
        cornerstone.triggerEvent(evt.target, csTools.EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);

        return;
    }

    createNewMeasurement(eventData) {
        if (eventData && eventData.event !== undefined) {
            let toolData = getToolState(eventData.event.currentTarget ?? eventData.event.srcElement?.parentElement, this.name);
            if (toolData !== undefined && toolData.data.length !== 0) { return; }
        }
        const goodEventData =
            eventData && eventData.currentPoints && eventData.currentPoints.image;

        if (!goodEventData) {
            logger.error(
                `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
            );

            return;
        }
        const element = eventData.element;
        var image = cornerstone.getImage(element);
        let imageId = image.image_raw ? image.image_raw.targetId : undefined;
        if (imageId === undefined) {
            console.log("no raw image");
            return;
        }
        // check x,y against image borders
        let x = eventData.currentPoints.image.x;
        let y = eventData.currentPoints.image.y;
        if (x > (image.width - 50) ||
            y > (image.height - 50) ||
            x < 50 ||
            y < 50) {
           return;
        }
        // check x,y against black mask borders
        let toolDataBM = getToolState(eventData.event.currentTarget ?? eventData.event.srcElement?.parentElement, "BlackMask");
        if (toolDataBM && toolDataBM.data && toolDataBM.data.length > 0) {
            const h = toolDataBM.data[0].handles;
            let bmh = [];
            for (let j = 1; j <= 4; j++)
                bmh.push({"x":h[j].x, "y":h[j].y});
            bmh.push({"x":h[1].x, "y":h[1].y});
            //console.log("point in freehand for"+bmh+"and ("+x+"/"+y+") returns"+pointInFreehand(bmh, {"x":x, "y":y}))
            if (pointInFreehand(bmh, {"x":x, "y":y}) === false)
                return;
        }
        return {
            visible: true,
            active: false,
            color: undefined,
            invalidated: true,
            artifactId: _getArtifactIDFromURI(imageId),
            roi: [Math.trunc(eventData.currentPoints.image.x - 50), Math.trunc(eventData.currentPoints.image.y - 50), 100, 100],
            requestFailed: false,
            handles: {
                start: {
                    x: eventData.currentPoints.image.x - 50,
                    y: eventData.currentPoints.image.y - 50,
                    highlight: false,
                    active: true,
                },
                end: {
                    x: eventData.currentPoints.image.x + 50,
                    y: eventData.currentPoints.image.y + 50,
                    highlight: false,
                    active: true,
                },

                /* initialRotation: eventData.viewport.rotation,
                textBox: {
                  active: false,
                  hasMoved: false,
                  movesIndependently: false,
                  drawnIndependently: true,
                  allowedOutsideImage: true,
                  hasBoundingBox: true,
                }, */
            },
            matrixIndex: this.initialConfiguration.matrixIndex,
        };
    } // createNewMeasurement

    handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
        evt.stopImmediatePropagation();
        evt.stopPropagation();
        evt.preventDefault();
        cornerstone.updateImage(evt.target);
        const modifiedEventData = {
            toolName: this.name,
            toolType: this.name,
            element: evt.target,
            measurementData: toolData,
        };
        cornerstone.triggerEvent(evt.target, csTools.EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);
    }

    pointNearTool(element, data, coords, interactionType) {
        return false;
        /*
        const hasHandles =
            data && data.handles && data.handles.start && data.handles.end;
        const validParameters = hasHandles;

        if (!validParameters) {
            logger.warn(
                `invalid parameters supplied to tool ${this.name}'s pointNearTool`
            );
        }

        if (!validParameters || data.visible === false) {
            return false;
        }

        const distance = interactionType === 'mouse' ? 15 : 25;
        const startCanvas = cornerstone.pixelToCanvas(
            element,
            data.handles.start
        );
        const endCanvas = cornerstone.pixelToCanvas(
            element,
            data.handles.end
        );

        const rect = {
            left: Math.min(startCanvas.x, endCanvas.x),
            top: Math.min(startCanvas.y, endCanvas.y),
            width: Math.abs(startCanvas.x - endCanvas.x),
            height: Math.abs(startCanvas.y - endCanvas.y),
        };

        const distanceToPoint = external.cornerstoneMath.rect.distanceToPoint(
            rect,
            coords
        );

        return distanceToPoint < distance;
        */
    } // pointNearTool

    renderToolData(evt) {
        const toolData = csTools.getToolState(evt.currentTarget, this.name);

        if (!toolData) {
            return;
        }

        const eventData = evt.detail;
        const { image, element } = eventData;

        const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
        const {
            renderDashed,
        } = this.configuration;
        const context = getNewContext(eventData.canvasContext.canvas);

        draw(context, context => {
            // If we have tool data for this element - iterate over each set and draw it
            for (let i = 0; i < toolData.data.length; i++) {
                const data = toolData.data[i];

                if (data.visible === false) {
                    continue;
                }

                // Configure
                let color = csTools.toolColors.getColorIfActive(data);

				if(data.requestFailed)
					color = "red";

                setShadow(context, this.configuration);

                const rectOptions = { color };

                if (renderDashed) {
                    rectOptions.lineDash = lineDash;
                }

                // Draw
                drawRect(
                    context,
                    element,
                    data.handles.start,
                    data.handles.end,
                    rectOptions,
                    'pixel',
                    0
                );
            }
        });
    } // renderToolData

    updateCachedStats(image, element, data) {
        return;
    }

} // WhitePointTool


function _getArtifactIDFromURI(uri) {
    let url = new URL(uri);

    let path = url.pathname;

    let pc = path.split('/');
    for (var i = 0; i < pc.length; i++) {
        if (pc[i] === "artifacts") {
            return pc[i + 1];
        }
    }

    return undefined;
} // _getArtifactIDFromURI
