import { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import './PatientSizeWeight.scss';
import { getImagePathForCurrentTheme, getSelectedWorkitem } from '../OrderList/OrdersSlice';
import { apiSlice, device, deviceSet } from '../../apis/apiSlice';
import { Constants } from '../../Constants';
import { getCurrentProtocolStepNumber } from '../ImageDisplay/ImageDisplaySlice';

import { useORTranslation } from '../Localization/ORLocalization';
import { formatISO } from 'date-fns';
import { getTechniqueParameters } from './GeneratorTechniqueParametersSlice';
import { selectGeneratorForDeviceSet } from './AcquisitionSlice';

interface PatientSizeWeightProps {
    orderId: string;
    deviceSet: any;
    device: device | deviceSet;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const PatientSizeWeight = (props: PatientSizeWeightProps) => {

    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['Acquisition', 'common']);

    const [updisabled, setUpdisabled] = useState<boolean>(true);
    const [downdisabled, setDowndisabled] = useState<boolean>(true);

    const [patientSizeSelected, setPatientSizeSelected] = useState<boolean>(false);
    const [patientThicknessSelected, setPatientThicknessSelected] = useState<boolean>(false);
    const [selectedSizeIndex, setSelectedSizeIndex] = useState<number>(0);
    const [selectedPatientThickness, setSelectedPatientThickness] = useState<number>(0);

    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
    const currentProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.ACQUISITION_STEP_TYPE, 'scheduled', 'rejected'));
    const generatorForDeviceSet: any = useAppSelector((state) => selectGeneratorForDeviceSet(state, props.deviceSet?.id));

    const findNearestValueInList = (value: { kv: number, mas: number }, list: any[]): number => {
        let deviation: number = Number.MAX_VALUE;
        let finalValue: number = 0;

        for (let i = 0; i < list.length; i++) {
            if ((Math.pow((list[i]?.kv - value?.kv), 2) + Math.pow((list[i]?.mas - value?.mas), 2)) < deviation) {
                finalValue = list[i]?.cm;
                deviation = (Math.pow((list[i]?.kv - value?.kv), 2) + Math.pow((list[i]?.mas - value?.mas), 2));
            }
        }
        return finalValue;
    }

    const findnextHigherValueInList = (value: number, list: any[]): number => {
        let ret: number = Number.MAX_VALUE;
        for (let i = 0; i < list.length; i++) {

            if (list[i]?.cm > value && list[i]?.cm < ret) {
                ret = list[i]?.cm;
            }
        }
        if (ret === Number.MAX_VALUE) {
            ret = value
        }
        return ret;
    }

    const findnextLowerValueInList = (value: number, list: any[]): number => {
        let ret: number = Number.MIN_VALUE;
        for (let i = 0; i < list.length; i++) {

            if (list[i]?.cm < value && list[i]?.cm > ret) {
                ret = list[i]?.cm;
            }
        }
        if (ret === Number.MIN_VALUE) {
            ret = value
        }
        return ret;
    }

    const handleClick = (inc: number) => {
        const currentProtocolStep = currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber];
        const newWorkitemDetails = structuredClone(currentWorkitem?.details);

        const patientSizeValues = currentProtocolStep?.scheduled?.generator?.patientSizeValues;
        const patientThicknessValues = currentProtocolStep?.scheduled?.generator?.thicknessValues;
        let newValues: any = undefined;
        let nextpatientThickness: number | undefined = undefined;

        if (patientSizeSelected && patientSizeValues !== undefined && patientSizeValues !== null && Array.isArray(patientSizeValues) && patientSizeValues.length > 0) {
            const sizeIndexMin = Math.min(...patientSizeValues.map((sizeValue: any) => sizeValue?.sizeIndex));
            const sizeIndexMax = Math.max(...patientSizeValues.map((sizeValue: any) => sizeValue?.sizeIndex));

            setUpdisabled(false);
            setDowndisabled(false);
            if ((selectedSizeIndex + inc) >= sizeIndexMax) {
                setUpdisabled(true);
            }
            if ((selectedSizeIndex + inc) <= sizeIndexMin) {
                setDowndisabled(true);
            }

            if ((selectedSizeIndex + inc) === 0) {
                newValues = {
                    kv: currentProtocolStep?.scheduled?.generator?.kv,
                    masWithoutAEC: currentProtocolStep?.scheduled?.generator?.masWithoutAEC,
                    masWithAEC: currentProtocolStep?.scheduled?.generator?.masWithAEC,
                    mas: (currentProtocolStep?.scheduled?.setup?.measuringChamber === 0) ? currentProtocolStep?.scheduled?.generator?.masWithoutAEC : currentProtocolStep?.scheduled?.generator?.masWithAEC,
                    sizeIndex: 0
                }
            } else {
                newValues = patientSizeValues.find((sizeValue: any) => sizeValue.sizeIndex === selectedSizeIndex + inc);
                newValues.mas = (currentProtocolStep?.scheduled?.setup?.measuringChamber === 0) ? newValues.masWithoutAEC : newValues.masWithAEC;
            }
            if (newValues) {
                setSelectedSizeIndex(selectedSizeIndex + inc);
                newWorkitemDetails.patientSizeIndex = newValues?.sizeIndex;
            }
        }

        if (patientThicknessSelected && patientThicknessValues !== undefined && patientThicknessValues !== null && Array.isArray(patientThicknessValues) && patientThicknessValues.length > 0) {
            const thicknessMin = Math.min(...patientThicknessValues.map((thicknessValue: any) => thicknessValue?.cm));
            const thicknessMax = Math.max(...patientThicknessValues.map((thicknessValue: any) => thicknessValue?.cm));

            setUpdisabled(false);
            setDowndisabled(false);

            if (inc > 0) {
                nextpatientThickness = findnextHigherValueInList(selectedPatientThickness, patientThicknessValues);
            } else {
                nextpatientThickness = findnextLowerValueInList(selectedPatientThickness, patientThicknessValues);
            }

            if (nextpatientThickness >= thicknessMax) {
                setUpdisabled(true);
            }
            if (nextpatientThickness <= thicknessMin) {
                setDowndisabled(true);
            }

            setSelectedPatientThickness(nextpatientThickness);
            newWorkitemDetails.patientThickness = nextpatientThickness;

            newValues = patientThicknessValues.find((value: any) => value.cm === nextpatientThickness);

        }

        if (newValues) {
            const d = new Date();
            const actionRef = currentWorkitem?.id + "_" + formatISO(d) + d.getMilliseconds();
            dispatch({ type: "GeneratorTechniqueParameters/setCurrentActionRef", payload: actionRef });
            if (generatorForDeviceSet?.config?.endpoint) {
                //const actionRef = currentWorkitem?.id + "_" + formatISO(new Date());
                dispatch(getTechniqueParameters({
                    voltage: newValues?.kv,
                    exposure: newValues?.mas,
                    generatorEndpoint: generatorForDeviceSet?.config?.endpoint, actionRef
                }));
            }


            dispatch(apiSlice.endpoints.updateWorkitemDetails1.initiate(
                {
                    workitemId: currentWorkitem?.id,
                    body: {
                        details: newWorkitemDetails,
                    }
                }
            ));

            dispatch(
                apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                    const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                    if (currentWorkitem?.id && workitemList && workitemList.includes(currentWorkitem?.id)) {
                        const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitem?.id);
                        if (tmpWorkitem?.data) {
                            tmpWorkitem.data.details = newWorkitemDetails;
                        }
                    }
                    return data;
                })
            );
        }
    }

    const getNumberToDisplay = () => {
        if (patientSizeSelected) {
            return selectedSizeIndex.toString();
        } else if (patientThicknessSelected) {
            return selectedPatientThickness.toString();
        }

        return ""
    }

    useEffect(() => {
        setUpdisabled(true);
        setDowndisabled(true);

        const currentProtocolStep = currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber];
        setSelectedSizeIndex(currentWorkitem?.details?.patientSizeIndex ?? 0);
        if (currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.thicknessValues !== undefined && currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.thicknessValues !== null) {
            const exposure = (currentProtocolStep?.scheduled?.setup?.measuringChamber === 0) ? currentProtocolStep?.scheduled?.generator?.masWithoutAEC : currentProtocolStep?.scheduled?.generator?.masWithAEC
            setSelectedPatientThickness(findNearestValueInList({ kv: currentProtocolStep?.scheduled?.generator?.kv, mas: exposure }, currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.thicknessValues))
        }
        setPatientSizeSelected(false);
        setPatientThicknessSelected(false);

        if (currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.patientSizeValues !== undefined && currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.patientSizeValues !== null) {
            setPatientSizeSelected(true);
            setPatientThicknessSelected(false);
            const patientSizeValues = currentProtocolStep?.scheduled?.generator?.patientSizeValues;
            if (patientSizeValues) {
                const sizeIndexMin = Math.min(...patientSizeValues.map((sizeValue: any) => sizeValue?.sizeIndex));
                const sizeIndexMax = Math.max(...patientSizeValues.map((sizeValue: any) => sizeValue?.sizeIndex));
                setUpdisabled((currentWorkitem?.details?.patientSizeIndex ?? 0) >= sizeIndexMax);
                setDowndisabled((currentWorkitem?.details?.patientSizeIndex ?? 0) <= sizeIndexMin);
            }
        } else if (currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.thicknessValues !== undefined && currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.thicknessValues !== null) {
            setPatientSizeSelected(false);
            setPatientThicknessSelected(true);
            const patientThicknessValues = currentProtocolStep?.scheduled?.generator?.thicknessValues;
            let currentThickness = currentWorkitem?.details?.patientThickness;
            if (currentThickness === undefined || currentThickness === null) {
                const mas = (currentProtocolStep?.scheduled?.setup?.measuringChamber === 0) ? currentProtocolStep?.scheduled?.generator?.masWithoutAEC : currentProtocolStep?.scheduled?.generator?.masWithAEC;
                currentThickness = findNearestValueInList({ kv: currentProtocolStep?.scheduled?.generator?.kv, mas: mas }, currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.thicknessValues)
            }
            setSelectedPatientThickness(currentThickness);
            if (patientThicknessValues) {
                const thicknessMin = Math.min(...patientThicknessValues.map((thicknessValue: any) => thicknessValue?.cm));
                const thicknessMax = Math.max(...patientThicknessValues.map((thicknessValue: any) => thicknessValue?.cm));
                setUpdisabled(currentThickness >= thicknessMax);
                setDowndisabled(currentThickness <= thicknessMin);
            }
        }
    }, [currentWorkitem?.id, currentWorkitem?.details?.procedureCode, currentWorkitem?.details?.patientSizeIndex, currentWorkitem?.details?.patientThickness, props.deviceSet?.id]);


    return (
        <div className="patientSizeWeight">
            <div className="patientSizeWeightWrapper">
                <div className="patientSizeWeightButtons">
                    <div className="patientSizeWeightButtonsColumn">
                        <button onClick={() => setPatientSizeSelected(!patientSizeSelected)}
                            className={patientSizeSelected ? "numericInputButton_selected" : "numericInputButton"} disabled={currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.patientSizeValues === undefined || currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.patientSizeValues === null}>
                            <img
                                src={`${imagePathForCurrentTheme}PatientSize.svg`}
                                onError={(event: any) => { event.target.src = "/images/PatientSize.svg"; event.onerror = null }}
                                alt="PatientSize.svg" />
                        </button>
                        <button className={patientThicknessSelected ? "numericInputButton_selected" : "numericInputButton"} disabled={currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.thicknessValues === undefined || currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.thicknessValues === null}>
                            <img
                                src={`${imagePathForCurrentTheme}Size.svg`}
                                onError={(event: any) => { event.target.src = "/images/Size.svg"; event.onerror = null }}
                                alt="Size.svg" />
                        </button>
                    </div>
                </div>
                <div className="patientSizeWeightLabel">
                    <label className="patientSizeWeightLabelNumber">
                        {getNumberToDisplay()}
                    </label>
                    <label className="patientSizeWeightLabelUnit">
                        {patientThicknessSelected ? "cm" : ""}
                    </label>
                </div>
                <div className="patientSizeWeightButtons">
                    <div className="patientSizeWeightButtonsColumn">
                        <button className="numericInputButton" onClick={() => handleClick(1)} disabled={updisabled}>
                            <img style={{ transform: "rotate(90deg)" }}
                                src={`${imagePathForCurrentTheme}triangle-left-arrow-outline-svgrepo-com.svg`}
                                onError={(event: any) => { event.target.src = "/images/triangle-left-arrow-outline-svgrepo-com.svg"; event.onerror = null }}
                                alt="triangle-left-arrow-outline-svgrepo-com.svg" />
                        </button>
                        <button className="numericInputButton" onClick={() => handleClick(-1)} disabled={downdisabled}>
                            <img style={{ transform: "rotate(-90deg)" }}
                                src={`${imagePathForCurrentTheme}triangle-left-arrow-outline-svgrepo-com.svg`}
                                onError={(event: any) => { event.target.src = "/images/triangle-left-arrow-outline-svgrepo-com.svg"; event.onerror = null }}
                                alt="triangle-left-arrow-outline-svgrepo-com.svg" />
                        </button>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default PatientSizeWeight;
