import cornerstone, { EnabledElement, VOI } from "cornerstone-core";
import { Constants } from "../../Constants";
import { AppDispatch, RootState } from "../../store";
import { messageSeverity } from "../common/MessageSlice";
import { addImageFromFile, addImageMode } from "../OrderList/OrdersSlice";
import { DisplayItemType, getMatrixIndexForInsert, getPixelReplication, Viewport, viewportToCornerstoneViewport, cornerstoneViewportToViewport, getViewportStorageType, ViewportStorageType } from "./ImageDisplaySlice";
import { serviceEndpoints } from "../..";
import { t } from "../Localization/ORLocalization";

export const asyncCallWithTimeout = async (asyncPromise: any, timeLimit: number) => {
	let timeoutHandle: NodeJS.Timeout;

	const timeoutPromise = new Promise((_resolve, reject) => {
		timeoutHandle = setTimeout(
			() => {
				reject(new Error('Async call timeout limit reached'))
			},
			timeLimit
		);
	});

	return Promise.race([asyncPromise, timeoutPromise]).then(result => {
		clearTimeout(timeoutHandle);
		return result;
	}).catch(error => {
		console.log(error)
	})
}

export function waitforImageRendered(element: HTMLElement) {
	return new Promise<void>(function (resolve) {
		element.addEventListener('cornerstoneimagerendered', function handler(evt) {
			evt.target?.removeEventListener('cornerstoneimagerendered', handler);
			resolve();
		});
	});
}

function waitforElementEnabled(newIndex: number) {
	return new Promise<EnabledElement | undefined>(function (resolve) {
		cornerstone.events.addEventListener('cornerstoneelementenabled', function handler(evt: any) {
			if (evt?.detail?.element?.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${newIndex}`) {
				cornerstone.events.removeEventListener('cornerstoneimagerendered', handler);
				const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
				const displayElement: EnabledElement | undefined = cornerstoneElements.find((element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${newIndex}`);
				resolve(displayElement);
			}
		});
	});
}

function relativeRescale(enabledElement: any, oldCanvasWidth: number, oldCanvasHeight: number, scale: number) {
	//const scale = enabledElement.viewport.scale;
	const canvasWidth = enabledElement.canvas.width;
	const canvasHeight = enabledElement.canvas.height;
	const relWidthChange = canvasWidth / oldCanvasWidth;
	const relHeightChange = canvasHeight / oldCanvasHeight;
	const relChange = Math.sqrt(relWidthChange * relHeightChange);

	return relChange * scale;
}

export async function loadAndDisplayImage(args: {
	imageFile: File | undefined, imageName: string | undefined, imageId: string | undefined, artifactId: string | undefined, artifactId_Raw: string | undefined, createPreviewImage: addImageMode,
	matrixIndex: number, display_raw: boolean, enabledElementForExport: EnabledElement | undefined, dispatch: AppDispatch, getState: () => RootState
}): Promise<void> {
	if (args.imageName) {
		const newIndex = getMatrixIndexForInsert(args.getState(), args.matrixIndex);
		// load image into cornerstone
		let image: cornerstone.Image | undefined = undefined;
		let image_raw: cornerstone.Image | undefined = undefined;
		let displayElement: EnabledElement | undefined = undefined;
		const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
		if (args.enabledElementForExport) {
			displayElement = args.enabledElementForExport;
		} else {
			displayElement = cornerstoneElements.find((element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${newIndex}`);
		}
		if (!displayElement) {
			// if opened from study list view the ViewportElenemt may be not fully initialised
			displayElement = await asyncCallWithTimeout(waitforElementEnabled(newIndex), 2000);
		}
		args.dispatch({ type: "ImageDisplay/setSelectedIndex", payload: newIndex });
		if (args.artifactId === undefined) { // this is true for images from drag and drop
			args.dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: '' });
		} else {
			args.dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: args.imageId });
		}
		/* args.dispatch({ type: "ImageDisplay/setWindowLevelHistActive", payload: { matrixIndex: newIndex, isActive: false } });
		args.dispatch({ type: "ImageDisplay/setFalseColorsActive", payload: { matrixIndex: newIndex, isActive: false } });
		args.dispatch({ type: "ImageDisplay/setPipeWallThicknessChartActive", payload: { matrixIndex: newIndex, isActive: false } });
		args.dispatch({ type: "ImageDisplay/setLineProfileChartActive", payload: { matrixIndex: newIndex, isActive: false } });
		args.dispatch({ type: "ImageDisplay/setBlackMaskActive", payload: { matrixIndex: newIndex, isActive: false } });
		args.dispatch({ type: "ImageDisplay/setGrayscaleLineProfileChartActive", payload: { matrixIndex: newIndex, isActive: false } });
		args.dispatch({ type: "ImageDisplay/setResolutionMeasurementChartActive", payload: { matrixIndex: newIndex, isActive: false } });
		args.dispatch({ type: "ImageDisplay/setLengthCalibrationChartActive", payload: { matrixIndex: newIndex, isActive: false } });
		args.dispatch({ type: "ImageDisplay/setToolTabsModel", payload: { matrixIndex: newIndex, model: undefined } }); */


		try {
			image = await cornerstone.loadAndCacheImage(args.imageName);

			if (args.enabledElementForExport && args.enabledElementForExport.canvas) {
				args.enabledElementForExport.canvas.width = image?.width;
				args.enabledElementForExport.canvas.height = image?.height;
			}

			if (args.artifactId_Raw) {
				image_raw = await cornerstone.loadAndCacheImage(`tifUri:${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${args.artifactId_Raw}/raw`);
				args.dispatch({ type: "ImageDisplay/setHasRawImage", payload: { matrixIndex: newIndex, hasRawImage: true } });
				if (image_raw) {
					image_raw.columnPixelSpacing = image.columnPixelSpacing;
					image_raw.rowPixelSpacing = image.rowPixelSpacing;
					// @ts-ignore
					image_raw.image_org = image;
					// @ts-ignore
					image.image_raw = image_raw;
					// @ts-ignore
					image_raw.targetId = `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${args.artifactId_Raw}/raw`;
				}
			} else {
				args.dispatch({ type: "ImageDisplay/setHasRawImage", payload: { matrixIndex: newIndex, hasRawImage: false } });
			}
			if (image) {

				args.dispatch({ type: 'ImageDisplay/setMatrixImages', payload: { matrixIndex: newIndex, imageId: args.imageId, displayItemType: DisplayItemType.image } });
				args.dispatch({ type: 'ImageDisplay/setCurrentIndex', payload: newIndex });
				if (args.artifactId) {
					// @ts-ignore
					image.targetId = `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${args.artifactId}/raw`;
				}
			}
		} catch (err) {
			let errorMessage: string = '';
			if (typeof err === "string") {
				errorMessage = err;
			} else if (err instanceof Object) {
				errorMessage = JSON.stringify(err);
			}
			args.dispatch({ type: "Message/setMessage", payload: { timestamp: new Date().toISOString(), severity: messageSeverity.error, text: "Image loading failed: " + errorMessage + " URL: " + args.imageName } })
		}

		// get stored viewport (if exist) and display inage
		if (image && displayElement && displayElement.element) {

			const isRaw: boolean = args.display_raw && image_raw !== undefined;
			let viewport: cornerstone.Viewport;

			if (args.display_raw && image_raw) {
				viewport = cornerstone.getDefaultViewportForImage(displayElement.element, image_raw);
				viewport.invert = false;
				args.dispatch({ type: "ImageDisplay/setRawImageActive", payload: { matrixIndex: newIndex, isActive: true } });
			} else {
				viewport = cornerstone.getDefaultViewportForImage(displayElement.element, image);
				viewport.invert = false;
				//args.dispatch({ type: "ImageDisplay/setRawImageActive", payload: { matrixIndex: newIndex, isActive: false } });
			}

			// do not use storedViewport for images from drag and drop (args.artifactId === undefined)
			//let storedViewport: Viewport | undefined = args.artifactId === undefined ? undefined : getViewportForMatrixIndexAndImageID(args.getState(), newIndex, args.imageId);

			const storeImageOrientation: ViewportStorageType = getViewportStorageType(args.getState());
			if (storeImageOrientation !== ViewportStorageType.none) {
				let storedValue: string | null = null;
				if (storeImageOrientation === ViewportStorageType.local)
					storedValue = localStorage.getItem(`${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${args.artifactId}/raw`);
				if (storedValue) {
					// @ts-ignore
					let jsvalue: any = JSON.parse(storedValue);
					if (jsvalue) {
						let newScale: number | undefined = undefined;
						if (jsvalue?.displayWidth !== undefined && jsvalue?.displayHeight !== undefined) {
							newScale = relativeRescale(displayElement, jsvalue?.displayWidth, jsvalue?.displayHeight, jsvalue.scale);
							console.log(newScale);
						}
						let storedViewport: Viewport = cornerstoneViewportToViewport(viewport, isRaw);

						if (jsvalue.flip === "horizontal" || jsvalue.flip === "both")
							storedViewport.hflip = true;

						if (jsvalue.flip === "vertical" || jsvalue.flip === "both")
							storedViewport.vflip = true;

						if (jsvalue.rotation) {
							storedViewport.rotation = jsvalue.rotation;
						}

						if (jsvalue.scale) {
							storedViewport.scale = newScale !== undefined ? newScale : jsvalue.scale;
						}

						if (jsvalue.tx && jsvalue.ty) {
							storedViewport.translation = { x: jsvalue.tx, y: jsvalue.ty };
						}

						if (jsvalue.ww && jsvalue.wc) {
							let voi: VOI = {
								windowWidth: jsvalue.ww,
								windowCenter: jsvalue.wc
							};
							storedViewport.voi = { voi: voi, voi_raw: undefined };
						}

						if (jsvalue.cl)
							// @ts-ignore
							image.cutleft = jsvalue.cl;

						if (jsvalue.cr)
							// @ts-ignore
							image.cutright = jsvalue.cr;

						if (jsvalue.ct)
							// @ts-ignore
							image.cuttop = jsvalue.ct;

						if (jsvalue.cb)
							// @ts-ignore
							image.cutbottom = jsvalue.cb;

						viewport = viewportToCornerstoneViewport(storedViewport, isRaw);
						if (isRaw && viewport.voi === undefined) {
							viewport.voi = cornerstone.getDefaultViewportForImage(displayElement.element, image_raw!).voi
						}
						if (!isRaw && viewport.voi === undefined) {
							viewport.voi = cornerstone.getDefaultViewportForImage(displayElement.element, image).voi
						}
					}
				}
			}


			if (args.enabledElementForExport) {
				viewport.scale = 1;
				viewport.translation = { x: 0, y: 0 };
				viewport.invert = false;
			}
			//args.dispatch({ type: 'ImageDisplay/setMatrixImages', payload: { matrixIndex: newIndex, imageId: args.imageId } });

			if (args.display_raw && image_raw) {
				viewport.pixelReplication = getPixelReplication(args.getState());
				cornerstone.displayImage(displayElement?.element, image_raw, viewport);
			} else {
				viewport.pixelReplication = getPixelReplication(args.getState());
				cornerstone.displayImage(displayElement?.element, image, viewport);
			}

			// display overview for loaded image
			const overviewElement: EnabledElement | undefined = cornerstoneElements.find((element: EnabledElement) => element.element.id === Constants.OVERVIEW_ELEMENT_NAME);
			if (args.enabledElementForExport === undefined && overviewElement && overviewElement.element) {
				let overviewImage = image;
				if (args.display_raw && image_raw) {
					overviewImage = image_raw;
				}
				if (cornerstone.getEnabledElement(overviewElement.element) && overviewImage) {
					const viewport: cornerstone.Viewport = cornerstone.getDefaultViewportForImage(overviewElement.element, overviewImage);
					viewport.invert = false;
					cornerstone.displayImage(overviewElement.element, overviewImage, viewport);
					cornerstone.resize(overviewElement.element);
					//cornerstone.updateImage(overviewElement.element);
				}
			}

			// for new image (from drag and drop operation) create preview image, upload preview image to artifact store
			// and load new workitem to backend system
			if (args.createPreviewImage !== addImageMode.none) {
				const createPreviewImageElement: EnabledElement | undefined = cornerstoneElements.find((element: EnabledElement) => element.element.id === Constants.CREATE_PREVIEW_IMAGE_ELEMENT);
				if (createPreviewImageElement && createPreviewImageElement.element) {
					if (cornerstone.getEnabledElement(createPreviewImageElement.element) && image) {
						if (args.createPreviewImage === addImageMode.createPreview) {
							const viewport: cornerstone.Viewport = cornerstone.getDefaultViewportForImage(createPreviewImageElement.element, image);
							viewport.invert = false;
							cornerstone.displayImage(createPreviewImageElement.element, image, viewport);
							await waitforImageRendered(createPreviewImageElement.element);
							const previewImageUrl: string = createPreviewImageElement?.canvas?.toDataURL('image/jpeg', 0.1) ?? '';
							const base64Image: string = previewImageUrl.replace('data:image/jpeg;base64,', '');
							args.dispatch(addImageFromFile({ image: image, imageFile: args.imageFile, imageId: args.imageId, base64Image: base64Image, matrixIndex: newIndex, displayItemType: DisplayItemType.image, title: t('imageFromFile', { ns: 'Acquisition' }), imageName: args.imageName }));
						}
					}
				}
			}
		}


	} // loadAndDisplayImage

} // LoadAndDisplay
